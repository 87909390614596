import { combineReducers } from '@reduxjs/toolkit';

import {
  authorsApi,
  blocksApi,
  configApi,
  employeesApi,
  feedsApi,
  filesApi,
  gridsApi,
  gridSchemasApi,
  postsApi,
  sectionsApi,
  settingsApi,
  tagsApi,
  usersApi,
} from '~services';
import {
  authorsReducer,
  blocksReducer,
  configReducer,
  employeesReducer,
  gridSchemasReducer,
  gridsReducer,
  postsReducer,
  sectionsReducer,
  tagsReducer,
  userReducer,
  validationReducer,
} from '~slices';

export default combineReducers({
  authors: authorsReducer,
  [authorsApi.reducerPath]: authorsApi.reducer,
  blocks: blocksReducer,
  [blocksApi.reducerPath]: blocksApi.reducer,
  config: configReducer,
  [configApi.reducerPath]: configApi.reducer,
  employees: employeesReducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [feedsApi.reducerPath]: feedsApi.reducer,
  gridSchemas: gridSchemasReducer,
  [gridSchemasApi.reducerPath]: gridSchemasApi.reducer,
  grids: gridsReducer,
  [gridsApi.reducerPath]: gridsApi.reducer,
  posts: postsReducer,
  [postsApi.reducerPath]: postsApi.reducer,
  sections: sectionsReducer,
  [sectionsApi.reducerPath]: sectionsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  tags: tagsReducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  user: userReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  validation: validationReducer,
});
