import { BlockType, VideoBlockType, VideoFormat } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentVideoSchema = z.object<ValidatesBlockContent<BlockType.Video>>({
  caption: z.string(),
  cycle: z.boolean(),
  fileId: z.number({
    invalid_type_error: 'Блок "Видео" - обязательно должно быть загружено видео!',
    required_error: 'Блок "Видео" - обязательно должно быть загружено видео!',
  }),
  format: z.nativeEnum(VideoFormat),
  posterId: z.number().optional().nullable(),
  type: z.nativeEnum(VideoBlockType),
});
