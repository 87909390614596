import { Col, Result, Row, Typography } from 'antd';
import { GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import React, { FunctionComponent } from 'react';

import { Badge, Cover } from '~components';
import { BadgeColor } from '~constants';
import { deleteMarkup } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

const Post: FunctionComponent<PostPreviewSecure<PostType>> = ({ preview, tags, title }) => {
  const [tag] = tags || [];

  return (
    <Col span={12} className={styles.post}>
      <Cover
        src={preview?.imageURLs?.medium}
        height={195}
        width="100%"
        className={styles.image}
        gradient
      />
      <Typography.Text className={styles.title}>{deleteMarkup(title?.text)}</Typography.Text>
      {tag && (
        <Badge color={BadgeColor.NaSporte} className={styles.tag}>
          {tag?.name}
        </Badge>
      )}
    </Col>
  );
};

export const MainOnSport: React.FC<GridSchemaSecure> = (props) => {
  const { posts, title } = props;

  return (
    <BlockWrapper {...props} title={title || 'На спорте'} type="naSporte">
      {posts?.length === 0 ? (
        <Result status="error" title="Отсутсвуют публикации" />
      ) : (
        <Row gutter={[20, 20]} className={styles.content}>
          {posts?.map((post) => (
            <Post key={post.id} {...post} />
          ))}
        </Row>
      )}
    </BlockWrapper>
  );
};
