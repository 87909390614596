import { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { GridProps } from '~types';

type Context = Partial<GridProps>;

export const GridContext = createContext<Context>({});

export const GridProvider: FC<PropsWithChildren<Context>> = ({ children, grid }) => {
  const value = useMemo(() => ({ grid }), [grid]);
  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
};
