import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, MenuProps, Row } from 'antd';
import { isNil } from 'ramda';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { Search as SearchIcon } from '~assets';
import { isDefined } from '~utils';

import styles from './styles.module.scss';

interface Props {
  createButtonText?: string;
  menuDropdown?: MenuProps;
  onCreate?(isOpen: boolean): void;
  onSearch?(value: string): void;
  placeholderText?: string;
  term?: string;
}

const { Search } = Input;

export const PageHeader: React.FC<Props> = (props) => {
  const {
    createButtonText = 'Добавить',
    menuDropdown,
    onCreate,
    onSearch,
    placeholderText = 'Найти',
    term,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>();
  const location = useLocation();

  const onClick = useCallback(() => {
    if (isDefined(onCreate)) {
      onCreate(true);
    }
  }, [onCreate]);

  const handleChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handlePressEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(() => {
    if (isDefined(searchTerm) && isDefined(onSearch)) {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    if (isNil(term)) {
      setSearchTerm(undefined);
    }
  }, [term]);

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col xl={16}>
        <Search
          className={styles.search}
          bordered={false}
          size="large"
          placeholder={placeholderText}
          prefix={<SearchIcon width={24} height={24} className={styles.prefix} />}
          onChange={handleChangeSearchTerm}
          onPressEnter={handlePressEnter}
          onSearch={onSearch}
          value={searchTerm}
          allowClear
          enterButton={<div className={styles.enterButton}>Найти</div>}
        />
      </Col>
      <Col>
        {onCreate && (
          <Dropdown
            menu={menuDropdown}
            placement="bottomRight"
            trigger={menuDropdown ? ['click'] : []}
            key={location.pathname}
          >
            <Button
              onClick={menuDropdown ? undefined : onClick}
              type="primary"
              className={styles.createButton}
            >
              <PlusOutlined />
              {createButtonText}
            </Button>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
};
