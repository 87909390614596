import React from 'react';
import { typeToFlattenedError } from 'zod';

type Props = {
  errors: typeToFlattenedError<{ blocks: string[] }, string>;
};

export const ZodErrors: React.FC<Props> = ({ errors }) => {
  if (!errors?.fieldErrors?.blocks?.length) return null;
  return (
    <div>
      {errors.fieldErrors.blocks.map((err: string, i) => {
        return <p key={i}>{err}</p>;
      })}
    </div>
  );
};
