import { BlockType, QuoteType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

import { getTextFromRawDraftContentState } from '../../utils';
import { draftContentSchema } from '../draft';

export const blockContentQuoteSchema = z
  .object<ValidatesBlockContent<BlockType.Quote>>({
    caption: z.string(),
    content: draftContentSchema,
    expertName: z.string(),
    fileId: z.number().nullable(),
    type: z.nativeEnum(QuoteType),
  })
  .superRefine(({ content, expertName, type }, ctx) => {
    if (type === QuoteType.Expert && expertName.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Цитата" - ФИО обязательно для заполнения!',
      });
    } else if (type !== QuoteType.Expert && getTextFromRawDraftContentState(content).length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Цитата" - цитата обязательна для заполнения!',
      });
    }
  });
