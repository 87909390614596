import { Col, Result, Row, Skeleton } from 'antd';
import React, { FC, PropsWithChildren } from 'react';

interface Props {
  hasContent: boolean;
  isError: boolean;
  isFetching: boolean;
}
export const SettingsTabWrapper: FC<PropsWithChildren<Props>> = (props) => {
  const { children, hasContent, isError, isFetching } = props;

  if (isFetching) {
    return (
      <Row gutter={[0, 24]}>
        {Array.from({ length: 3 }).map((_, i) => (
          <Col key={i} span={24}>
            <Skeleton active loading />
          </Col>
        ))}
      </Row>
    );
  }
  if (isError) {
    return (
      <Row align="middle" justify="center">
        <Col span={24}>
          <Result
            status="error"
            title="Ошибка"
            subTitle="Ошибка загрузки настроек. Попробуйте обновить страницу"
          />
        </Col>
      </Row>
    );
  }
  if (!hasContent) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Не найдены настройки. Попробуйте обновить страницу"
      />
    );
  }
  return <div>{children}</div>;
};
