import 'antd/dist/antd.less';
import '~stylesheets/styles.scss';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import ruRU from 'antd/es/locale-provider/ru_RU';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { LoadingWrapper, NotFound } from '~components';
import { RoutesPath } from '~constants';
import { AuthGuard } from '~containers';
import { ConfigProvider } from '~providers';
import { routes } from '~routes';
import { useGetConfigQuery } from '~services';

ruRU.DatePicker!.lang.locale = 'ru';

setDefaultOptions({ locale: ru });

export const App = () => {
  const { data: config, isLoading } = useGetConfigQuery();

  useEffect(() => {
    if (config) {
      // eslint-disable-next-line no-console
      console.info('App starter', { config });
      Sentry.init({
        dsn: config.sentryDSN,
        enabled: config.env === 'production',
        integrations: [
          new BrowserTracing(),
          new Sentry.Replay({
            blockAllMedia: false,
            maskAllInputs: false,
            maskAllText: false,
          }),
        ],

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // This sets the sample rate to be 50%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.5,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
      });
    }
  }, [config]);

  // ToDo Обернуть ошибку
  if (!config) {
    return null;
  }

  return (
    <AntdConfigProvider locale={ruRU}>
      <LoadingWrapper isLoading={isLoading}>
        <ConfigProvider value={config}>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to={RoutesPath.Posts} />} />
              {routes.map(({ component: Component, isPublic, path, props }) => (
                <Route
                  key={path}
                  element={
                    <AuthGuard isPublic={isPublic}>
                      <Component {...props} />
                    </AuthGuard>
                  }
                  path={path}
                />
              ))}

              <Route
                path="*"
                element={
                  <AuthGuard>
                    <NotFound />
                  </AuthGuard>
                }
              />
            </Routes>
            <ModalContainer />
          </Router>
        </ConfigProvider>
      </LoadingWrapper>
    </AntdConfigProvider>
  );
};
