import { Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import { prop } from 'ramda';
import React, { useContext } from 'react';

import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { SettingsContext } from '~providers';

import { DropZone } from '../../../DropZone';
import { SettingsTabWrapper } from '../Wrapper';
import style from './styles.module.scss';

export const SettingsTabContacts: React.FC = () => {
  const { forms } = useContext(SettingsContext);
  const isMobile = useIsMobile();
  const { form, initialValues, isError, isFetching } = forms.contacts;
  console.log('SettingsTabContacts', { initialValues });
  return (
    <SettingsTabWrapper isError={isError} isFetching={isFetching} hasContent>
      <Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
        <Col md={12} xs={24} className={cn(!isMobile && style.noPaddingLeft)}>
          <Row gutter={[25, 25]}>
            <Col span={24}>
              <Form.Item
                name="copyright"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Копирайт</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="certificateTitle"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Свидетельство о регистрации</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="certificateId"
                getValueFromEvent={prop('id')}
                getValueProps={() => ({
                  value: initialValues.certificate,
                })}
              >
                <DropZone
                  type={DropType.Documents}
                  imageOptions={{
                    height: isMobile ? 177 : 240,
                    width: isMobile ? 315 : 420,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Адрес редакции</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="founder"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Учредитель</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="chiefEditor"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Главный редактор</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Электронная почта редакции</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="additional"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Дополнительная информация</label>}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </SettingsTabWrapper>
  );
};
