import { Avatar, Col, Layout, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { getFileURL } from 'pn-backend';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Close, Exit, Logo } from '~assets';
import { Button, ErrorBoundary } from '~components';
import { RoutesPath } from '~constants';
import { ConfigContext, GlobalProvider, UserProvider } from '~providers';
import { hasPendingBlocksSelector } from '~selectors';
import { useGetCurrentUserQuery, useLogoutUserMutation } from '~services';
import { getInitials, getUserRoleTitle } from '~utils';

import { Menu } from './components';
import styles from './styles.module.scss';

const { Content, Sider } = Layout;
const { Text } = Typography;

export const AppLayout: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [logout] = useLogoutUserMutation();
  const { data: user } = useGetCurrentUserQuery();
  const { imageProxyHost } = useContext(ConfigContext);
  const refLayout = useRef<HTMLDivElement>(null);

  const [isMenuShown, setIsMenuShown] = useState(false);

  const isSavingBlocks = useSelector(hasPendingBlocksSelector);

  useEffect(() => {
    setIsMenuShown(false);
    refLayout?.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
  }, [location.pathname]);

  const onLogoClick = useCallback(async () => {
    navigate(RoutesPath.Home);
  }, [navigate]);

  const logoutClick = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <GlobalProvider value={{ isMenuShown: false, setIsMenuShown }}>
      <UserProvider user={user}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            style={{
              left: 0,
              overflow: 'auto',
              position: 'fixed',
            }}
            className={classNames(styles.sider, isMenuShown && styles.visibleSider)}
          >
            <div className={styles.nonScrollable}>
              <Row className={styles.logoWrapper} justify="space-between" align="middle">
                <Col md={24} xs={12}>
                  <Row className={styles.logoBlock}>
                    <Col>
                      <Logo
                        className={classNames(styles.logo, isSavingBlocks && styles.disabled)}
                        onClick={onLogoClick}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className={styles.closeButton}>
                  <Close
                    onClick={() => {
                      setIsMenuShown(false);
                    }}
                  />
                </Col>
              </Row>
              <div className={styles.menuWrapper}>
                <Menu />
              </div>
            </div>
            <Space direction="vertical" size="large" className={styles.siderFooter}>
              <Space>
                <Avatar
                  size={50}
                  src={
                    user?.avatar
                      ? getFileURL(user?.avatar, {
                          domain: imageProxyHost,
                          height: 50,
                          width: 50,
                        })
                      : null
                  }
                >
                  {getInitials(user?.name?.split(' ') || [])}
                </Avatar>
                <Row>
                  <Col span={24}>
                    <Text className={styles.userName}>{user?.name}</Text>
                  </Col>
                  <Col span={24}>
                    <Text className={styles.userRole}>{getUserRoleTitle(user?.role)}</Text>
                  </Col>
                </Row>
              </Space>
              <Button
                type="text"
                icon={<Exit />}
                className={styles.logoutButton}
                onClick={logoutClick}
                textClassName={styles.logoutButton}
              >
                Выйти из аккаунта
              </Button>
            </Space>
          </Sider>
          <Layout
            className={classNames(styles.contentLayout, isMenuShown && styles.nonScrollable)}
            ref={refLayout}
          >
            <Content className={styles.content}>
              <div className={styles.contentWrapper}>
                <ErrorBoundary>{children}</ErrorBoundary>
              </div>
            </Content>
          </Layout>
        </Layout>
      </UserProvider>
    </GlobalProvider>
  );
};
