import { Col, Form, Row } from 'antd';
import React, { useContext } from 'react';

import { TitlePage } from '~components';
import { SettingsContext } from '~providers';

import { SettingsTabWrapper } from '../Wrapper';
import { Social } from './Social';
import { SocialShare } from './SocialShare';

export const SettingsTabSocials: React.FC = () => {
  const { forms } = useContext(SettingsContext);
  const { form, initialValues, isError, isFetching } = forms.socials;
  return (
    <SettingsTabWrapper isError={isError} isFetching={isFetching} hasContent>
      <Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Social />
          </Col>
          <Col span={24}>
            <TitlePage level={4} title="Шер в соцсети" />
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <SocialShare />
          </Col>
        </Row>
      </Form>
    </SettingsTabWrapper>
  );
};
