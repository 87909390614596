import { createApi } from '@reduxjs/toolkit/query/react';
import type { AuthGetTokensResponseSecure, AuthLoginBodySecure, Employee } from 'pn-backend';

import { ApiPath } from '~constants';
import { createQuery, createUrl } from '~utils';

import { authGuardQuery } from './defaultApi';

export const usersApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getCurrentUser: build.query<Employee, void>({
      query: createQuery(ApiPath.Auth, ApiPath.Me),
    }),
    loginUser: build.mutation<AuthGetTokensResponseSecure, AuthLoginBodySecure>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Login),
      }),
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Logout),
      }),
    }),
  }),
  reducerPath: 'usersApi',
});

export const { useGetCurrentUserQuery, useLoginUserMutation, useLogoutUserMutation } = usersApi;
