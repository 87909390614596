import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Row } from 'antd';
import { GridLayout } from 'pn-backend';
import { always, cond, dec, inc, propEq, T } from 'ramda';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SortableItem } from '~components';
import { useIsMobile } from '~hooks';
import { gridSchemasEntitySelector } from '~selectors';
import { updateManyGridSchemas } from '~slices';
import { getSortValuesFunc } from '~utils';

import {
  MainBlackScience,
  MainCompilation,
  MainNews,
  MainOnSport,
  MainPostOfTheWeek,
  MainTop,
  OneSpecProject,
  SomeTests,
  TwoStandard,
} from './components';

const propLayoutEq = (layout: GridLayout) => propEq('layout', layout);

const renderComponent = (Component: React.FC) =>
  function (props: any) {
    return <Component {...props} />;
  };

const renderBlockByLayout = cond([
  [propLayoutEq(GridLayout.MainBlackScience), renderComponent(MainBlackScience)],
  [propLayoutEq(GridLayout.MainCompilation), renderComponent(MainCompilation)],
  [propLayoutEq(GridLayout.MainNewsFull), renderComponent(MainNews)],
  [propLayoutEq(GridLayout.MainOnSport), renderComponent(MainOnSport)],
  [propLayoutEq(GridLayout.MainPostOfTheWeek), renderComponent(MainPostOfTheWeek)],
  [propLayoutEq(GridLayout.MainTop), renderComponent(MainTop)],
  [propLayoutEq(GridLayout.OneSpecProject), renderComponent(OneSpecProject)],
  [propLayoutEq(GridLayout.SomeTests), renderComponent(SomeTests)],
  // [propLayoutEq(GridLayout.ThreeSmall), renderComponent(ThreeSmall)],
  [propLayoutEq(GridLayout.TwoStandard), renderComponent(TwoStandard)],
  [T, always(null)],
]);

export const GridSchemaBlocks = () => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const gridSchemas = useSelector(gridSchemasEntitySelector.selectAll);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { delay: 300, tolerance: 50 } }),
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const sort = getSortValuesFunc(gridSchemas);
      const changes = sort(event);

      if (changes.length > 0) {
        dispatch(updateManyGridSchemas(changes));
      }
    },
    [dispatch, gridSchemas],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={gridSchemas} strategy={rectSortingStrategy}>
        <Row gutter={[20, 20]}>
          {gridSchemas?.map((gridSchema, index) => (
            <SortableItem
              key={gridSchema.id}
              id={gridSchema.sortOrder}
              span={24}
              disabled={!gridSchema.canChangeOrder || isMobile}
            >
              {renderBlockByLayout({
                ...gridSchema,
                canSortDown: gridSchema.canChangeOrder && gridSchemas.length !== inc(index),
                canSortUp: gridSchema.canChangeOrder && gridSchemas[dec(index)]?.canChangeOrder,
                onChangeOrder: onDragEnd,
              })}
            </SortableItem>
          ))}
        </Row>
      </SortableContext>
    </DndContext>
  );
};
