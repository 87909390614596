import { Col, Form, Input, Row } from 'antd';
import { BlockType } from 'pn-backend';
import React, { FC, useMemo } from 'react';

import { BlockProps } from '~types';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

export const NewsSourceBlock: FC<BlockProps<BlockType.NewsSource>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { content } = block;

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      initialValues={initialValues}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Источник</div>
        </Col>
        <Col span={24}>
          <Form.Item name="name" className={styles.inputRow}>
            <Input className={styles.input} bordered={false} placeholder="Название источника" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="url" className={styles.inputRow}>
            <Input className={styles.input} bordered={false} placeholder="Ссылка на источник" />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};
