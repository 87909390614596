import Icon from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import type { SelectInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Home, News, Post, Sections, Settings, Tag, User } from '~assets';
import { RoutesPath } from '~constants';
import { hasPendingBlocksSelector } from '~selectors';

import styles from './styles.module.scss';

const items: ItemType[] = [
  {
    icon: <Icon component={Home} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: '/grid/1/schema',
    label: 'Главная',
  },
  {
    icon: <Icon component={Sections} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Sections,
    label: 'Разделы',
  },
  // {
  //   icon: <TableOutlined className={styles.icon} style={{ fontSize: '24px' }} />,
  //   key: RoutesPath.GridSchemas,
  //   label: 'Схемы сеток',
  // },
  {
    icon: <Icon component={Post} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Posts,
    label: 'Публикации',
  },
  {
    icon: <Icon component={News} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.News,
    label: 'Новости',
  },
  {
    children: [
      {
        key: RoutesPath.Employees,
        label: 'Пользователи',
      },
      {
        key: RoutesPath.Authors,
        label: 'Авторы',
      },
    ],
    icon: <Icon component={User} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: 'users',
    label: 'Справочники',
  },
  {
    icon: <Icon component={Tag} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Tags,
    label: 'Теги',
  },
  {
    icon: <Icon component={Settings} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Settings,
    label: 'Настройки',
  },
];

export const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSavingBlocks = useSelector(hasPendingBlocksSelector);

  const onMenuClick = (event: SelectInfo) => {
    navigate(event.key);
  };

  const onSubMenuClick = (openedSubMenus: string[]) => {
    if (openedSubMenus.includes('users')) {
      navigate('/employees');
    }
  };

  const selectedRoute = `${location.pathname}`;

  return (
    <AntdMenu
      selectedKeys={[selectedRoute]}
      mode="inline"
      className={classNames(styles.menu, isSavingBlocks && styles.disabled)}
      onSelect={onMenuClick}
      onOpenChange={onSubMenuClick}
      items={items}
    />
  );
};
