import classNames from 'classnames';
import React, { KeyboardEvent, MouseEvent, PropsWithChildren } from 'react';

import { BadgeColor } from '~constants';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  color?: BadgeColor;
  onClick?(event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>): void;
  size?: 'large' | 'default' | 'small';
  transparent?: boolean;
} & ({ selected?: boolean; type?: 'badge' } | { selected: boolean; type: 'chip' });

export const Badge: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  color,
  onClick,
  selected,
  size,
  transparent,
  type = 'badge',
}) => (
  <div
    className={classNames(
      styles[type],
      size && styles[size],
      color && styles[color],
      selected && styles.selected,
      transparent && styles.transparent,
      className,
    )}
    onClick={onClick}
    onKeyDown={onClick}
  >
    {children}
  </div>
);
