import { EntityId } from '@reduxjs/toolkit';
import { Button, Col, Row } from 'antd';
import { GridSchemaSecure, PostType } from 'pn-backend';
import { __, applySpec, filter, groupBy, includes, map, not, pipe, prop } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBeforeUnload } from 'react-router-dom';

import { GridSchemaCard, TitlePage } from '~components';
import { GridSchemaModal, MobilePageHeader } from '~containers';
import { useIsMobile, useNotification } from '~hooks';
import { changesGridSchemasEntitySelector, gridSchemasEntitySelector } from '~selectors';
import {
  useCreateGridSchemaMutation,
  useGetGridSchemasListQuery,
  useUpdateGridSchemasMutation,
} from '~services';
import { updateManyGridSchemas } from '~slices';

import styles from './styles.module.scss';

const filterByChanges = (changes: EntityId[], items: GridSchemaSecure[]) =>
  pipe(
    filter(pipe(prop('id'), includes(__, changes))),
    map(
      applySpec({
        canChangeOrder: prop<boolean>('canChangeOrder'),
        canChangePosts: prop<boolean>('canChangePosts'),
        canHide: prop<boolean>('canHide'),
        canPaginate: prop<boolean>('canPaginate'),
        filterByPostTypes: prop<PostType[]>('filterByPostTypes'),
        id: prop<number>('id'),
        isHidden: prop<boolean>('isHidden'),
        title: prop<string>('title'),
      }),
    ),
  )(items);

const RightContent: React.FC = () => {
  const changes = useSelector(changesGridSchemasEntitySelector);

  const gridSchemas = useSelector(gridSchemasEntitySelector.selectAll);
  const [updateGridSchemas, { isLoading }] = useUpdateGridSchemasMutation();

  const hasChanges = changes.length > 0;

  const notification = useNotification();

  const publishHandler = async () => {
    const items = filterByChanges(changes, gridSchemas);

    await updateGridSchemas({
      items,
    });

    notification({
      message: 'Изменения опубликованы',
      placement: 'top',
    });
  };

  return (
    <Row gutter={[25, 0]}>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={!hasChanges}
          loading={isLoading}
          onClick={publishHandler}
        >
          Опубликовать
        </Button>
      </Col>
    </Row>
  );
};

export const GridSchemasPage: React.FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [gridSchema, setGridSchema] = useState<GridSchemaSecure>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const items = useSelector(gridSchemasEntitySelector.selectAll);
  const [createGridSchema] = useCreateGridSchemaMutation();

  const toggleModal = () => {
    setIsShowModal(not);
  };

  const handleEdit = (gridSchema: GridSchemaSecure) => {
    setGridSchema(gridSchema);
    toggleModal();
  };

  const handleCloseModal = async (gridSchema?: Partial<GridSchemaSecure>) => {
    if (gridSchema) {
      const {
        canChangeOrder,
        canChangePosts,
        canHide,
        canPaginate,
        filterByPostTypes,
        id,
        isHidden,
        title,
      } = gridSchema;

      const data = {
        canChangeOrder,
        canChangePosts,
        canHide,
        canPaginate,
        filterByPostTypes,
        id,
        isHidden,
        title,
      };

      if (id) {
        dispatch(updateManyGridSchemas([{ changes: data, id }]));
      } else {
        createGridSchema(data);
      }
    }

    toggleModal();
  };

  const itemsGroupedByGridAlias = groupBy<GridSchemaSecure>(pipe(prop('grid'), prop('alias')))(
    items,
  );

  useGetGridSchemasListQuery({ relations: ['grid'] });

  const changes = useSelector(changesGridSchemasEntitySelector);

  useBeforeUnload((event) => {
    if (changes.length > 0) {
      event.preventDefault();
      event.returnValue = true;
    }
  });

  return (
    <Row gutter={[0, 32]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader title="Управление схемами сеток" withSearch={false} />
      </Col>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <TitlePage titleVisible={!isMobile} title="Управление схемами сеток" />
          </Col>
          <Col>
            <RightContent />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col span={24}>
            {Object.entries(itemsGroupedByGridAlias).map(([, gridSchemas]) => {
              return (
                <>
                  <div className={styles.title}>{gridSchemas[0]?.grid?.title}</div>
                  <Row gutter={[25, 25]}>
                    {gridSchemas.map((item) => (
                      <Col flex="33%">
                        <GridSchemaCard item={item} onEdit={handleEdit} />
                      </Col>
                    ))}
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
        <GridSchemaModal isOpen={isShowModal} onClose={handleCloseModal} gridSchema={gridSchema} />
      </Col>
    </Row>
  );
};
