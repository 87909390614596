import queryString, { StringifyOptions } from 'query-string';
import {
  __,
  always,
  anyPass,
  assoc,
  defaultTo,
  includes,
  is,
  isEmpty,
  isNil,
  join,
  map,
  not,
  pipe,
  prop,
  reject,
  toUpper,
  trim,
} from 'ramda';

export const clearObject = reject(anyPass([isEmpty, isNil]));

export const getFirstSymbol = pipe(defaultTo(''), trim, toUpper, (value) => value.slice(0, 1));

export const getInitials = pipe(map(getFirstSymbol), join(''), trim);

export const isString = is(String);

export const isNumber = is(Number);

export const isDate = is(Date);

export const propIn = <P extends string>(key: P, list: any[]) =>
  pipe(prop(key), includes(__, list));

export const isNotNil = pipe(isNil, not);

export const copyProp = (from: string, to: string) => (obj: any) =>
  pipe(prop(from), assoc(to, __, obj))(obj);

export function isSuccessResult<T>(result: { data: T } | { error: any }): result is { data: T } {
  return (<{ data: T }>result).data !== undefined;
}

export function isFile(data: File | Blob): data is File {
  return data instanceof File;
}

export const stringifyQueryString = (options?: StringifyOptions) => (params: Record<string, any>) =>
  queryString.stringify(params, options);

export const noop = always(undefined);
