import { Form } from 'antd';
import { BlockContentIntro, BlockContentText, BlockType, PostType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React, { FC, useCallback, useState } from 'react';

import { RichEditor } from '~components';
import { usePost } from '~hooks';
import { BlockProps } from '~types';
import { getText } from '~utils';

import { RichTextBlock } from '../../../components/RichEditor/types';
import { FormBlock } from '../Wrappers';

const getBlockTitle = cond([
  [equals(BlockType.Intro), always('Вводка')],
  [equals(BlockType.Text), always('Текст')],
  [T, always('text')],
]);

const getBlockPlaceholder = cond([
  [equals(BlockType.Intro), always('Напишите вводку')],
  [equals(BlockType.Text), always('Напишите текст')],
  [T, always('text')],
]);

const getBlockIsInline = cond([
  [equals(BlockType.Intro), always(false)],
  [equals(BlockType.Text), always(false)],
  [T, always(true)],
]);

const getBlockFontSize = cond([
  [equals(PostType.News), always(26)],
  [T, always(20)],
]);

const getBlockLineHeight = cond([
  [equals(PostType.News), always(31)],
  [T, always(28)],
]);

const getBlockBannedToolbarControls = cond([
  [equals(PostType.News), always([RichTextBlock.tooltip, RichTextBlock.highlight])],
  [T, always([])],
]);

const getBlockMaxCount = cond([[T, always(undefined)]]);

export const TextBlock: FC<BlockProps<BlockType.Text | BlockType.Intro>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const { content: blockContent, type } = block;
  const { type: postType } = usePost();

  const { content } = blockContent;

  const [form] = Form.useForm();

  const [count, setCount] = useState(getText(content).length);

  const onValuesChange = useCallback(
    ({ content: changedContent }: { content: BlockContentIntro | BlockContentText }) => {
      setCount(getText(changedContent).length);
    },
    [],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={getBlockMaxCount(type)}
      onValuesChange={onValuesChange}
    >
      <Form.Item name="content">
        <RichEditor
          withColor={false}
          defaultData={content}
          fontSize={getBlockFontSize(postType)}
          lineHeight={getBlockLineHeight(postType)}
          placeholder={getBlockPlaceholder(type)}
          title={getBlockTitle(type)}
          inline={getBlockIsInline(type)}
          bannedToolbarControls={getBlockBannedToolbarControls(postType)}
        />
      </Form.Item>
    </FormBlock>
  );
};
