import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ZodIssue } from 'zod';

type ZodIssueWithBlockId = ZodIssue & { blockId: number };

interface State {
  errors: ZodIssueWithBlockId[];
}

export const validationSlice = createSlice({
  initialState: {
    errors: [],
  } as State,
  name: 'user',
  reducers: {
    setErrors: (state, action: PayloadAction<State>) => {
      state.errors = action.payload.errors;
    },
  },
});

export const validationReducer = validationSlice.reducer;
export const validationActions = validationSlice.actions;
