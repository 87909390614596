import { EditorState, RichUtils } from 'draft-js';

import { RichTextBlock } from '../types';

export const getTooltipString = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();

  const startKey = editorState.getSelection().getStartKey();

  const start = editorState.getSelection().getStartOffset();
  const end = editorState.getSelection().getEndOffset();

  let lastTooltip = '';
  const allTooltips: string[] = [];

  editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .findEntityRanges(
      (data) => {
        const entity = data.getEntity();

        if (entity) {
          // @ts-ignore
          lastTooltip = contentState.getEntity(entity).data.body;
          return true;
        }
        return false;
      },
      (s, e) => {
        if (!(e <= start || s >= end)) {
          allTooltips.push(lastTooltip);
        }
      },
    );

  if (allTooltips.length > 0) return allTooltips[0];

  return '';
};

export const getTooltip = (editorState: EditorState, enteredTooltip: string): EditorState => {
  const contentState = editorState.getCurrentContent();

  const tooltipValue = enteredTooltip ?? null;

  if (tooltipValue) {
    const contentStateWithEntity = contentState.createEntity(RichTextBlock.tooltip, 'SEGMENTED', {
      body: tooltipValue,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
  }
  return editorState;
};
