export enum TypeSettings {
  About = 'about',
  Ads = 'advertisers',
  Contacts = 'contacts',
  Socials = 'socials',
}

export const TYPE_SETTINGS: TypeSettings[] = [
  TypeSettings.About,
  TypeSettings.Socials,
  TypeSettings.Contacts,
  TypeSettings.Ads,
];
