import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { PostProps } from '~types';

type Context = PostProps;

// ToDo Отрефакторить: инкапсулировать хуки useGetPostByIdQuery и useGetPostBlocksQuery
export const PostContext = createContext<Context>({} as Context);

export const PostProvider: FC<PropsWithChildren<Context>> = ({ children, post }) => {
  const value = useMemo(() => ({ post }), [post]);
  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};
