import { BlockContentGalleryItem, BlockType, GalleryType } from 'pn-backend';
import { z } from 'zod';

import { Validates, ValidatesBlockContent } from '~types';

export const blockContentGallerySchema = z.object<ValidatesBlockContent<BlockType.Gallery>>({
  images: z
    .array(
      z.object<Validates<BlockContentGalleryItem>>({
        caption: z.string(),
        fileId: z.number({
          invalid_type_error:
            'В блоке "Галерея" обязательно должно быть загружено изображение. Загрузите необходимые медиаданные',
        }),
        height: z.number(),
        width: z.number(),
      }),
    )
    .min(1, { message: 'Блок "Галерея" - необходимо загрузить хотя бы одно изображение!' }),
  type: z.nativeEnum(GalleryType),
});
