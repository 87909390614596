import { Popover } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockPicker, ColorChangeHandler } from 'react-color';

import { isDefined } from '~utils';

import styles from './index.module.scss';

interface FieldProps {
  baseColor?: string;
  defaultRegionColor?: string;
  onChange?(value: any): void;
  value?: any;
}

const colors = ['#505050', '#A29FA6', '#C4C3C6', '#EEEEEE'];

export const ColorPicker: React.FC<FieldProps> = ({
  baseColor,
  defaultRegionColor,
  onChange,
  value,
}) => {
  const [color, setColor] = useState(value);

  const defaultColor = baseColor ?? defaultRegionColor;
  const availableColors = useMemo(() => {
    return [...colors, defaultColor];
  }, [defaultColor]);

  const onChangeColor = useCallback<ColorChangeHandler>(
    ({ hex }) => {
      const color = hex.toUpperCase();
      setColor(color);
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      onChange && onChange(color === defaultColor ? null : color);
    },
    [defaultColor, onChange],
  );

  useEffect(() => {
    if (!availableColors.includes(color)) {
      setColor(defaultColor);
    }
  }, [availableColors, color, defaultColor]);

  return (
    <Popover
      content={
        <BlockPicker
          colors={availableColors.filter(isDefined)}
          color={color}
          onChangeComplete={onChangeColor}
          triangle="hide"
        />
      }
      trigger="click"
    >
      <div className={styles.color} style={{ background: color || 'white' }} />
    </Popover>
  );
};
