import { Col, Form, message, notification, Row } from 'antd';
import cn from 'classnames';
import {
  BlockMapBuilder,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  DraftEditorCommand,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RawDraftContentState,
  RichUtils,
} from 'draft-js';
import { EMPTY_RAW_DRAFT_CONTENT_STATE } from 'pn-backend';
import { isEmpty } from 'ramda';
import React, { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { isDefined, isNotNil } from '~utils';

import { ColorPicker } from '../ColorPicker';
import {
  getLengthOfSelectedText,
  makeUnstyled,
  removeInlineStyles,
  validateLinks,
} from './helpers';
import { customStyleMap, decorator, RenderMap } from './setup';
import styles from './styles.module.scss';
import controls from './toolbarControls';
import { RichTextBlock } from './types';

type RichEditorProps = {
  addBlocksOnPaste?: (content: RawDraftContentState, insertIndex: number) => void;
  bannedToolbarControls?: RichTextBlock[];
  baseColor?: string;
  contentBlockTypesToUnstyled?: string[];
  defaultData?: RawDraftContentState;
  defaultRegionColor?: string;
  externalIndex?: number;
  fontSize?: number;
  inline?: boolean;
  inlineStylesToRetain?: string[];
  lineHeight?: number;
  maxLength?: number;
  needFocus?: boolean;
  onChange?: (rawDraftContent: RawDraftContentState) => void;
  onEnterPress?: () => void;
  placeholder?: string;
  textPrefix?: string | ReactNode;
  title?: string;
  withColor?: boolean;
};

const defaultBlocksToUnstyled = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
];

const MSWordParagraphDelimiter = '<p class=MsoNormal><o:p>&nbsp;</o:p></p>';

const getInitialState = (defaultData: RawDraftContentState) =>
  isNotNil(defaultData) && !isEmpty(defaultData)
    ? EditorState.createWithContent(convertFromRaw(defaultData), decorator)
    : EditorState.createEmpty(decorator);

export const RichEditor: React.FC<RichEditorProps> = (props) => {
  const {
    addBlocksOnPaste = null,
    baseColor,
    bannedToolbarControls = [],
    externalIndex = 0,
    contentBlockTypesToUnstyled = [],
    inlineStylesToRetain = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'HIGHLIGHT'],
    defaultData,
    defaultRegionColor,
    fontSize,
    inline = false,
    needFocus = false,
    lineHeight,
    maxLength,
    onChange,
    onEnterPress,
    placeholder,
    textPrefix,
    title,
    withColor,
  } = props;

  const ref = useRef<Editor>(null);
  const popoversRefs = useRef<HTMLSpanElement[]>([]);

  const [editorState, setEditorState] = useState<EditorState>(
    getInitialState(defaultData ?? EMPTY_RAW_DRAFT_CONTENT_STATE),
  );
  const currentStyle = editorState.getCurrentInlineStyle();

  const createControlHandler =
    (type: RichTextBlock, isInline?: boolean) =>
    (event: React.MouseEvent<HTMLSpanElement>): void => {
      event.preventDefault();
      switch (true) {
        case isInline:
          setEditorState(RichUtils.toggleInlineStyle(editorState, type));
          break;
        default:
      }
    };

  const handleChange = (state: EditorState) => {
    setEditorState(state);
    if (onChange) {
      onChange(convertToRaw(state.getCurrentContent()));
    }
  };

  const handleKeyCommand = (
    command: DraftEditorCommand & 'enterPressed',
    state: EditorState,
  ): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (isDefined(onEnterPress) && command === 'enterPressed') {
      onEnterPress();
      return 'handled';
    }

    if (command === 'shiftEnterPressed') {
      const splitedState = RichUtils.insertSoftNewline(newState || state);
      handleChange(splitedState);
      return 'handled';
    }

    if (newState) {
      handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState,
  ): DraftHandleValue => {
    let newEditorState = editorState;

    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;

      const selectedTextLength = getLengthOfSelectedText(editorState);
      const textLength = text.length;

      const totalTextLength = currentContentLength + text.length - selectedTextLength;

      console.log('maxLength', {
        currentContentLength,
        maxLength,
        selectedTextLength,
        text,
        textLength,
        totalTextLength,
      });

      if (currentContentLength + text.length - selectedTextLength > maxLength) {
        message.error('Слишком большой текст для вставки!');

        return 'handled';
      }
    }

    if (html) {
      const linkUnderlineRegexp = /(<[a]+.*?<\/[a]>)/gm;
      const linkTextDecorationRegexp = /(text-decoration:[ ]*underline)/gm;

      const modifiedHtml = html
        // handle MS Word unordered list [19106]
        .replace(
          /<!\[if !supportLists\]>[\s\S]*?·?[\s\S]*?<!\[endif\]>([\s\S]*?<o:p>)/g,
          '<li>$1</li>',
        )
        // handle MS Word paragraphs [24146]
        .replace(MSWordParagraphDelimiter, '<br>')
        // удаляет стиль underline если он находится внутри <a ..../>
        .replace(linkUnderlineRegexp, (data) => data.replace(linkTextDecorationRegexp, () => ''));

      const blocksFromHTML = convertFromHTML(modifiedHtml);
      if (blocksFromHTML) {
        const { contentBlocks, entityMap } = blocksFromHTML;

        if (contentBlocks) {
          try {
            validateLinks(contentBlocks, editorState.getCurrentContent());
          } catch (error) {
            console.log('error', error);
            notification.error({
              message: 'Текст для вставки содержит невалидную ссылку',
              placement: 'bottomRight',
            });
            return 'handled';
          }

          const htmlMap = BlockMapBuilder.createFromArray(
            addBlocksOnPaste ? contentBlocks.slice(0, 1) : contentBlocks,
          );

          const newContent = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            htmlMap,
          );
          newContent.set('entityMap', entityMap);
          newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
          newEditorState = removeInlineStyles(newEditorState, inlineStylesToRetain);
          newEditorState = makeUnstyled(newEditorState, [
            ...defaultBlocksToUnstyled,
            ...contentBlockTypesToUnstyled,
          ]);

          if (maxLength) {
            const textLength = newEditorState.getCurrentContent().getPlainText('').length;
            const selectedTextLength = getLengthOfSelectedText(editorState);

            if (textLength - selectedTextLength > maxLength) {
              message.error('Слишком большой текст для вставки!');

              return 'handled';
            }
          }

          if (addBlocksOnPaste) {
            const blocksToAdd = contentBlocks.slice(1);
            blocksToAdd.forEach((block, index) => {
              // дополнительные преобразования вставленного контента в одтельный стейт, чтобе применить удаление стилей
              const rawPasted = convertToRaw(ContentState.createFromBlockArray([block]));

              let editorStateFromPasted = EditorState.createWithContent(
                convertFromRaw(rawPasted),
                decorator,
              );

              editorStateFromPasted = makeUnstyled(editorStateFromPasted, [
                ...defaultBlocksToUnstyled,
                ...contentBlockTypesToUnstyled,
              ]);

              const pastedContentState = editorStateFromPasted.getCurrentContent();

              addBlocksOnPaste(convertToRaw(pastedContentState), index + externalIndex + 1);
            });
          }

          setEditorState(newEditorState);
          if (isDefined(onChange)) {
            onChange(convertToRaw(newEditorState.getCurrentContent()));
          }

          return 'handled';
        }
      }
    }

    return 'not-handled';
  };

  const handleBeforeInput = () => {
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = getLengthOfSelectedText(editorState);

      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return 'handled';
      }
    }

    return 'not-handled';
  };

  const keyBindings = (event: KeyboardEvent) => {
    if (event.keyCode === 13 && onEnterPress) {
      return 'enterPressed';
    }
    if (event.keyCode === 13 && event.shiftKey) {
      return 'shiftEnterPressed';
    }
    return getDefaultKeyBinding(event);
  };

  useEffect(() => {
    if (needFocus) {
      if (isDefined(ref.current)) {
        ref.current.focus();
      }
    }
  }, [needFocus]);

  return (
    <Row gutter={[30, 30]} style={{ marginBottom: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
      <Col
        span={24}
        className={cn(styles.headerWrapper, !title && styles.noBorder)}
        style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
      >
        <Row gutter={[10, 10]} style={{ width: '100%' }} justify="space-between">
          {title && (
            <Col xs={24} md={12} className={styles.title}>
              {title}
            </Col>
          )}
          {!inline && (
            <Col className={styles.toolbar}>
              {controls
                .filter((control) => !bannedToolbarControls.includes(control.key))
                .map(({ icon, inline, key, popover }, index) => {
                  const control = (
                    <span
                      // @ts-ignore ToDo Исправить типы
                      ref={(popoversRefs.current[index] = ref)}
                      key={key}
                      className={cn(styles.icon, currentStyle.has(key) && styles.active)}
                      onMouseDown={createControlHandler(key, inline)}
                    >
                      {icon}
                    </span>
                  );

                  return popover ? popover(control, setEditorState, editorState, key) : control;
                })}
              {withColor && (
                <Form.Item style={{ marginBottom: '0px' }} name="color">
                  <ColorPicker baseColor={baseColor} defaultRegionColor={defaultRegionColor} />
                </Form.Item>
              )}
            </Col>
          )}
        </Row>
      </Col>
      <Col
        className={cn(!title && styles.editorWrapper)}
        span={24}
        style={{
          fontSize: fontSize ? `${fontSize}px` : 'unset',
          lineHeight: lineHeight ? `${lineHeight}px` : 'unset',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Row gutter={[5, 5]}>
          {textPrefix && (
            <Col md={1} xs={2}>
              {textPrefix && textPrefix}
            </Col>
          )}
          <Col md={textPrefix ? 23 : 24} xs={textPrefix ? 22 : 24}>
            <Editor
              ref={ref}
              customStyleMap={customStyleMap}
              blockRenderMap={RenderMap}
              editorState={editorState}
              onChange={handleChange}
              placeholder={placeholder}
              keyBindingFn={keyBindings}
              handleKeyCommand={handleKeyCommand}
              handlePastedText={handlePastedText}
              handleBeforeInput={handleBeforeInput}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
