import { ContentBlock, ContentState } from 'draft-js';

import { isUrlCorrect } from '~utils';

import { RichTextBlock } from '../types';

export const validateLinks = (contentBlocks: ContentBlock[], contentState: ContentState) => {
  contentBlocks.forEach((contentBlock) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null && contentState.getEntity(entityKey).getType() === RichTextBlock.link
        );
      },
      (start) => {
        const { url } = contentState.getEntity(contentBlock.getEntityAt(start)).getData();

        if (!isUrlCorrect(url)) {
          throw new Error('url not valid');
        }
      },
    );
  });
};
