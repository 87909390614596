import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { always, equals, ifElse } from 'ramda';
import React from 'react';

import { SortDown, SortUp } from '~assets';
import { SortDirections } from '~constants';

import style from './styles.module.scss';

export interface SortField {
  label: string;
  value: string;
}

export type SortValue = Record<string, SortDirections>;

interface Props {
  fields: SortField[];
  onChange(sort: SortValue): void;
  value: SortValue;
}

const getReverseSort = ifElse(
  equals(SortDirections.Asc),
  always(SortDirections.Desc),
  always(SortDirections.Asc),
);

export const Sort: React.FC<Props> = ({ fields, onChange, value }) => {
  const onSortChange = (field: string) => () => {
    onChange({ [field]: getReverseSort(value[field]) });
  };

  const isSort = (name: string, sort: SortDirections) => value && value[name] === sort;

  return (
    <Row gutter={[20, 0]}>
      <>
        <Col>
          <Typography.Text className={style.sort}>Сортировать:</Typography.Text>
        </Col>
        {fields.map(({ label, value }) => (
          <Col key={label} onClick={onSortChange(value)}>
            <Row align="middle" gutter={[5, 0]}>
              <Col>
                <Typography.Text
                  className={classNames(
                    style.label,
                    (isSort(value, SortDirections.Asc) || isSort(value, SortDirections.Desc)) &&
                      style.selected,
                  )}
                >
                  {label}
                </Typography.Text>
              </Col>
              <Col
                className={classNames(
                  style.iconWrapper,
                  (isSort(value, SortDirections.Asc) || isSort(value, SortDirections.Desc)) &&
                    style.selected,
                )}
              >
                {isSort(value, SortDirections.Asc) && <SortDown />}
                {isSort(value, SortDirections.Desc) && <SortUp />}
              </Col>
            </Row>
          </Col>
        ))}
      </>
    </Row>
  );
};
