import { createSlice } from '@reduxjs/toolkit';

import { employeesAdapter } from '~adapters';
import { employeesApi } from '~services';

interface State {
  count: number;
  lastRequestId?: string;
}

export const employeesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(employeesApi.endpoints.getEmployeesList.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0) {
          employeesAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(employeesApi.endpoints.getEmployeesList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          employeesAdapter.addMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(employeesApi.endpoints.createEmployee.matchFulfilled, (state, action) => {
        employeesAdapter.upsertOne(state, action.payload);
        state.count += 1;
      })
      .addMatcher(employeesApi.endpoints.deleteEmployee.matchFulfilled, (state) => {
        employeesAdapter.removeAll(state);
      })
      .addMatcher(employeesApi.endpoints.getEmployeeById.matchFulfilled, (state, action) => {
        employeesAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(employeesApi.endpoints.updateEmployee.matchFulfilled, (state, action) => {
        employeesAdapter.updateOne(state, {
          changes: action.payload,
          id: action.meta.arg.originalArgs.id,
        });
      });
  },
  initialState: employeesAdapter.getInitialState<State>({
    count: 0,
  }),
  name: 'employees',
  reducers: {},
});

export const employeesReducer = employeesSlice.reducer;
