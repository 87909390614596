import { Col, Row } from 'antd';
import { Block, BlockType, blockTypeChildMap } from 'pn-backend';
import { always, ascend, cond, equals, prop, sort, T } from 'ramda';
import React, { Fragment } from 'react';

import { Visible } from '~components';

import { AddBlock } from '../AddBlock';
import { EmptyBlock } from '../Blocks/EmptyBlock';
import { TestQuestionBlock } from '../Blocks/TestQuestionBlock';

interface Props {
  blocks: Block<BlockType, string>[];
  parentId: number;
}

const getBlockComponent = cond([
  [equals(BlockType.TestQuestion), always(TestQuestionBlock)],
  [T, always(EmptyBlock)],
]);

const [, , availableToAddBlocks] = blockTypeChildMap.test;

const sortBlocks = sort<Block<BlockType, string>>(ascend(prop('sortOrder')));

export const TestBlocks: React.FunctionComponent<Props> = React.memo(({ blocks, parentId }) => {
  return (
    <Row gutter={[0, 20]}>
      {sortBlocks(blocks)?.map((block, blockIndex) => {
        const BlockComponent = getBlockComponent(block.type);

        const { id, sortOrder } = block;

        return BlockComponent ? (
          <Fragment key={id}>
            <Col span={24}>
              <BlockComponent block={block} blockIndex={blockIndex} canDelete={blockIndex > 0} />
            </Col>
            <Visible isVisible={sortOrder >= -1}>
              <Col span={24}>
                <AddBlock
                  availableBlocks={availableToAddBlocks}
                  sortOrder={sortOrder}
                  parentId={parentId}
                />
              </Col>
            </Visible>
          </Fragment>
        ) : null;
      })}
    </Row>
  );
}, equals);
