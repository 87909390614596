import { notification, Typography } from 'antd';
import type { ArgsProps } from 'antd/lib/notification';

export const useNotification =
  () =>
  ({ message, ...props }: ArgsProps) => {
    notification.open({
      className: 'pn_notification',
      message: <Typography.Text color="white">{message}</Typography.Text>,
      ...props,
    });
  };
