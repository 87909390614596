import { Col, Row } from 'antd';
import { not } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge } from '~components';
import { BadgeColor } from '~constants';
import { AuthorModal, AuthorsList, MobilePageHeader, PageHeader } from '~containers';
import { countAuthorsSelector } from '~selectors';

import styles from '../EmployeesPage/styles.module.scss';

export const AuthorsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
  const [editableAuthor, setEditableAuthor] = useState<number>();

  const count = useSelector(countAuthorsSelector);

  const toggleModal = () => {
    setIsCreationModalOpen(not);
    setEditableAuthor(undefined);
  };

  const openEditModal = (id: number) => {
    toggleModal();
    setEditableAuthor(id);
  };

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Авторы"
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="small">
              {count ?? 0}
            </Badge>
          }
          placeholderText="Поиск по имени и фамилии"
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader placeholderText="Поиск по имени и фамилии" onSearch={setSearchTerm} />
      </Col>
      <Col span={24}>
        <AuthorsList onEdit={openEditModal} searchTerm={searchTerm} />
        <AuthorModal onClose={toggleModal} isOpen={isCreationModalOpen} id={editableAuthor} />
      </Col>
    </Row>
  );
};
