import { createDraftSafeSelector, EntityId } from '@reduxjs/toolkit';
import type { EmployeeExtSecure } from 'pn-backend';
import { prop } from 'ramda';

import { employeesAdapter } from '~adapters';
import { isDefined } from '~utils';

import { stateSelector } from './state';

export const employeesEntitySelector = employeesAdapter.getSelectors<RootState>(prop('employees'));

export const employeesSelector = createDraftSafeSelector(
  stateSelector,
  prop<EmployeeExtSecure[]>('employees'),
);

export const countEmployeesSelector = createDraftSafeSelector(
  employeesSelector,
  prop<EntityId[]>('count'),
);

export const employeeByIdSelector = (id?: number) => (state: RootState) =>
  isDefined(id) ? employeesEntitySelector.selectById(state, id) : undefined;
