import { Col, Row } from 'antd';
import { Block, BlockType, blockTypeChildMap } from 'pn-backend';
import { always, ascend, cond, equals, prop, sort, T } from 'ramda';
import React, { Fragment } from 'react';

import { Visible } from '~components';

import { AddBlock } from '../AddBlock';
import { ColumnsBlock } from '../Blocks/ColumnsBlock';
import { CoverBlock } from '../Blocks/CoverBlock';
import { EmptyBlock } from '../Blocks/EmptyBlock';
import { GalleryBlock } from '../Blocks/GalleryBlock';
import { HTitleBlock } from '../Blocks/HTitleBlock';
import { ImageBlock } from '../Blocks/ImageBlock';
import { LineBlock } from '../Blocks/LineBlock';
import { ListBlock } from '../Blocks/ListBlock';
import { MoreOnTopicBlock } from '../Blocks/MoreOnTopicBlock';
import { NewsSourceBlock } from '../Blocks/NewsSourceBlock';
import { PreviewBlock } from '../Blocks/PreviewBlock';
import { QuizBlock } from '../Blocks/QuizBlock';
import { QuoteBlock } from '../Blocks/QuoteBlock';
import { SeoBlock } from '../Blocks/SeoBlock';
import { SpecLinkBlock } from '../Blocks/SpecLinkBlock';
import { TextBlock } from '../Blocks/TextBlock';
import { TitleBlock } from '../Blocks/TitleBlock';
import { VideoBlock } from '../Blocks/VideoBlock';
import { WidgetBlock } from '../Blocks/WidgetBlock';

interface Props {
  blocks: Block<BlockType, string>[];
  parentId: number;
}

const getBlockComponent = cond([
  [equals(BlockType.H1), always(TitleBlock)],
  [equals(BlockType.H2), always(HTitleBlock)],
  [equals(BlockType.H3), always(HTitleBlock)],
  [equals(BlockType.Subtitle), always(TitleBlock)],
  [equals(BlockType.SeoTitle), always(SeoBlock)],
  [equals(BlockType.Description), always(SeoBlock)],
  [equals(BlockType.Intro), always(TextBlock)],
  [equals(BlockType.Text), always(TextBlock)],
  [equals(BlockType.NewsSource), always(NewsSourceBlock)],
  [equals(BlockType.Cover), always(CoverBlock)],
  [equals(BlockType.Gif), always(ImageBlock)],
  [equals(BlockType.Image), always(ImageBlock)],
  [equals(BlockType.Video), always(VideoBlock)],
  [equals(BlockType.Widget), always(WidgetBlock)],
  [equals(BlockType.SpecLink), always(SpecLinkBlock)],
  [equals(BlockType.Quiz), always(QuizBlock)],
  [equals(BlockType.Line), always(LineBlock)],
  [equals(BlockType.Quote), always(QuoteBlock)],
  [equals(BlockType.MoreOnTopic), always(MoreOnTopicBlock)],
  [equals(BlockType.List), always(ListBlock)],
  [equals(BlockType.Columns), always(ColumnsBlock)],
  [equals(BlockType.Preview), always(PreviewBlock)],
  [equals(BlockType.Gallery), always(GalleryBlock)],
  [T, always(EmptyBlock)],
]);

const [fixedDefaultBlocks, , availableToAddBlocks] = blockTypeChildMap.card;

const sortBlocks = sort<Block<BlockType, string>>(ascend(prop('sortOrder')));

export const CardBlocks: React.FunctionComponent<Props> = React.memo(({ blocks, parentId }) => {
  return (
    <Row gutter={[0, 20]}>
      {sortBlocks(blocks)?.map((block, blockIndex) => {
        const BlockComponent = getBlockComponent(block.type);

        const { id, sortOrder, type } = block;

        const blockIsFixed = fixedDefaultBlocks.find((value) => type === value);

        return BlockComponent ? (
          <Fragment key={id}>
            <Col span={24}>
              <BlockComponent block={block} blockIndex={blockIndex} canDelete={!blockIsFixed} />
            </Col>
            <Visible isVisible={sortOrder >= -1}>
              <Col span={24}>
                <AddBlock
                  availableBlocks={availableToAddBlocks}
                  sortOrder={sortOrder}
                  parentId={parentId}
                />
              </Col>
            </Visible>
          </Fragment>
        ) : null;
      })}
    </Row>
  );
}, equals);
