import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { PostProvider, SettingsContext, SettingsCustomPage } from '~providers';
import { blocksEntitySelector } from '~selectors';
import { useGetPostBlocksQuery } from '~services';

import { PostBlocks } from '../../../PostBlocks';
import { SettingsTabWrapper } from '../Wrapper/Wrapper';

interface Props {
  sectionAlias: SettingsCustomPage;
}
export const CustomPage: FC<Props> = (props) => {
  const { sectionAlias } = props;
  const { posts } = useContext(SettingsContext);
  const { isError, isFetching, post } = posts[sectionAlias];

  useGetPostBlocksQuery(
    { postId: post?.id, relations: ['blocks', 'files', 'blocks.files'] },
    { refetchOnMountOrArgChange: true, skip: !post?.id },
  );

  const blocks = useSelector(blocksEntitySelector.selectAll);

  return (
    <SettingsTabWrapper isError={isError} isFetching={isFetching} hasContent={!!post}>
      {post ? (
        <PostProvider post={post}>
          <PostBlocks blocks={blocks} type={post.type} />
        </PostProvider>
      ) : null}
    </SettingsTabWrapper>
  );
};
