import { Button, Col, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { GridSchemaSecure, PostType } from 'pn-backend';
import { always, ifElse, isNil } from 'ramda';
import React, { FC } from 'react';

import { Modal } from '~components';
import { getTypeTitle } from '~utils';

import styles from './styles.module.scss';

const { Option } = Select;

interface Props {
  gridSchema?: Partial<GridSchemaSecure>;
  isOpen?: boolean;

  onClose(gridSchema?: Partial<GridSchemaSecure>): void;
}

const getModalTitle = ifElse(isNil, always('Добавление схемы'), always('Редактирование схемы'));
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

export const GridSchemaModal: FC<Props> = ({ gridSchema, isOpen = false, onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleFinish = (values: Partial<GridSchemaSecure>) => {
    onClose({ ...gridSchema, ...values });
  };

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(gridSchema?.id)}
      destroyOnClose
      onCancel={handleCancel}
      width={470}
      footer={null}
    >
      <Form
        layout="vertical"
        name="section"
        validateTrigger="submit"
        onFinish={handleFinish}
        initialValues={gridSchema}
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item name="title">
                  <Input size="large" placeholder="Введите название схемы" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="filterByPostTypes">
                  <Select size="large" mode="multiple" maxTagCount="responsive">
                    {(Object.keys(PostType) as (keyof typeof PostType)[]).map((key) => {
                      const title = getTypeTitle(PostType[key]);
                      return title ? (
                        <Option key={PostType[key]} value={PostType[key]}>
                          {title}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Typography.Text className={styles.title}>Изменение порядка</Typography.Text>
                  <Form.Item name="canChangeOrder" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Typography.Text className={styles.title}>Изменение постов</Typography.Text>
                  <Form.Item name="canChangePosts" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Typography.Text className={styles.title}>Можно скрыть</Typography.Text>
                  <Form.Item name="canHide" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Typography.Text className={styles.title}>Скрытa</Typography.Text>
                  <Form.Item name="isHidden" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Typography.Text className={styles.title}>Пагинация</Typography.Text>
                  <Form.Item name="canPaginate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={handleCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(gridSchema?.id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
