import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Popover, Row, Select } from 'antd';
import { BlockType, ImageFormat, ImageProxyResize, PostType } from 'pn-backend';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC } from 'react';

import ContentHeightImagePreview from '~assets/png/contentHeightImagePreview.png';
import ContentWidthImagePreview from '~assets/png/contentWidthImagePreview.png';
import FullscreenImagePreview from '~assets/png/fullScreenImagePreview.png';
import HalfContentHeightImagePreview from '~assets/png/halfContentHeightImagePreview.png';
import HeaderWidthImagePreview from '~assets/png/headerWidthImagePreview.png';
import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { Option } = Select;

const getBlockCanFormat = cond([
  [equals(BlockType.Image), always(true)],
  [equals(BlockType.Gif), always(false)],
  [T, always(false)],
]);

const getBlockNeedFormat = cond([
  [equals(PostType.Card), always(false)],
  [T, always(true)],
]);

const getBlockDropType = cond([
  [equals(BlockType.Image), always(DropType.Image)],
  [equals(BlockType.Gif), always(DropType.Gif)],
  [T, always(DropType.Image)],
]);

const getBlockTitle = cond([
  [equals(BlockType.Image), always('Картинка')],
  [equals(BlockType.Gif), always('GIF')],
  [T, always(null)],
]);

const getImageFormatOptionsByTitle = cond([
  [
    equals(PostType.Longread),
    always([
      ImageFormat.Fullscreen,
      ImageFormat.HeaderWidth,
      ImageFormat.ContentWidth,
      ImageFormat.ContentHeight,
      ImageFormat.HalfContentHeight,
    ]),
  ],
  [equals(PostType.Card), always([ImageFormat.ContentWidth])],
  [T, always([ImageFormat.ContentWidth, ImageFormat.ContentHeight, ImageFormat.HalfContentHeight])],
]);

const getImageFormatTitle = cond([
  [equals(ImageFormat.Fullscreen), always('Фуллскрин')],
  [equals(ImageFormat.HeaderWidth), always('По ширине 1200px')],
  [equals(ImageFormat.ContentWidth), always('По ширине 750px')],
  [equals(ImageFormat.ContentHeight), always('По высоте 750px')],
  [equals(ImageFormat.HalfContentHeight), always('По высоте 450px')],
  [T, always(null)],
]);

const getPopoverContent = cond([
  [equals(ImageFormat.Fullscreen), always(<img src={FullscreenImagePreview} alt="" />)],
  [equals(ImageFormat.HeaderWidth), always(<img src={HeaderWidthImagePreview} alt="" />)],
  [equals(ImageFormat.ContentWidth), always(<img src={ContentWidthImagePreview} alt="" />)],
  [equals(ImageFormat.ContentHeight), always(<img src={ContentHeightImagePreview} alt="" />)],
  [
    equals(ImageFormat.HalfContentHeight),
    always(<img src={HalfContentHeightImagePreview} alt="" />),
  ],
  [T, always(null)],
]);

export const ImageBlock: FC<BlockProps<BlockType.Image>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { type: postType } = usePost();
  const { content, files, type } = block;

  const isMobile = useIsMobile();

  const formatValues: FormBlockProps<BlockType.Image>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId].filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>{getBlockTitle(type)}</div>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              type={getBlockDropType(type)}
              imageOptions={{
                height: isMobile ? 177 : 240,
                resize: ImageProxyResize.Fill,
                width: isMobile ? 315 : 420,
              }}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Row gutter={[25, 25]}>
            {getBlockCanFormat(type) && (
              <Col span={24}>
                <Form.Item
                  name="format"
                  className={styles.inputRow}
                  hidden={!getBlockNeedFormat(postType)}
                >
                  <Select size="large" bordered={false} className={styles.input}>
                    {getImageFormatOptionsByTitle(postType).map((imageFormatOption) => (
                      <Option
                        key={imageFormatOption}
                        value={imageFormatOption}
                        className={styles.input}
                      >
                        <Row justify="space-between">
                          <Col>{getImageFormatTitle(imageFormatOption)}</Col>
                          <Col>
                            <Popover
                              placement="right"
                              content={getPopoverContent(imageFormatOption)}
                            >
                              <QuestionCircleOutlined />
                            </Popover>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item name="caption" className={styles.inputRow}>
                <Input bordered={false} className={styles.input} placeholder="Подпись" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
