import { Col, Form, FormListFieldData, Input, Radio, Row } from 'antd';
import cn from 'classnames';
import { BlockType, ImageProxyResize, MoreOnTopicType } from 'pn-backend';
import { objOf, prop, times } from 'ramda';
import React, { FC } from 'react';

import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const MoreOnTopicBlock: FC<BlockProps<BlockType.MoreOnTopic>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const currentType = Form.useWatch('type', form);

  const isMobile = useIsMobile();

  const { content, files } = block;

  const formatValues: FormBlockProps<BlockType.MoreOnTopic>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId].filter(isNotNil) as number[],
  });

  const getFormFieldsByType = () => {
    switch (currentType) {
      case MoreOnTopicType.Image:
        return (
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Row justify="center">
                <Col xs={24} md={12}>
                  <Form.Item
                    shouldUpdate
                    name="fileId"
                    getValueFromEvent={prop('id')}
                    getValueProps={() => {
                      return {
                        value: getFileById(content.fileId ?? null, files),
                      };
                    }}
                  >
                    <DropZone
                      type={DropType.Image}
                      imageOptions={{
                        height: isMobile ? 177 : 240,
                        resize: ImageProxyResize.Fill,
                        width: isMobile ? 315 : 420,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[30, 30]}>
                <Col xs={24} md={12}>
                  <Form.List name="items" key="items">
                    {(fields) =>
                      fields.slice(0, 1).map((item, index) => (
                        <Row gutter={[30, 30]} key={`item-${index}`}>
                          <Col span={24}>
                            <Form.Item name={[index, 'caption']} className={styles.inputRow}>
                              <TextArea
                                autoSize
                                rows={1}
                                className={styles.input}
                                bordered={false}
                                placeholder="Заголовок"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={[index, 'link']} className={styles.inputRow}>
                              <TextArea
                                autoSize
                                rows={1}
                                className={styles.input}
                                bordered={false}
                                placeholder="Ссылка"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))
                    }
                  </Form.List>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      case MoreOnTopicType.Text:
        return (
          <Row gutter={[30, 30]}>
            <Form.List name="items">
              {(fields) => {
                let items: (FormListFieldData | Record<any, number>)[] = [...fields];
                if (fields.length < 3) {
                  items = [...fields, ...times(objOf('name'), 3 - fields.length)];
                }
                return items.map((item, index) => (
                  <Col span={24} key={`item-${index}`}>
                    <Row gutter={[30, 30]}>
                      <Col span={24}>
                        <Form.Item name={[index, 'caption']} className={styles.inputRow}>
                          <TextArea
                            autoSize
                            rows={1}
                            className={styles.input}
                            bordered={false}
                            placeholder="Заголовок"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={[index, 'link']} className={styles.inputRow}>
                          <TextArea
                            autoSize
                            rows={1}
                            className={styles.input}
                            bordered={false}
                            placeholder="Ссылка"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ));
              }}
            </Form.List>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Еще по теме</Col>
            <Col>
              <Form.Item name="type">
                <Radio.Group>
                  <Radio.Button
                    className={cn(
                      styles.type,
                      currentType === MoreOnTopicType.Image && styles.selected,
                    )}
                    value={MoreOnTopicType.Image}
                  >
                    Изображение
                  </Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.type,
                      currentType === MoreOnTopicType.Text && styles.selected,
                    )}
                    value={MoreOnTopicType.Text}
                  >
                    Текст
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>{getFormFieldsByType()}</Col>
      </Row>
    </FormBlock>
  );
};
