import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Radio, Row } from 'antd';
import cn from 'classnames';
import { RawDraftContentState } from 'draft-js';
import { BlockType, ListType } from 'pn-backend';
import React, { FC, Fragment, useMemo, useState } from 'react';

import { Delete, Dot } from '~assets';
import { RichEditor } from '~components';
import { BlockProps } from '~types';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const EMPTY_RAW_DRAFT_CONTENT_STATE: RawDraftContentState = {
  blocks: [],
  entityMap: {},
};

export const ListBlock: FC<BlockProps<BlockType.List>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const [lastAdded, setLastAdded] = useState(-1);

  const currentType = Form.useWatch('type', form);

  const { getFieldValue } = form;

  const { content } = block;

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  const getListPrefix = (type: ListType, index = 0) => {
    switch (type) {
      case ListType.Dotted:
        return (
          <div className={styles.dot}>
            <Dot />
          </div>
        );
      case ListType.Numbered:
        return <div>{index + 1}.</div>;
      default:
        return null;
    }
  };

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      initialValues={initialValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Список</Col>
            <Col>
              <Form.Item name="type">
                <Radio.Group>
                  <Radio.Button
                    className={cn(
                      styles.type,
                      currentType === ListType.Numbered && styles.selected,
                    )}
                    value={ListType.Numbered}
                  >
                    Цифровой
                  </Radio.Button>
                  <Radio.Button
                    className={cn(styles.type, currentType === ListType.Dotted && styles.selected)}
                    value={ListType.Dotted}
                  >
                    Символьный
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between" gutter={[30, 30]}>
            <Col span={24}>
              <Form.List name="options">
                {(fields, { add, remove }) => (
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <Row gutter={[20, 20]} align="bottom" justify="space-between">
                        {fields.map((item, index) => {
                          const optionValue = getFieldValue(['options', index, 'content']);
                          return (
                            <Fragment key={item.key}>
                              <Col md={23} xs={20}>
                                <Form.Item name={[index, 'content']}>
                                  <RichEditor
                                    needFocus={index === lastAdded}
                                    externalIndex={index}
                                    withColor={false}
                                    defaultData={optionValue}
                                    fontSize={20}
                                    lineHeight={24}
                                    placeholder="Пункт"
                                    contentBlockTypesToUnstyled={[
                                      'unordered-list-item',
                                      'ordered-list-item',
                                    ]}
                                    textPrefix={getListPrefix(currentType, index)}
                                    addBlocksOnPaste={(
                                      content: RawDraftContentState,
                                      insertIndex: number,
                                    ) => {
                                      add({ content }, insertIndex);
                                    }}
                                    onEnterPress={() => {
                                      add({ content: EMPTY_RAW_DRAFT_CONTENT_STATE }, index + 1);
                                      setLastAdded(index + 1);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Button
                                icon={
                                  <Delete
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                }
                                type="text"
                              />
                            </Fragment>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Button
                        onClick={() => {
                          add({ content: EMPTY_RAW_DRAFT_CONTENT_STATE });
                        }}
                        type="text"
                        className={styles.addButtonWrapper}
                      >
                        <Row gutter={[10, 10]}>
                          <Col>
                            <PlusOutlined className={styles.addButton} />
                          </Col>
                          <Col>Добавить пункт</Col>
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
