import { BlockType, ImageFormat } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentImageSchema = z.object<ValidatesBlockContent<BlockType.Image>>({
  caption: z.string(),
  fileId: z.number({
    invalid_type_error:
      'В блоке "Картинка" обязательно должно быть загружено изображение. Загрузите необходимые данные',
    required_error:
      'В блоке "Картинка" обязательно должно быть загружено изображение. Загрузите необходимые данные',
  }),
  format: z.nativeEnum(ImageFormat),
});
