import { Button, Card, Col, Row, Switch, Typography } from 'antd';
import { SectionPreviewSecure, SectionStatus } from 'pn-backend';
import { always, equals, ifElse } from 'ramda';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Edit } from '~assets';
import { updateManySections } from '~slices';
import { formatDate } from '~utils';

import styles from './styles.module.scss';

interface Props {
  item: SectionPreviewSecure;

  onEdit(section: SectionPreviewSecure): void;
}

const getNewStatus: (status: SectionStatus) => SectionStatus = ifElse(
  equals(SectionStatus.published),
  always(SectionStatus.draft),
  always(SectionStatus.published),
);

export const SectionCard: React.FC<Props> = ({ item, onEdit }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(item.status);
  const { canChangeStatus, createdAt, id, postCount, title } = item;

  const togglePublished = () => {
    const newStatus = getNewStatus(status);

    setStatus(newStatus);
    dispatch(updateManySections([{ changes: { status: newStatus }, id }]));
  };

  const onClickEdit = () => {
    onEdit(item);
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Row className={styles.wrapper} align="middle">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Row gutter={[10, 0]}>
                <Col>
                  <Switch
                    checked={status === 'published'}
                    disabled={!canChangeStatus}
                    onClick={togglePublished}
                  />
                </Col>
                <Col>
                  <Typography.Text className={styles.title}>{title}</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button icon={<Edit />} type="text" onClick={onClickEdit} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Дата создания:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>
                    {formatDate(createdAt, 'dd MMMM yyyy')}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Публикации:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>{postCount || 0}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
