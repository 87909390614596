import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Update } from '@reduxjs/toolkit';
import { Col, ColProps, Result, Row } from 'antd';
import { all, has } from 'ramda';
import { ComponentType, FunctionComponent } from 'react';

import { InfiniteScroll, SortableItem } from '~components';
import { getSortValuesFunc, isDefined } from '~utils';

type UniqueIdentifierModel = {
  id: number;
};

type SortableModel = UniqueIdentifierModel & {
  sortOrder: number;
};

type ItemType = UniqueIdentifierModel | (UniqueIdentifierModel & SortableModel);

type CardComponentProps<T> = { item: T; onDelete?(item: T): void; onEdit(item: T): void };

interface Props<T extends ItemType = ItemType> {
  card: ComponentType<CardComponentProps<T>>;
  col?: ColProps;
  count?: number;
  hasMore?: boolean;
  isFetching: boolean;
  items: T[];
  onDelete?(item: T): void;
  onEdit(item: T): void;
  onFetch: () => Promise<void>;
  onSortEnd?: (changes: Array<Update<T>>) => void;
}

const isSortableItems = (items: ItemType[]): items is SortableModel[] =>
  all(has('sortOrder'), items);

export const ModelList: FunctionComponent<Props> = ({
  card: Card,
  count,
  hasMore = false,
  isFetching,
  items,
  onDelete,
  onEdit,
  onFetch,
  onSortEnd,
  col = { lg: 12, xl: 8 },
}) => {
  const sortable = isSortableItems(items);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { delay: 300, tolerance: 50 } }),
  );

  const sort = sortable ? getSortValuesFunc(items) : null;

  const onDragEnd = (event: DragEndEvent) => {
    const changes = isDefined(sort) && sort(event);

    if (changes && changes.length > 0 && onSortEnd) {
      onSortEnd(changes);
    }
  };

  if (count === 0) {
    return (
      <Row>
        <Col span={24}>
          <Result status="info" title="Список пуст" />
        </Col>
      </Row>
    );
  }

  return (
    <InfiniteScroll fetchData={onFetch} hasMore={hasMore} isFetching={isFetching}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Row gutter={[25, 25]}>
            {items.map((item) => (
              // отключит сорт из-за лагов на мобиле
              <SortableItem key={item.id} disabled id={item.id} {...col}>
                <Card item={item} onEdit={onEdit} onDelete={onDelete} />
              </SortableItem>
            ))}
          </Row>
        </SortableContext>
      </DndContext>
    </InfiniteScroll>
  );
};
