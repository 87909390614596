import { Button as AntdButton, ButtonProps, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = ButtonProps & {
  className?: string;
  iconFillType?: 'fill' | 'stroke';
  textClassName?: string;
};

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className,
    iconFillType = 'fill',
    textClassName,
    type = 'default',
    ...buttonProps
  } = props;

  return (
    <AntdButton
      ref={ref}
      type={type}
      {...buttonProps}
      className={classNames(styles.button, styles[type], styles[iconFillType], className)}
    >
      <Typography.Text className={classNames(styles.text, textClassName)}>
        {children}
      </Typography.Text>
    </AntdButton>
  );
});
