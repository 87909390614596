import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EmployeeCard, InfiniteScroll } from '~components';
import { employeesEntitySelector } from '~selectors';
import { useGetEmployeesListQuery } from '~services';
import { isDefined } from '~utils';

interface Props {
  onEdit?(id?: number): void;
  searchTerm?: string;
}

// ToDo Отрефакторить в ModelList
export const EmployeesList: React.FC<Props> = (props) => {
  const { onEdit, searchTerm } = props;
  const [offset, setOffset] = useState(0);
  const { data, isFetching, refetch } = useGetEmployeesListQuery({
    name: searchTerm,
    offset,
    relations: ['avatar'],
  });
  const employees = useSelector(employeesEntitySelector.selectAll) ?? [];

  const fetchData = async () => {
    setOffset(employees?.length);
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <InfiniteScroll
      fetchData={fetchData}
      hasMore={isDefined(data) && data?.count > employees?.length}
      isFetching={isFetching}
    >
      <Row
        gutter={[
          { md: 20, xs: 10 },
          { md: 20, xs: 10 },
        ]}
      >
        {employees?.map((employee) => (
          <EmployeeCard key={employee.id} item={employee} onEdit={onEdit} />
        ))}
      </Row>
    </InfiniteScroll>
  );
};
