import { Col, Result, Row, Space, Typography } from 'antd';
import { GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import { FC } from 'react';

import { deleteMarkup, formatDate } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

interface NewsRowProps {
  post: PostPreviewSecure<PostType>;
}

const News: FC<NewsRowProps> = ({ post }) => {
  const { newsSource, publishedAt, title } = post;

  return (
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Space>
            <Typography.Text className={styles.publishedAt}>
              {formatDate(publishedAt, 'HH:mm')}
            </Typography.Text>
            <Typography.Text className={styles.source}>{newsSource?.name}</Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <Typography.Text className={styles.title}>{deleteMarkup(title?.text)}</Typography.Text>
        </Col>
      </Row>
    </Col>
  );
};

export const MainNews: FC<GridSchemaSecure> = (props) => {
  const { posts } = props;

  return (
    <BlockWrapper {...props} type="news">
      <Row gutter={[0, 16]} className={styles.content}>
        {posts?.length === 0 && (
          <Col span={24}>
            <Result
              status="error"
              title={
                <Typography.Text className={styles.error}>Отсутствуют публикации</Typography.Text>
              }
            />
          </Col>
        )}
        {posts?.map((post) => (
          <News key={post.id} post={post} />
        ))}
      </Row>
    </BlockWrapper>
  );
};
