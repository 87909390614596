import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import { BlockType, ImageProxyResize, PostStatus } from 'pn-backend';
import { prop } from 'ramda';
import React, { FC, Fragment, useState } from 'react';

import { Delete } from '~assets';
import { DropType } from '~constants';
import { usePost } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const QuizBlock: FC<BlockProps<BlockType.Quiz>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content, files } = block;
  const { status } = usePost();
  const [lastAdded, setLastAdded] = useState(-1);

  const formatValues: FormBlockProps<BlockType.Quiz>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [content.fileId].filter(isNotNil) as number[],
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title}>
            <Col>Опрос</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col md={12} xs={24}>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Form.Item
                    name="fileId"
                    getValueFromEvent={prop('id')}
                    getValueProps={() => ({
                      value: getFileById(content.fileId, files),
                    })}
                  >
                    <DropZone
                      type={DropType.Image}
                      imageOptions={{ height: 240, resize: ImageProxyResize.Fill, width: 240 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="caption" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Подпись"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Вопрос</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="question" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Напишите вопрос"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Ответы</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.List name="answerOptions">
                    {(fields, { add, remove }) => (
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Row gutter={[20, 20]} align="bottom" justify="space-between">
                            {fields.map((item, index) => {
                              const onKeyPress = (event: any) => {
                                if (event.charCode === 13 && fields.length <= 3) {
                                  add('', index + 1);
                                  setLastAdded(index + 1);
                                  return true;
                                }
                                return true;
                              };

                              return (
                                <Fragment key={item.key}>
                                  <Col md={22} xs={20}>
                                    <Form.Item name={[index]}>
                                      <TextArea
                                        autoFocus={index === lastAdded}
                                        autoSize
                                        rows={1}
                                        className={cn(styles.input, styles.answer)}
                                        placeholder="Ответ"
                                        onKeyPress={onKeyPress}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Button
                                    disabled={status === PostStatus.Published}
                                    icon={
                                      <Delete
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      />
                                    }
                                    type="text"
                                  />
                                </Fragment>
                              );
                            })}
                          </Row>
                        </Col>
                        {fields.length < 4 && (
                          <Col span={24}>
                            <Button
                              onClick={() => add('')}
                              type="text"
                              className={styles.addButtonWrapper}
                            >
                              <Row gutter={[10, 10]}>
                                <Col>
                                  <PlusOutlined className={styles.addButton} />
                                </Col>
                                <Col>Добавить ответ</Col>
                              </Row>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
