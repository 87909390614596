import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '../../types';
import { getTextFromRawDraftContentState } from '../../utils';
import { draftContentSchema } from '../draft';

export const blockContentTextSchema = z.object<ValidatesBlockContent<BlockType.Text>>({
  content: draftContentSchema,
});

export const blockContentTextSchemaMandatory = z.object<ValidatesBlockContent<BlockType.Text>>({
  content: draftContentSchema.refine(
    (content) => getTextFromRawDraftContentState(content).length > 0,
    {
      message: 'Блок "Текст" - обязателен к заполнению!',
    },
  ),
});
