import { Col, Modal, Row } from 'antd';
import { Tag } from 'pn-backend';
import { not } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TagCard, TitlePage } from '~components';
import { MobilePageHeader, ModelList, PageHeader, TagModal } from '~containers';
import { tagsEntitySelector } from '~selectors';
import { useDeleteTagMutation, useGetTagsListQuery } from '~services';

import styles from './styles.module.scss';

const { confirm } = Modal;

export const TagsPage: React.FC = () => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [tag, setTag] = useState<Tag>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [deleteTag] = useDeleteTagMutation();
  const [offset, setOffset] = useState(0);
  const { data, isFetching, refetch } = useGetTagsListQuery({ name: searchTerm, offset });
  const items = useSelector(tagsEntitySelector.selectAll);

  const toggleModal = () => {
    setIsShowModal(not);
  };

  const onEdit = (tag: Tag) => {
    setTag(tag);
    toggleModal();
  };

  const onDelete = async (tag: Tag) => {
    confirm({
      cancelText: 'Отменить',
      okText: 'Удалить',
      onOk: async () => deleteTag(tag.id),
      title: 'Удалить тег?',
    });
  };

  const handleFetch = async () => {
    setOffset(items?.length);
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Теги"
          onCreate={toggleModal}
          placeholderText="Поиск"
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText="Добавить тег"
          onCreate={toggleModal}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24}>
        <TitlePage title="Теги" />
      </Col>
      <Col span={24}>
        <ModelList
          onEdit={onEdit}
          items={items}
          isFetching={isFetching}
          count={data?.count}
          card={TagCard}
          onDelete={onDelete}
          onFetch={handleFetch}
        />
        <TagModal
          isOpen={isShowModal}
          onClose={() => {
            setTag(undefined);
            toggleModal();
          }}
          id={tag?.id}
        />
      </Col>
    </Row>
  );
};
