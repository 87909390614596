import { Col, Row, Space, Typography } from 'antd';
import { PostStatus, PostType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { Badge } from '~components';
import { BadgeColor } from '~constants';
import { formatDate, getStatusTitle } from '~utils';

import styles from './styles.module.scss';

interface Props {
  createdAt: string;
  edit?: boolean;
  status: PostStatus;
  type: PostType;
  updatedAt: string;
}

const getCreateTitle = cond<[PostType], string>([
  [equals(PostType.NewsFull), always('Новая новость')],
  [equals(PostType.Article), always('Новая статья')],
  [equals(PostType.Longread), always('Новый лонгрид')],
  [equals(PostType.Card), always('Новые карточки')],
  [equals(PostType.Test), always('Новый тест')],
  [equals(PostType.Spec), always('Новый спецпроект')],
]);

const getEditTitle = cond<[PostType], string>([
  [equals(PostType.NewsFull), always('Редактирование новости')],
  [equals(PostType.Article), always('Редактирование статьи')],
  [equals(PostType.Longread), always('Редактирование лонгрида')],
  [equals(PostType.Card), always('Редактирование карточек')],
  [equals(PostType.Test), always('Редактирование теста')],
  [equals(PostType.Spec), always('Редактирование спецпректа')],
]);

const getStatusColor = cond<[PostStatus], BadgeColor>([
  [equals(PostStatus.Published), always(BadgeColor.BlueLight)],
  [T, always(BadgeColor.GreyLight)],
]);

export const PostInfo: React.FC<Props> = ({ createdAt, edit = false, status, type, updatedAt }) => {
  const title = edit ? getEditTitle(type) : getCreateTitle(type);

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Row gutter={[20, 0]}>
          <Col>
            <Typography.Text className={styles.title}>{title}</Typography.Text>
          </Col>
          <Col>
            <Badge color={getStatusColor(status)}>{getStatusTitle(status)}</Badge>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={[5, 5]} align="middle" justify="end">
          <Col xs={24} md={11}>
            <Row justify={{ md: 'end', xs: 'start' }}>
              <Space size="small">
                <Typography.Text className={styles.label}>Создан:</Typography.Text>
                <Typography.Text className={styles.date}>
                  {formatDate(createdAt, 'dd MMMM yyyy, HH:mm')}
                </Typography.Text>
              </Space>
            </Row>
          </Col>
          <Col md={1}>
            <Row justify="center">
              <div className={styles.line} />
            </Row>
          </Col>
          <Col xs={24} md={11}>
            <Space size="small">
              <Typography.Text className={styles.label}>Изменен:</Typography.Text>
              <Typography.Text className={styles.date}>
                {formatDate(updatedAt, 'dd MMMM yyyy, HH:mm')}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
