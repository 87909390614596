import { Col, Form, Input, Radio, Row } from 'antd';
import cn from 'classnames';
import { BlockType, ImageProxyResize, QuoteType } from 'pn-backend';
import { prop } from 'ramda';
import React, { FC, useMemo } from 'react';

import { RichEditor } from '~components';
import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const QuoteBlock: FC<BlockProps<BlockType.Quote>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const currentType = Form.useWatch('type', form);
  const { content, files } = block;

  const formatValues: FormBlockProps<BlockType.Quote>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId].filter(isNotNil) as number[],
  });

  const getFormFieldsByType = useMemo(() => {
    switch (currentType) {
      case QuoteType.Line:
        return (
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Form.Item name="content">
                <RichEditor
                  withColor={false}
                  defaultData={content.content}
                  fontSize={20}
                  lineHeight={24}
                  placeholder="Цитата"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="caption" className={styles.inputRow}>
                <TextArea
                  autoSize
                  rows={1}
                  className={styles.input}
                  bordered={false}
                  placeholder="Подпись"
                />
              </Form.Item>
            </Col>
          </Row>
        );
      case QuoteType.Frame:
        return (
          <Row gutter={[45, 45]}>
            <Col xs={24} md={7}>
              <Form.Item
                name="fileId"
                getValueFromEvent={prop('id')}
                getValueProps={() => ({
                  value: getFileById(content.fileId, files),
                })}
              >
                <DropZone
                  type={DropType.Image}
                  imageOptions={{ height: 236, resize: ImageProxyResize.Fill, width: 236 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={17}>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Form.Item name="content">
                    <RichEditor
                      withColor={false}
                      defaultData={content.content}
                      fontSize={20}
                      lineHeight={31}
                      placeholder="Цитата"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="caption" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Подпись"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      case QuoteType.Expert:
        return (
          <Row gutter={[45, 45]}>
            <Col xs={24} md={7}>
              <Form.Item
                name="fileId"
                getValueFromEvent={prop('id')}
                getValueProps={() => ({
                  value: getFileById(content.fileId, files),
                })}
              >
                <DropZone
                  type={DropType.Image}
                  imageOptions={{ height: 236, resize: ImageProxyResize.Fill, width: 236 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={17}>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Form.Item name="expertName" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="ФИО"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="caption" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Подпись"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      case QuoteType.SelectionLine:
      case QuoteType.Selection:
        return (
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Form.Item name="content">
                <RichEditor
                  withColor={false}
                  defaultData={content.content}
                  fontSize={20}
                  lineHeight={31}
                  placeholder="Цитата"
                />
              </Form.Item>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  }, [content.content, content.fileId, currentType, files]);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Цитата</Col>
            <Col>
              <Form.Item name="type">
                <Radio.Group>
                  <Radio.Button
                    className={cn(
                      styles.quoteType,
                      currentType === QuoteType.Line && styles.selected,
                    )}
                    value={QuoteType.Line}
                  >
                    Линия
                  </Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.quoteType,
                      currentType === QuoteType.Frame && styles.selected,
                    )}
                    value={QuoteType.Frame}
                  >
                    Рамка
                  </Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.quoteType,
                      currentType === QuoteType.Expert && styles.selected,
                    )}
                    value={QuoteType.Expert}
                  >
                    Эксперт
                  </Radio.Button>
                  <Radio.Button className={cn(styles.delimiter)}>|</Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.quoteType,
                      currentType === QuoteType.Selection && styles.selected,
                    )}
                    value={QuoteType.Selection}
                  >
                    Кавычки
                  </Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.quoteType,
                      currentType === QuoteType.SelectionLine && styles.selected,
                    )}
                    value={QuoteType.SelectionLine}
                  >
                    Текст с линией
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>{getFormFieldsByType}</Col>
      </Row>
    </FormBlock>
  );
};
