import { format, parseISO } from 'date-fns/fp';
import { PostPreviewSecure, PostStatus, PostType } from 'pn-backend';
import { always, cond, defaultTo, equals, head, identity, ifElse, pipe, replace, T } from 'ramda';

import { isString } from './functional';

export const isNews = equals(PostType.News);
export const isNewsFull = equals(PostType.NewsFull);
export const isArticle = equals(PostType.Article);
export const isCards = equals(PostType.Card);
export const isCustom = equals(PostType.Custom);
export const isLongread = equals(PostType.Longread);
export const isSpec = equals(PostType.Spec);
export const isTest = equals(PostType.Test);

export const getStatusTitle = cond([
  [equals(PostStatus.Draft), always('Черновик')],
  [equals(PostStatus.Published), always('Опубликован')],
  [equals(PostStatus.Unpublished), always('Снят с публикации')],
  [equals(PostStatus.Archived), always('Архив')],
  [equals(PostStatus.Postponed), always('Ожидает публикации')],
  [T, always('-')],
]);

export const getTypeTitle = cond([
  [isNews, always('Новость(старая)')],
  [isNewsFull, always('Новость')],
  [isLongread, always('Лонгрид')],
  [isSpec, always('Спецпроект')],
  [isCards, always('Карточки')],
  [isArticle, always('Статья')],
  [isTest, always('Тест')],
  [isCustom, always('Кастом')],
]);

export const getText = ifElse(
  isString,
  identity,
  (raw?: IRaw) => head(raw?.blocks || [])?.text || '',
);

export const isPost =
  <T extends PostType>(postType: T) =>
  (post: PostPreviewSecure<PostType>): post is PostPreviewSecure<T> =>
    post.type === postType;

export const getPublishedTime = pipe(parseISO, format('HH:mm'));

export const deleteMarkup = pipe(defaultTo(''), replace(/<[^>]*>?/gm, ''));
