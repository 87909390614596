import { Col, Row, Typography } from 'antd';
import { GridSchemaSecure } from 'pn-backend';
import React from 'react';

import { Cover } from '~components';
import { deleteMarkup } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

export const MainTop: React.FC<GridSchemaSecure> = (props) => {
  const { posts } = props;
  const [post] = posts || [];
  const { preview, title } = post || {};

  return (
    <BlockWrapper {...props} title="Блок топ">
      <Row className={styles.post}>
        <Col span={16} push={8}>
          <Cover src={preview?.imageURLs?.medium} height={249} width="100%" objectFit="cover" />
        </Col>
        <Col span={24} className={styles.text}>
          <Row>
            <Col flex="70%">
              <Typography.Text className={styles.title}>
                {deleteMarkup(title?.text)}
              </Typography.Text>
            </Col>
            {/* <Col flex="40%">
              TODO ипсравить тип
              @ts-ignore
              <Typography.Text className={styles.subTitle}>{subTitle?.text}</Typography.Text>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </BlockWrapper>
  );
};
