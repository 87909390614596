import { createDraftSafeSelector, EntityId } from '@reduxjs/toolkit';
import type { SectionPreviewSecure } from 'pn-backend';
import { prop } from 'ramda';

import { sectionsAdapter } from '~adapters';

import { stateSelector } from './state';

export const sectionsEntitySelector = sectionsAdapter.getSelectors<RootState>(prop('sections'));

export const sectionsSelector = createDraftSafeSelector(
  stateSelector,
  prop<SectionPreviewSecure[]>('sections'),
);

export const changesSectionsEntitySelector = createDraftSafeSelector(
  sectionsSelector,
  prop<EntityId[]>('changes'),
);

export const sectionByIdSelector = (id: number) => (state: RootState) =>
  sectionsEntitySelector.selectById(state, id);
