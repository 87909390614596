import { Col, Row } from 'antd';
import { BlockType, postTypeBlocksMap } from 'pn-backend';
import { always, complement, cond, equals, T } from 'ramda';
import { FC, Fragment, memo } from 'react';

import { Visible } from '~components';
import {
  CardBlock,
  ColumnsBlock,
  CoverBlock,
  EmptyBlock,
  GalleryBlock,
  HTitleBlock,
  ImageBlock,
  IncutBlock,
  LineBlock,
  ListBlock,
  MetaBlock,
  MoreOnTopicBlock,
  NewsSourceBlock,
  QuizBlock,
  QuoteBlock,
  SeoBlock,
  SpecLinkBlock,
  TestBlock,
  TestQuestionBlock,
  TestResultBlock,
  TextBlock,
  TitleBlock,
  VideoBlock,
  WidgetBlock,
} from '~postBlocks';
import { PostProps } from '~types';
import { isBlock } from '~utils';

import { AddBlock } from '../AddBlock';

export const getBlockComponent = cond([
  [equals(BlockType.H1), always(TitleBlock)],
  [equals(BlockType.H2), always(HTitleBlock)],
  [equals(BlockType.H3), always(HTitleBlock)],
  [equals(BlockType.Subtitle), always(TitleBlock)],
  [equals(BlockType.SeoTitle), always(SeoBlock)],
  [equals(BlockType.Description), always(SeoBlock)],
  [equals(BlockType.Intro), always(TextBlock)],
  [equals(BlockType.Text), always(TextBlock)],
  [equals(BlockType.NewsSource), always(NewsSourceBlock)],
  [equals(BlockType.Cover), always(CoverBlock)],
  [equals(BlockType.Gif), always(ImageBlock)],
  [equals(BlockType.Image), always(ImageBlock)],
  [equals(BlockType.Video), always(VideoBlock)],
  [equals(BlockType.Widget), always(WidgetBlock)],
  [equals(BlockType.SpecLink), always(SpecLinkBlock)],
  [equals(BlockType.Quiz), always(QuizBlock)],
  [equals(BlockType.Line), always(LineBlock)],
  [equals(BlockType.Quote), always(QuoteBlock)],
  [equals(BlockType.MoreOnTopic), always(MoreOnTopicBlock)],
  [equals(BlockType.List), always(ListBlock)],
  [equals(BlockType.Columns), always(ColumnsBlock)],
  [equals(BlockType.Card), always(CardBlock)],
  // Не показываем блок в интерфейсе
  [equals(BlockType.Preview), always(null)],
  [equals(BlockType.Incut), always(IncutBlock)],
  [equals(BlockType.Gallery), always(GalleryBlock)],
  [equals(BlockType.Test), always(TestBlock)],
  [equals(BlockType.TestQuestion), always(TestQuestionBlock)],
  [equals(BlockType.TestResults), always(TestResultBlock)],
  [equals(BlockType.Meta), always(MetaBlock)],
  [T, always(EmptyBlock)],
]);

type Props = Pick<PostProps['post'], 'type' | 'blocks'>;

export const PostBlocks: FC<Props> = memo(({ blocks, type }) => {
  const [fixedDefaultBlocks, , availableToAddBlocks] = postTypeBlocksMap[type];

  const hasTestResultBlock = blocks.filter(isBlock(BlockType.TestResults)).length > 0;

  const filterAvailableBlocks = cond([
    // Убираем блок результатов, если уже есть один
    // https://itprojectnm.atlassian.net/browse/PNS-800
    // ToDo Изменить структуру блока так, чтобы результат был неизменной его частью
    [always(hasTestResultBlock), complement(equals(BlockType.TestResults))],
    [T, T],
  ]);

  return (
    <Row gutter={[0, 20]}>
      {blocks?.map((block, blockIndex) => {
        const BlockComponent = getBlockComponent(block.type);

        // ToDo Эту логику нужно перенести в FormBlock, чтобы избежать ненужный проброс пропса canDelete Block -> FormBlock
        // @ts-ignore
        const blockIsFixed = fixedDefaultBlocks.find((value: BlockType) => block.type === value);

        const { sortOrder } = block;

        return BlockComponent ? (
          <Fragment key={block.id}>
            <Col span={24}>
              <BlockComponent block={block} canDelete={!blockIsFixed} blockIndex={blockIndex} />
            </Col>
            <Visible isVisible={sortOrder >= -1}>
              <Col span={24}>
                <AddBlock
                  availableBlocks={(availableToAddBlocks as BlockType[]).filter(
                    filterAvailableBlocks,
                  )}
                  sortOrder={block.sortOrder}
                />
              </Col>
            </Visible>
          </Fragment>
        ) : null;
      })}
    </Row>
  );
}, equals);
