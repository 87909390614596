import { ContentBlock, RawDraftContentState } from 'draft-js';
import { Block, BlockType, FileSecure, PostType } from 'pn-backend';
import { __, always, cond, equals, head, includes, not, pipe } from 'ramda';

export const canBeFullscreen = pipe(includes(__, [PostType.Article, PostType.Card]), not);

export const isBlock =
  <T extends BlockType>(blockType: T) =>
  (block: Block<BlockType, string>): block is Block<T, string> =>
    block.type === blockType;

export const getFileById = (fileId: number | null, files: FileSecure[] = []): FileSecure | null =>
  files.find(({ id }) => fileId === id) ?? null;

export const blockIsListItem = (block: ContentBlock) =>
  block instanceof ContentBlock &&
  ['unordered-list-item', 'ordered-list-item'].includes(block.getType());

export const getBlockDocumentId = (id: number) => `block-${id}`;

export const getTextFromRawDraftContentState = (state: RawDraftContentState) =>
  head(state?.blocks || [])?.text || '';

export const getBlockTitle = cond([
  [equals(BlockType.Intro), always('Вводка')],
  [equals(BlockType.H2), always('Заголовок H2')],
  [equals(BlockType.H3), always('Заголовок H3')],
  [equals(BlockType.Text), always('Текст')],
  [equals(BlockType.Quote), always('Цитата')],
  [equals(BlockType.Incut), always('Врезка')],
  [equals(BlockType.Image), always('Картинка')],
  [equals(BlockType.Gif), always('GIF')],
  [equals(BlockType.Gallery), always('Галерея')],
  [equals(BlockType.Video), always('Видео')],
  [equals(BlockType.Widget), always('Embed')],
  [equals(BlockType.MoreOnTopic), always('Еще по теме')],
  [equals(BlockType.List), always('Список')],
  [equals(BlockType.Line), always('Разделитель')],
  [equals(BlockType.Quiz), always('Опрос')],
  [equals(BlockType.Columns), always('Колонки')],
  [equals(BlockType.TestQuestion), always('Вопрос')],
  [equals(BlockType.TestResults), always('Результат')],
  [equals(BlockType.Card), always('Карточка')],
]);
