import { EditorState } from 'draft-js';
import EmojiPicker from 'emoji-picker-react';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { getEmoji } from '../../helpers';

type EmojiFormProps = {
  editorState: EditorState;
  onSubmit: Dispatch<SetStateAction<EditorState>>;
};

export const EmojiSelector: FC<EmojiFormProps> = ({ editorState, onSubmit }) => {
  const handleFinish = useCallback(
    async (value: any) => {
      if (value) {
        onSubmit(getEmoji(editorState, value.emoji));
      }
    },
    [editorState],
  );

  return <EmojiPicker onEmojiClick={handleFinish} autoFocusSearch={false} />;
};
