import { Col, Form, Row, Switch, Typography } from 'antd';
import classNames from 'classnames';
import React, { Fragment } from 'react';

import { FakeInput } from '~components';

import styles from './styles.module.scss';

export const SocialShare = () => (
  <Row gutter={[0, 16]} align="middle" className={classNames(styles.wrapper)}>
    <Form.List name="socialNetworkShare">
      {(fields) =>
        fields.map((item, index) => (
          <Fragment key={index}>
            <Col md={{ span: 3 }} xs={{ span: 5 }}>
              <Form.Item name={[index, 'use']} valuePropName="checked" noStyle>
                <Switch />
              </Form.Item>
            </Col>
            <Col md={{ span: 21 }} xs={{ span: 19 }}>
              <Form.Item name={[index, 'title']}>
                <FakeInput component={Typography.Text} />
              </Form.Item>
            </Col>
          </Fragment>
        ))
      }
    </Form.List>
  </Row>
);
