import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { UserProps } from '~types';

type Context = Partial<UserProps>;

export const UserContext = createContext<Context>({});

export const UserProvider: FC<PropsWithChildren<Context>> = ({ children, user }) => {
  const value = useMemo(() => ({ user }), [user]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
