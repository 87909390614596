import { DeleteOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Radio, Row, Space } from 'antd';
import cn from 'classnames';
import { BlockType, FileSecure, FileType, GalleryType, ImageProxyResize } from 'pn-backend';
import { dec, inc, prop } from 'ramda';
import React, { FC, useState } from 'react';

import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { BlockContent, BlockProps } from '~types';
import { getFileById, isDefined, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Gallery = BlockType.Gallery;

export const GalleryBlock: FC<BlockProps<Gallery>> = ({ block, blockIndex, canDelete = false }) => {
  const [form] = Form.useForm<BlockContent<Gallery>>();
  const { content, files } = block;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [localFiles, setLocalFiles] = useState(files);

  const isMobile = useIsMobile();

  const currentType = Form.useWatch<BlockContent<Gallery>['type']>('type', form);
  const currentImages =
    Form.useWatch<BlockContent<Gallery>['images']>('images', form) ?? content.images;

  const isFirst = currentImageIndex === 0;
  const isTheOnly = currentImages.length <= 1;
  const isLast = currentImageIndex === currentImages.length - 1;

  const formatValues: FormBlockProps<Gallery>['formatValues'] = (content) => ({
    content: {
      ...content,
      // Для корректного отображения галерей на сайте нужны точные размеры картинок, они становятся доступными после постобработки файла
      images: content.images.map((image) => ({
        ...image,
        height: (getFileById(image.fileId, localFiles) as FileSecure<string, FileType.Image>)?.meta
          .height,
        width: (getFileById(image.fileId, localFiles) as FileSecure<string, FileType.Image>)?.meta
          .width,
      })),
    },
    fileIds: content.images.map(prop('fileId')).filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        {/* Хедер */}
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Галерея</Col>
            <Col>
              <Form.Item name="type">
                <Radio.Group>
                  <Radio.Button
                    className={cn(
                      styles.type,
                      currentType === GalleryType.Slider && styles.selected,
                    )}
                    value={GalleryType.Slider}
                  >
                    Слайдер
                  </Radio.Button>
                  <Radio.Button
                    className={cn(
                      styles.type,
                      currentType === GalleryType.Tiles && styles.selected,
                    )}
                    value={GalleryType.Tiles}
                  >
                    Плитка
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.List name="images">
            {(fields, { add, remove }) => (
              <Space direction="vertical">
                {/* Дропзона */}
                <Form.Item
                  name={[currentImageIndex, 'fileId']}
                  getValueFromEvent={(file?: FileSecure) => {
                    if (isDefined(file)) {
                      setLocalFiles((prevFiles) => [...prevFiles, file]);
                      return file.id;
                    }
                    return null;
                  }}
                  getValueProps={() => ({
                    value: getFileById(currentImages[currentImageIndex]?.fileId, files),
                  })}
                >
                  <DropZone
                    type={DropType.Image}
                    imageOptions={{
                      height: isMobile ? 177 : 240,
                      resize: ImageProxyResize.Fit,
                      width: isMobile ? 315 : 420,
                    }}
                  />
                </Form.Item>
                {/* Кнопки */}
                <Row gutter={[12, 12]} justify="center" align="top">
                  <Col xs={{ order: 1 }} md={{ order: 1 }}>
                    <Row gutter={12} justify="center" align="top">
                      <Col>
                        <Button
                          icon={<DeleteOutlined />}
                          size="small"
                          disabled={isTheOnly}
                          onClick={() => {
                            remove(currentImageIndex);
                            if (isLast) {
                              setCurrentImageIndex(dec);
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          icon={<LeftOutlined />}
                          size="small"
                          disabled={isFirst || isTheOnly}
                          onClick={() => {
                            setCurrentImageIndex(dec);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Блок превью */}
                  <Col xs={{ order: 3, span: 24 }} md={{ order: 2, span: 18 }}>
                    <Space
                      size={12}
                      direction="vertical"
                      className={cn(styles.width420, isMobile && styles.width315)}
                    >
                      <Space size={12} wrap>
                        {fields.map(({ name }) => {
                          const isSelected = name === currentImageIndex;
                          const file = getFileById(currentImages[name]?.fileId, files);
                          return (
                            <Image
                              key={name}
                              className={cn(isSelected && styles.selected, styles.preview)}
                              width={60}
                              height={34}
                              src={
                                file
                                  ? file.url
                                  : // Прозрачный пиксель
                                    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                              }
                              preview={false}
                              onClick={() => {
                                setCurrentImageIndex(name);
                              }}
                            />
                          );
                        })}
                      </Space>
                      <Form.Item
                        name={[currentImageIndex, 'caption']}
                        className={cn(styles.inputRow)}
                      >
                        <TextArea
                          bordered={false}
                          placeholder="Подпись"
                          className={styles.input}
                          autoSize
                          rows={1}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col xs={{ order: 2 }} md={{ order: 3 }}>
                    <Row gutter={12} justify="center" align="top">
                      <Col>
                        <Button
                          icon={<RightOutlined />}
                          size="small"
                          disabled={isLast || isTheOnly}
                          onClick={() => {
                            setCurrentImageIndex(inc);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          icon={<PlusOutlined />}
                          size="small"
                          onClick={() => {
                            add({ caption: '', fileId: null }, currentImageIndex + 1);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Space>
            )}
          </Form.List>
        </Col>
      </Row>
    </FormBlock>
  );
};
