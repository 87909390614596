import { Http } from '@status/codes';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { always, cond, equals } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Logo } from '~assets';
import { RoutesPath } from '~constants';
import { isAuthenticatedSelector } from '~selectors';
import { useLoginUserMutation } from '~services';
import { isSuccessResult } from '~utils';

import styles from './styles.module.scss';

const layout = {
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const getErrorMessage = cond<[number], string>([
  [equals(Http.Unauthorized), always('Неверный логин или пароль!')],
]);

const AuthPage: FC = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginUserMutation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isAuthenticated) navigate(RoutesPath.Home);
  }, [navigate, isAuthenticated]);

  const onFinish = async (values: any) => {
    setErrorMessage(null);

    const result = await login(values);

    if (!isSuccessResult(result) && result.error) {
      const { data, status } = result.error as any;

      setErrorMessage(getErrorMessage(status) || data?.message);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form
        {...layout}
        layout="vertical"
        name="auth"
        validateTrigger="onSubmit"
        onFinish={onFinish}
        className={styles.form}
      >
        <Row gutter={[0, 25]} justify="center">
          <Col flex="auto">
            <Logo className={styles.logo} />
          </Col>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label="Логин"
                  rules={[
                    {
                      message: 'Введите логин',
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Логин" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Пароль"
                  rules={[
                    {
                      message: 'Введите пароль',
                      required: true,
                    },
                  ]}
                  style={{ width: '100%' }}
                >
                  <Input.Password size="large" placeholder="Пароль" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item {...tailLayout} style={{ width: '100%' }}>
              <Button type="primary" htmlType="submit" size="large" block loading={isLoading}>
                Войти
              </Button>
            </Form.Item>
          </Col>
          <Col span={24} className={styles.error}>
            <Typography.Text type="danger" strong>
              {errorMessage}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AuthPage;
