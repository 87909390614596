import { Button, Card, Col, Row, Typography } from 'antd';
import { GridSchemaSecure } from 'pn-backend';
import React from 'react';

import { Edit } from '~assets';
import { BadgeColor } from '~constants';
import { formatDate, getTypeTitle } from '~utils';

import { Badge } from '../Badge';
import styles from './styles.module.scss';

interface Props {
  item: GridSchemaSecure;

  onEdit(section: GridSchemaSecure): void;
}

export const GridSchemaCard: React.FC<Props> = ({ item, onEdit }) => {
  const { createdAt, filterByPostTypes, id, layout } = item;

  const onClickEdit = () => {
    onEdit(item);
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Row className={styles.wrapper} align="middle">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Row gutter={[10, 0]}>
                <Col>
                  <Typography.Text className={styles.title}>{layout}</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button icon={<Edit />} type="text" onClick={onClickEdit} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Id:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>{id}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Дата создания:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>
                    {formatDate(createdAt, 'dd MMMM yyyy')}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[6, 6]}>
            {filterByPostTypes?.map((postType) => (
              <Col>
                <Badge color={BadgeColor.BlueLight} size="small">
                  {getTypeTitle(postType)}
                </Badge>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
