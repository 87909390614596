import { BlockType, ColumnsTextPosition } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';
import { getTextFromRawDraftContentState } from '~utils';

import { draftContentSchema } from '../draft';

export const blockContentColumnsSchema = z.object<ValidatesBlockContent<BlockType.Columns>>({
  content: draftContentSchema.refine(
    (content) => getTextFromRawDraftContentState(content).length > 1,
    { message: 'Блок "Колонки" - текст должен быть заполнен!' },
  ),
  images: z
    .array(
      z.object({
        caption: z.string().nullable(),
        fileId: z.number(),
      }),
    )
    .min(1, { message: 'Блок "Колонки" - должно быть не менее одного изображения!' }),
  textPosition: z.nativeEnum(ColumnsTextPosition),
  title: z.string(),
});
