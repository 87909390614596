import { createSlice } from '@reduxjs/toolkit';

import { tagsAdapter } from '~adapters';
import { tagsApi } from '~services';

export const tagsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(tagsApi.endpoints.getTagsList.matchPending, (state, action) => {
        if (action.meta.arg.originalArgs.offset === 0) {
          tagsAdapter.removeAll(state);
        }
      })
      .addMatcher(tagsApi.endpoints.getTagsList.matchFulfilled, (state, action) => {
        tagsAdapter.upsertMany(state, action.payload.results);
      });
  },
  initialState: tagsAdapter.getInitialState(),
  name: 'tags',
  reducers: {},
});

export const tagsReducer = tagsSlice.reducer;
