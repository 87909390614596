import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';
import * as z from 'zod';

import { Validates } from '~types';

export const rawDraftContentBlockSchema = z.object<Validates<RawDraftContentBlock>>({
  data: z.record(z.any()).optional(),
  depth: z.number(),
  entityRanges: z.array(
    z.object<Validates<RawDraftEntityRange>>({
      key: z.number(),
      length: z.number(),
      offset: z.number(),
    }),
  ),
  inlineStyleRanges: z.array(
    z.object<Validates<RawDraftInlineStyleRange>>({
      length: z.number(),
      offset: z.number(),
      // @ts-expect-error Используется кастомное форматирование HIGHLIGHT
      style: z.union([
        z.literal('BOLD'),
        z.literal('CODE'),
        z.literal('ITALIC'),
        z.literal('STRIKETHROUGH'),
        z.literal('UNDERLINE'),
        z.literal('HIGHLIGHT'),
      ]),
    }),
  ),
  key: z.string(),
  text: z.string(),
  type: z.string(),
});

export const draftContentSchema = z.object<Validates<RawDraftContentState>>({
  blocks: z.array(rawDraftContentBlockSchema),
  entityMap: z.record(
    z.object<Validates<RawDraftEntity>>({
      data: z.record(z.any()),
      mutability: z.union([z.literal('MUTABLE'), z.literal('IMMUTABLE'), z.literal('SEGMENTED')]),
      type: z.union([
        z.literal('LINK'),
        z.literal('TOKEN'),
        z.literal('PHOTO'),
        z.literal('IMAGE'),
        z.string(),
      ]),
    }),
  ),
});
