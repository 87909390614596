export const RoutesPath = {
  Authors: '/authors',
  Changes: '/posts/:id/changes',
  EmployeeEdit: '/employees/:id',
  Employees: '/employees',
  GridSchema: '/grid/:gridId/schema',
  GridSchemas: '/grid-schemas',
  Home: '/',
  Login: '/login',
  News: '/news',
  NotFound: '/404',
  PostEdit: '/posts/:id',
  Posts: '/posts',
  Sections: '/sections',
  Settings: '/settings',
  Tags: '/tags',
};

export const PublicRoutesPath = {
  NewsPreview: '/n/:postId',
  PostPreview: '/a/:postId',
};
