import { EntityId } from '@reduxjs/toolkit';
import { Button, Col, Row } from 'antd';
import { GridSchemaSecure } from 'pn-backend';
import { __, always, applySpec, cond, filter, includes, map, pipe, prop, propEq, T } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { useBeforeUnload, useParams } from 'react-router-dom';

import { TitlePage } from '~components';
import { GridSchemaBlocks, MobilePageHeader } from '~containers';
import { useIsMobile, useNotification } from '~hooks';
import { GridProvider } from '~providers';
import { changesGridSchemasEntitySelector, gridSchemasEntitySelector } from '~selectors';
import { useGetGridQuery, useGetSectionGridQuery, useUpdateGridSchemasMutation } from '~services';

import styles from './styles.module.scss';

const filterByChanges = (changes: EntityId[], items: GridSchemaSecure[]) =>
  pipe(
    filter(pipe(prop('id'), includes(__, changes))),
    map(
      applySpec({
        coverId: prop<number>('coverId'),
        id: prop<number>('id'),
        isHidden: prop<boolean>('isHidden'),
        postIds: pipe(prop('posts'), map(prop<number>('id'))),
        sortOrder: prop<number>('sortOrder'),
        title: prop<string>('title'),
      }),
    ),
  )(items);

const RightContent: React.FC = () => {
  const changes = useSelector(changesGridSchemasEntitySelector);

  const gridSchemas = useSelector(gridSchemasEntitySelector.selectAll);
  const [updateGridSchemas, { isLoading }] = useUpdateGridSchemasMutation();

  const hasChanges = changes.length > 0;

  const notification = useNotification();

  const publishHandler = async () => {
    const items = filterByChanges(changes, gridSchemas);

    await updateGridSchemas({
      items,
    });

    notification({
      message: 'Изменения опубликованы',
      placement: 'top',
    });
  };

  return (
    <Row gutter={[25, 0]}>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={!hasChanges}
          loading={isLoading}
          onClick={publishHandler}
        >
          Опубликовать
        </Button>
      </Col>
    </Row>
  );
};

const getTitleByAliasGrid = cond([
  [propEq('alias', 'main'), always('Управление главной')],
  [T, always('Управление разделом')],
]);

export const GridSchemaPage: React.FC = () => {
  const { gridId } = useParams();
  const isMobile = useIsMobile();
  const { data: grid } = useGetGridQuery(Number(gridId));
  useGetSectionGridQuery(Number(gridId), { refetchOnMountOrArgChange: true });

  const changes = useSelector(changesGridSchemasEntitySelector);

  useBeforeUnload((event) => {
    if (changes.length > 0) {
      event.preventDefault();
      event.returnValue = true;
    }
  });

  return (
    <GridProvider grid={grid}>
      <Row gutter={[0, 32]}>
        <Col span={24} className={styles.mobilePageHeader}>
          <MobilePageHeader title={getTitleByAliasGrid(grid)} withSearch={false} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <TitlePage titleVisible={!isMobile} title={getTitleByAliasGrid(grid)} />
            </Col>
            <Col>
              <RightContent />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col span={24} style={{ maxWidth: 680 }}>
              <GridSchemaBlocks />
            </Col>
          </Row>
        </Col>
      </Row>
    </GridProvider>
  );
};
