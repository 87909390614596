import { skipToken } from '@reduxjs/toolkit/query';
import { Button, Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import {
  CreateAuthorBodySecure,
  EmployeeExtSecure,
  ImageProxyResize,
  UpdateAuthorBodySecure,
} from 'pn-backend';
import React, { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '~components';
import { DropType } from '~constants';
import { ConfigContext } from '~providers';
import { authorByIdSelector } from '~selectors';
import { useCreateAuthorMutation, useGetAuthorByIdQuery, useUpdateAuthorMutation } from '~services';

import { DropZone } from '../DropZone';
import styles from './styles.module.scss';

interface Props {
  employeeId?: number;
  id?: number;
  isOpen?: boolean;

  onClose?(employee?: EmployeeExtSecure): void;
}

export const AuthorModal: FC<Props> = ({ employeeId, id, isOpen = false, onClose }) => {
  const [createAuthor] = useCreateAuthorMutation();
  const [updateAuthor] = useUpdateAuthorMutation();

  const { imageProxyHost } = useContext(ConfigContext);

  const modalTitle = id ? 'Редактирование автора' : 'Добавление нового автора';
  const submitButtonText = id ? 'Применить' : 'Добавить';
  const action = id
    ? (data: Partial<UpdateAuthorBodySecure>) => updateAuthor({ ...data, id })
    : (data: CreateAuthorBodySecure) => createAuthor(data);

  useGetAuthorByIdQuery(id ?? skipToken);
  const author = useSelector(authorByIdSelector(id));

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const onFinish = async (values: any) => {
    const actionParams = {
      avatarId: values?.avatar?.id,
      employeeId: author?.employeeId || employeeId,
      name: values.name,
      status: 'active',
    };

    // @ts-ignore
    const { data: newEmployee } = await action(actionParams);

    if (onClose) {
      onClose(newEmployee);
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...author,
    };
  }, [author]);

  return (
    <Modal
      open={isOpen}
      title={modalTitle}
      destroyOnClose
      onCancel={onCancel}
      width={613}
      footer={null}
    >
      <Form
        layout="vertical"
        name="author"
        validateTrigger="submit"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={[0, 15]}>
          <Col md={12} xs={24}>
            <Form.Item name="avatar">
              <DropZone
                type={DropType.Image}
                imageOptions={{
                  domain: imageProxyHost,
                  height: 250,
                  resize: ImageProxyResize.Fill,
                  width: 250,
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Row gutter={[15, 15]}>
              <Col span={24}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Form.Item name="name" label={<label className={styles.label}>Имя</label>}>
                  <Input size="large" placeholder="Имя" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col md={{ order: 1, span: 12 }} xs={{ order: 2, span: 24 }}>
                <Row justify={{ md: 'end', xs: 'center' }}>
                  <Button
                    type="text"
                    size="large"
                    className={cn(styles.button, styles.decline)}
                    onClick={onCancel}
                  >
                    Отменить
                  </Button>
                </Row>
              </Col>
              <Col md={{ order: 2, span: 12 }} xs={{ order: 1, span: 24 }}>
                <Row justify={{ md: 'start', xs: 'center' }}>
                  <Button type="primary" htmlType="submit" size="large" className={styles.button}>
                    {submitButtonText}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
