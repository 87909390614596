import { Col, Form, Radio, Row } from 'antd';
import cn from 'classnames';
import { BlockType, LineType } from 'pn-backend';
import { always, cond, equals } from 'ramda';
import React, { FC, useMemo } from 'react';

import { Dots, Line, Squares } from '~assets';
import { BlockProps } from '~types';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const lines: LineType[] = [LineType.Solid, LineType.Squares, LineType.Dots];

const getIconLine = cond([
  [equals(LineType.Dots), always(<Dots />)],
  [equals(LineType.Squares), always(<Squares className={styles.squares} />)],
  [equals(LineType.Solid), always(<Line className={styles.line} />)],
]);

export const LineBlock: FC<BlockProps<BlockType.Line>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content } = block;

  const { type } = content;

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Разделитель</div>
        </Col>
        <Col span={24}>
          <Form.Item name="type">
            <Row gutter={[25, 25]} justify="start">
              {lines.map((lineType) => (
                <Col className={cn(styles.buttonWrapper, type === lineType && styles.selected)}>
                  <Radio.Group>
                    <Radio.Button key={lineType} value={lineType}>
                      {getIconLine(lineType)}
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              ))}
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};
