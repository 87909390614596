import { BlockContentMoreOnTopicItem, BlockType, MoreOnTopicType } from 'pn-backend';
import { z } from 'zod';

import { Validates, ValidatesBlockContent } from '~types';
import { isDefined } from '~utils';

export const blockContentMoreOnTopicSchema = z
  .object<ValidatesBlockContent<BlockType.MoreOnTopic>>({
    fileId: z
      .number({
        invalid_type_error: 'Блок "Еще по теме" - обязательно должно быть загружено изображение!',
      })
      .optional()
      .nullable(),
    items: z.array(
      z.object<Validates<BlockContentMoreOnTopicItem>>({
        caption: z
          .string()
          .min(1, { message: 'Блок "Еще по теме" - заголовок должен быть заполнен!' }),
        link: z.string().url({ message: 'Блок "Еще по теме" - невалидная ссылка!' }),
      }),
    ),
    type: z.nativeEnum(MoreOnTopicType),
  })
  .superRefine(({ fileId, items, type }, ctx) => {
    if (type === MoreOnTopicType.Image && !isDefined(fileId)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Еще по теме" - обязательно должно быть загружено изображение!',
      });
    } else if (type === MoreOnTopicType.Text && items.length < 3) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Еще по теме" - должны быть заполнены все поля!',
      });
    }
  });
