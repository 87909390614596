import { Button, Col, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { always, gt, pipe } from 'ramda';
import React, { ReactNode } from 'react';

import { Arrow, Delete } from '~assets';

import styles from './styles.module.scss';

interface Props {
  bordered?: boolean;
  canDelete?: boolean;
  canSort?: boolean;
  canSortUp?: boolean;
  children?: any;
  count?: number;
  error?: boolean;
  id?: string;
  maxCount?: number;
  message?: ReactNode;
  onDelete(): void;
  onSort(direction: 'up' | 'down'): void;
}

export const BlockWrapper: React.FC<Props> = (props) => {
  const {
    bordered = false,
    canDelete = true,
    canSort = true,
    canSortUp = false,
    children,
    count = 0,
    error,
    id,
    maxCount,
    message,
    onDelete,
    onSort,
  } = props;

  return (
    <div className={styles.row} id={id}>
      <Row
        className={classNames(
          styles.wrapper,
          canDelete && styles.withDelete,
          bordered && styles.bordered,
          error && styles.errorValidate,
        )}
      >
        <Col span={24}>{children}</Col>
        {canDelete && <Delete className={styles.delete} onClick={onDelete} />}
        {maxCount && (
          <Typography.Text
            className={classNames(styles.count, gt(count, maxCount) && styles.warning)}
          >
            {count}/{maxCount}
          </Typography.Text>
        )}
        {message && <div className={styles.message}>{message}</div>}
        {canSort && (
          <Col className={styles.sort}>
            <Space size={5}>
              <Button
                className={classNames(styles.buttonOrder, styles.buttonUp)}
                icon={<Arrow />}
                disabled={!canSortUp}
                onClick={pipe(always('up'), onSort)}
              />
              <Button
                className={styles.buttonOrder}
                icon={<Arrow />}
                onClick={pipe(always('down'), onSort)}
              />
            </Space>
          </Col>
        )}
      </Row>
    </div>
  );
};
