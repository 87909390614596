import { Col, Row, Typography } from 'antd';
import { GridSchemaSecure } from 'pn-backend';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Badge, Cover } from '~components';
import { BadgeColor } from '~constants';
import { GridContext } from '~providers';
import { updateManyGridSchemas } from '~slices';
import { deleteMarkup, isDefined } from '~utils';

import { searchPostsModal } from '../../../SearchPostModal';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

export const MainPostOfTheWeek: React.FC<GridSchemaSecure> = (props) => {
  const dispatch = useDispatch();
  const { grid } = useContext(GridContext);
  const { posts = [], maxPostCount, filterByPostTypes, filterBySectionId, id } = props;
  const [post] = posts;
  const { preview, title, tags = [] } = post || {};
  const [tag] = tags;

  const onSelectPost = async () => {
    const newPosts = await searchPostsModal({
      maxPostCount,
      sections: isDefined(grid) ? [filterBySectionId, grid.id] : [filterBySectionId],
      types: filterByPostTypes,
    });

    const changes = { posts: newPosts };

    dispatch(updateManyGridSchemas([{ changes, id }]));
  };

  return (
    <BlockWrapper {...props} canChangeTitle>
      {!post && (
        <Row className={styles.selectPost} onClick={onSelectPost} justify="center" align="middle">
          <Col span={6} className={styles.text}>
            <Typography.Text>Выбрать публикацию</Typography.Text>
          </Col>
        </Row>
      )}
      {post && (
        <Row className={styles.post} gutter={[0, 15]}>
          <Col span={12}>
            <Row className={styles.content}>
              <Col span={24}>
                <Typography.Text className={styles.title}>
                  {deleteMarkup(title?.text)}
                </Typography.Text>
              </Col>
              {/* <Col span={24}>
                <Typography.Text className={styles.subTitle}>
                  TODO исправить тип
                  @ts-ignore
                  {h2Block?.content?.text}
                </Typography.Text>
              </Col> */}
            </Row>
          </Col>
          <Col span={12} className={styles.imageWrapper}>
            <Cover src={preview?.imageURLs?.medium} height={194} width="100%" objectFit="cover" />
            {tag && (
              <Badge className={styles.tag} color={BadgeColor.GreyDark}>
                {tag?.name}
              </Badge>
            )}
          </Col>
        </Row>
      )}
    </BlockWrapper>
  );
};
