import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { EmployeeExtSecure } from 'pn-backend';
import { prop } from 'ramda';

import { stateSelector } from './state';

export const userStateSelector = createDraftSafeSelector(
  stateSelector,
  prop<EmployeeExtSecure>('user'),
);

export const userSelector = createDraftSafeSelector(
  userStateSelector,
  prop<EmployeeExtSecure>('user'),
);

export const isAuthenticatedSelector = createDraftSafeSelector(
  userStateSelector,
  prop<boolean>('isAuthenticated'),
);
