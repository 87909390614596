import { List, Modal, Space } from 'antd';
import { FC } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

type Props = InstanceProps<true | false> & {
  messages: string[];
};

const Container: FC<Props> = ({ isOpen, messages, onResolve }) => {
  const handleCancel = () => {
    onResolve(false);
  };

  const handleOk = () => {
    onResolve(true);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      title="Вы точно хотите опубликовать материал?"
      okText="Да"
      cancelText="Нет"
    >
      <Space direction="vertical">
        <List dataSource={messages} renderItem={(item) => <List.Item>{item}</List.Item>} />
      </Space>
    </Modal>
  );
};

export const confirmPostsModal = create(Container);
