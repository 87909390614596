import { createSlice } from '@reduxjs/toolkit';

import { postsAdapter } from '~adapters';
import { postsApi } from '~services';

interface State {
  count: number;
  lastRequestId?: string;
}

export const postsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(postsApi.endpoints.getListPosts.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0 && action.meta.arg.subscribe) {
          postsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(postsApi.endpoints.getListPosts.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          postsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(postsApi.endpoints.updatePost.matchFulfilled, (state, action) => {
        postsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.meta.arg.originalArgs.id,
        });
      });
  },
  initialState: postsAdapter.getInitialState<State>({
    count: 0,
  }),
  name: 'posts',
  reducers: {},
});

export const postsReducer = postsSlice.reducer;
