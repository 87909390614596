import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentMetaSchema = z.object<ValidatesBlockContent<BlockType.Meta>>({
  canonical: z.union([
    z.string().url('Блок "Meta": Canonical должен быть валидным url'),
    z.literal(''),
  ]),
});
