import { Form } from 'antd';
import { BlockType, PostType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { RichEditor } from '~components';
import { usePost } from '~hooks';
import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';

const getBlockTitle = cond([
  [equals(BlockType.H2), always('Заголовок H2')],
  [equals(BlockType.H3), always('Заголовок H3')],
  [T, always(undefined)],
]);

const getBlockPlaceholder = cond([
  [equals(BlockType.H2), always('Напишите заголовок')],
  [equals(BlockType.H3), always('Напишите заголовок')],
  [T, always(undefined)],
]);

const getBlockIsInline = cond([
  [equals(BlockType.H2), always(false)],
  [equals(BlockType.H3), always(false)],
  [T, always(true)],
]);

const getBlockMaxCount = cond([
  [equals(BlockType.H2), always(100)],
  [equals(BlockType.H3), always(100)],
  [T, always(undefined)],
]);

const getBlockFontSize = cond([
  [equals(BlockType.H2), always(20)],
  [equals(BlockType.H3), always(20)],
  [T, always(undefined)],
]);

const getBlockLineHeightCommon = cond([
  [equals(BlockType.H2), always(28)],
  [equals(BlockType.H3), always(28)],
  [T, always(undefined)],
]);

const getBlockLineHeightCard = cond([
  [equals(BlockType.H2), always(24)],
  [equals(BlockType.H3), always(24)],
  [T, always(undefined)],
]);

const getBlockLineHeightByPostType = cond([
  [equals(PostType.Card), always(getBlockLineHeightCard)],
  [T, always(getBlockLineHeightCommon)],
]);

export const HTitleBlock: FC<BlockProps<BlockType.H2 | BlockType.H3>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content: blockContent, type } = block;
  const { type: postType } = usePost();

  const { content } = blockContent;
  const [count, setCount] = useState(getText(content).length);

  const onValuesChange = useCallback(
    ({
      content: changedContent,
    }: Pick<BlockProps<BlockType.H2 | BlockType.H3>['block'], 'content'>) => {
      setCount(getText(changedContent).length);
    },
    [],
  );

  const initialValues = useMemo(
    () => ({
      ...blockContent,
    }),
    [blockContent],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={getBlockMaxCount(type)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Form.Item name="content">
        <RichEditor
          withColor={false}
          fontSize={getBlockFontSize(type)}
          inlineStylesToRetain={[]}
          lineHeight={getBlockLineHeightByPostType(postType)(type)}
          defaultData={content}
          placeholder={getBlockPlaceholder(type)}
          title={getBlockTitle(type)}
          inline={getBlockIsInline(type)}
        />
      </Form.Item>
    </FormBlock>
  );
};
