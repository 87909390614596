import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { BlockType, CoverFormat, ImageProxyResize, PostType } from 'pn-backend';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC, useMemo } from 'react';

import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { Option } = Select;

export const CoverBlock: FC<BlockProps<BlockType.Cover>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const { type } = usePost();
  const [form] = Form.useForm();
  const { content, files } = block;

  const isMobile = useIsMobile();

  const formatValues: FormBlockProps<BlockType.Cover>['formatValues'] = (changedContent) => {
    return {
      content: { ...content, ...changedContent },
      fileIds: [changedContent.fileId].filter(isNotNil),
    };
  };

  const getLongreadOptionTitle = cond([
    [equals(CoverFormat.CaptionAbove), always('Заголовок над обложкой')],
    [equals(CoverFormat.CaptionUnder), always('Заголовок под обложкой')],
    [equals(CoverFormat.FullscreenCaptionOver), always('Fullscreen + заголовок поверх')],
    [equals(CoverFormat.FullscreenCaptionUnder), always('Fullscreen + заголовок под обложкой')],
    [equals(CoverFormat.FullscreenCaptionRight), always('Fullscreen + заголовок справа')],
    [T, always(null)],
  ]);

  const getCommonOptionTitle = cond([
    [equals(CoverFormat.CaptionAbove), always('Заголовок над обложкой')],
    [equals(CoverFormat.CaptionUnder), always('Заголовок под обложкой')],
    [T, always(null)],
  ]);

  const getOptionTitle = cond([
    [equals(PostType.Longread), always(getLongreadOptionTitle)],
    [T, always(getCommonOptionTitle)],
  ]);

  const getHideOptionTitle = cond([
    [equals(PostType.Card), always(true)],
    [equals(PostType.Test), always(true)],
    [equals(PostType.Spec), always(true)],
    [T, always(false)],
  ]);

  const getHideHideInPost = cond([
    [equals(PostType.Spec), always(true)],
    [T, always(false)],
  ]);

  const getHideCaption = cond([
    [equals(PostType.Spec), always(true)],
    [T, always(false)],
  ]);

  const getOptionInitialValue = cond([
    [equals(PostType.Card), always(CoverFormat.CaptionUnder)],
    [equals(PostType.Test), always(CoverFormat.CaptionAbove)],
    [T, always(CoverFormat.CaptionUnder)],
  ]);

  const initialValues = useMemo(() => {
    const values = { ...content };

    if (type === PostType.Card || type === PostType.Test) {
      values.format = getOptionInitialValue(type);
    }

    return {
      ...values,
    };
  }, []);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col xs={12}>Обложка</Col>
            <Col xs={12}>
              <Row justify="end">
                <Form.Item
                  name="hideInPost"
                  noStyle
                  valuePropName="checked"
                  hidden={getHideHideInPost(type)}
                >
                  <Checkbox>
                    {isMobile ? 'Не отображать' : 'Не отображать на странице материала'}
                  </Checkbox>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              type={DropType.Image}
              imageOptions={{
                height: isMobile ? 177 : 240,
                resize: ImageProxyResize.Fill,
                width: isMobile ? 315 : 420,
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Row gutter={[25, 25]}>
            <Col span={24}>
              <Form.Item
                name="format"
                className={styles.inputRow}
                hidden={getHideOptionTitle(type)}
              >
                <Select size="large" bordered={false} className={styles.input}>
                  {(Object.keys(CoverFormat) as (keyof typeof CoverFormat)[]).map((key) => {
                    const title = getOptionTitle(type)(CoverFormat[key]);
                    return title ? (
                      <Option
                        key={CoverFormat[key]}
                        value={CoverFormat[key]}
                        className={styles.input}
                      >
                        {title}
                      </Option>
                    ) : null;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="caption" className={styles.inputRow} hidden={getHideCaption(type)}>
                <Input bordered={false} className={styles.input} placeholder="Подпись" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
