import { EditorState, RichUtils } from 'draft-js';

import { RichTextBlock } from '../types';

export const getLinkString = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();

  const startKey = editorState.getSelection().getStartKey();

  const start = editorState.getSelection().getStartOffset();
  const end = editorState.getSelection().getEndOffset();

  let lastUrl = '';
  const allUrls: string[] = [];

  editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .findEntityRanges(
      (data) => {
        const entity = data.getEntity();

        if (entity) {
          // @ts-ignore
          lastUrl = contentState.getEntity(entity).data.url;
          return true;
        }
        return false;
      },
      (s, e) => {
        if (!(e <= start || s >= end)) {
          allUrls.push(lastUrl);
        }
      },
    );

  if (allUrls.length > 0) return allUrls[0];

  return '';
};

export const getLink = (editorState: EditorState, enteredUrl: string): EditorState => {
  const contentState = editorState.getCurrentContent();

  const urlValue = enteredUrl ?? null;

  if (urlValue) {
    const contentStateWithEntity = contentState.createEntity(RichTextBlock.link, 'SEGMENTED', {
      url: urlValue,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
  }
  return editorState;
};
