import { Button, Col, Input, Row, Space, Spin } from 'antd';
import classNames from 'classnames';
import { PostPreviewSecure, PostStatus, PostType } from 'pn-backend';
import { equals, includes, isNil, not, pipe, prop, reject } from 'ramda';
import React, { ChangeEventHandler, useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useDebounce } from 'use-debounce';

import { Search } from '~assets';
import { Badge, Modal, Sort, SortField, SortValue } from '~components';
import { SortDirections } from '~constants';
import { useIsMobile } from '~hooks';
import { useGetListPostsQuery } from '~services';
import { getTypeTitle, sortToString } from '~utils';

import { PostCard } from '../PostCard';
import style from './styles.module.scss';

type Props = InstanceProps<PostPreviewSecure<PostType>[], false> & {
  maxPostCount: number;
  sections?: number[];
  types?: PostType[];
};

const sortFields: SortField[] = [{ label: 'По дате публикации', value: 'publishedAt' }];

const Container: React.FC<Props> = (props) => {
  const { isOpen, maxPostCount, onReject, onResolve, sections = [], types = [] } = props;
  const [selected, setSelected] = useState<PostPreviewSecure<PostType>[]>([]);
  const [term, setTerm] = useState<string>();
  const [sort, setSort] = useState<SortValue>({
    createdAt: SortDirections.Desc,
  });
  const [selectedTypes, setSelectedTypes] = useState(types);

  const isMobile = useIsMobile();

  const filterBySectionId = reject(isNil, sections);

  const [debouncedTerm] = useDebounce(term, 1000);

  const { data, isLoading } = useGetListPostsQuery({
    order: sortToString(sort),
    sections: filterBySectionId.length !== 0 ? filterBySectionId : undefined,
    status: PostStatus.Published,
    term: debouncedTerm,
    type: selectedTypes,
  });

  const onCancel = () => {
    onReject(false);
  };

  const onTermChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event?.currentTarget.value);
  };

  const onPostClick = (post: PostPreviewSecure<PostType>) => {
    if (includes(post.id, selected.map(prop('id')))) {
      setSelected(selected.filter(pipe(prop('id'), equals(post.id))));
    } else if (selected.length < maxPostCount) {
      setSelected([...selected, post]);
    }
  };

  const onAdd = () => {
    onResolve(selected);
  };

  const handleClickType = (type: PostType) => () => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter(pipe(equals(type), not)));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const isSelected = (post: PostPreviewSecure<PostType>) =>
    includes(post.id, selected.map(prop('id')));

  const canSelectMore = selected.length < maxPostCount;

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      title="Поиск публикаций"
      width={1120}
      okButtonProps={{ disabled: selected.length === 0 }}
      onOk={onAdd}
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={[20, 0]} align="middle">
            <Col span={18}>
              <Input
                placeholder="поиск публикаций по названию"
                className={style.input}
                prefix={<Search />}
                value={term}
                onChange={onTermChange}
              />
            </Col>
            <Col span={6}>
              <Button
                onClick={onAdd}
                disabled={selected.length === 0}
                block
                size="large"
                type="primary"
                className={style.addButton}
              >
                Добавить
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col flex="auto">
              {types?.length > 0 && (
                <Space wrap={isMobile}>
                  {types.map((type) => (
                    <Badge
                      type="chip"
                      selected={selectedTypes.includes(type)}
                      onClick={handleClickType(type)}
                    >
                      {getTypeTitle(type)}
                    </Badge>
                  ))}
                </Space>
              )}
            </Col>
            <Col>
              <Sort fields={sortFields} value={sort} onChange={setSort} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]} justify="center">
            {isLoading && (
              <Col span={24}>
                <Spin />
              </Col>
            )}
            {data?.results?.map((post) => (
              <Col md={8} xs={24}>
                <PostCard
                  post={post}
                  shortInfo
                  onClick={onPostClick}
                  className={classNames(
                    style.post,
                    isSelected(post) && style.selected,
                    !isSelected(post) && !canSelectMore && style.disabled,
                  )}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export const searchPostsModal = create(Container);
