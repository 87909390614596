import { Form, Result } from 'antd';
import { FunctionComponent } from 'react';

import { BlockProps } from '~types';

import { FormBlock } from '../Wrappers';

export const EmptyBlock: FunctionComponent<BlockProps> = (props) => {
  const [form] = Form.useForm();
  const { block } = props;
  return (
    <FormBlock form={form} {...props}>
      <Result status="warning" title="Этот блок еще не настроен" subTitle={block.type} />
    </FormBlock>
  );
};
