import { Http } from '@status/codes';
import { Col, Row, Typography } from 'antd';
import { always, cond, equals, gte, T } from 'ramda';
import { Link } from 'react-router-dom';

import { RoutesPath } from '~constants';

import styles from './styles.module.scss';

interface Props {
  status: number;
}

const getTitle = cond([
  [equals(Http.NotFound), always('страница не найдена')],
  [gte(Http.InternalServerError), always('серверная ошибка')],
  [T, always('ошибка')],
]);

export const Error: React.FunctionComponent<Props> = ({ status }) => (
  <Row className={styles.wrapper} gutter={[0, 20]} align="middle">
    <Col span={24}>
      <Typography.Title className={styles.status}>{status}</Typography.Title>
    </Col>
    <Col span={24}>
      <Row gutter={[0, 25]}>
        <Col span={24}>
          <Typography.Text className={styles.title}>{getTitle(status)}</Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text className={styles.description}>
            но можно вернуться ко <Link to={RoutesPath.Posts}>всем публикациям</Link>
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  </Row>
);
