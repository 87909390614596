import { Col, Row, Typography } from 'antd';
import { GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import { always, inc, times } from 'ramda';
import React from 'react';

import { Badge, Cover } from '~components';
import { BadgeColor } from '~constants';
import { deleteMarkup, getPublishedTime, isDefined } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

const Post: React.FC<Partial<PostPreviewSecure<PostType>>> = (props) => {
  const { preview, publishedAt, tags, title } = props;
  const { imageURLs } = preview || {};

  const [tag] = tags || [];

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Row>
          <Col span={24} className={styles.imageWrapper}>
            <Cover src={imageURLs?.medium} width="100%" />
            {tag && (
              <Badge className={styles.tag} color={BadgeColor.GreyDark}>
                {tag.name}
              </Badge>
            )}
          </Col>
        </Row>
      </Col>
      {title && (
        <Col span={24} className={styles.content}>
          <Row gutter={[0, 15]}>
            <Col span={24}>
              <Typography.Text className={styles.title}>
                {deleteMarkup(title?.text)}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text className={styles.publishTime}>
                {isDefined(publishedAt) ? getPublishedTime(publishedAt) : '-'}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export const TwoStandard: React.FC<GridSchemaSecure> = (props) => {
  const { posts = [], maxPostCount } = props;

  const onChange =
    (index: number) =>
    ({ posts: [newPost] = [] }: Partial<GridSchemaSecure>) => {
      // TODO Никите переписать на рамду красивее
      const newPosts = [...posts];
      newPosts[index] = newPost;
      return { posts: newPosts };
    };

  return (
    <Row gutter={[20, 20]}>
      {[...posts, ...times(always(undefined), maxPostCount - posts.length)]
        .slice(0, 2)
        .map((post, index) => (
          <Col span={12} key={post?.id}>
            <BlockWrapper
              {...props}
              onChange={onChange(index)}
              maxPostCount={1}
              title={`Публикация ${inc(index)}`}
            >
              <Post {...(post || {})} />
            </BlockWrapper>
          </Col>
        ))}
    </Row>
  );
};
