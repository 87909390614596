import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { BlockType, ColumnsTextPosition, ImageProxyResize } from 'pn-backend';
import { prop } from 'ramda';
import React, { FC, useCallback } from 'react';

import { Delete, Swap } from '~assets';
import { RichEditor } from '~components';
import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const ColumnsBlock: FC<BlockProps<BlockType.Columns>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content, files } = block;

  const { setFieldValue } = form;

  const formatValues: FormBlockProps<BlockType.Columns>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: changedContent.images.map(prop('fileId')).filter(isNotNil),
  });

  const textPosition = Form.useWatch('textPosition', form);

  const onSwapClick = useCallback(() => {
    switch (textPosition) {
      case ColumnsTextPosition.Right:
        setFieldValue('textPosition', ColumnsTextPosition.Left);
        break;
      case ColumnsTextPosition.Left:
        setFieldValue('textPosition', ColumnsTextPosition.Right);
        break;
      default:
        break;
    }
  }, [textPosition]);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Колонки</div>
        </Col>
        <Col span={24}>
          <Row>
            <Col md={8} xs={24} order={textPosition === ColumnsTextPosition.Left ? 3 : 1}>
              <Form.List name="images">
                {(fields, { add, remove }) => (
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <Row gutter={[20, 20]}>
                        {fields.map((item, index, ...rest) => {
                          return (
                            <Col span={24}>
                              <Row gutter={[10, 10]} justify="start">
                                <Col span={24}>
                                  <Form.Item
                                    name={[index, 'fileId']}
                                    getValueFromEvent={prop('id')}
                                    getValueProps={() => ({
                                      value: getFileById(content.images[index]?.fileId, files),
                                    })}
                                  >
                                    <DropZone
                                      type={DropType.Image}
                                      imageOptions={{
                                        height: fields.length === 1 ? 342 : 166,
                                        resize: ImageProxyResize.Fill,
                                        width: fields.length === 1 ? 295 : 295,
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Row justify="space-between">
                                    <Col>
                                      <Form.Item {...rest} name={[index, 'caption']} key={item.key}>
                                        <TextArea
                                          bordered={false}
                                          placeholder="Подпись"
                                          className={styles.input}
                                          autoSize
                                          rows={1}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Button
                                        icon={
                                          <Delete
                                            onClick={() => {
                                              remove(index);
                                            }}
                                          />
                                        }
                                        type="text"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    {fields.length < 2 && (
                      <Col span={24}>
                        <Button
                          onClick={() => add({ caption: null, fileId: null })}
                          type="text"
                          className={styles.addButtonWrapper}
                        >
                          <Space>
                            <PlusOutlined className={styles.addButton} />
                            Добавить изображение
                          </Space>
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
              </Form.List>
            </Col>
            <Col order={2} md={2} xs={{ flex: '100%', span: 24 }}>
              <Form.Item name="textPosition" hidden>
                <Input />
              </Form.Item>
              <Button
                className={styles.swapButton}
                icon={<Swap onClick={onSwapClick} />}
                type="text"
              />
            </Col>
            <Col
              flex="auto"
              xs={24}
              md={14}
              order={textPosition === ColumnsTextPosition.Left ? 1 : 3}
            >
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Row gutter={[25, 25]}>
                    <Col span={24}>
                      <Form.Item name="title" className={styles.inputRow}>
                        <TextArea
                          bordered={false}
                          placeholder="Заголовок"
                          className={styles.input}
                          autoSize
                          rows={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="content">
                    <RichEditor
                      withColor={false}
                      defaultData={content.content}
                      fontSize={20}
                      lineHeight={24}
                      placeholder="Текст"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
