import { Col, Form, Row } from 'antd';
import { BlockType, ImageProxyGravity, ImageProxyResize, PreviewImageFormat } from 'pn-backend';
import { prop } from 'ramda';
import React, { FC, useMemo } from 'react';

import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const PreviewBlock: FC<BlockProps<BlockType.Preview>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content, files } = block;

  const formatValues: FormBlockProps<BlockType.Preview>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId].filter(isNotNil),
  });

  const initialValues = useMemo(() => {
    return {
      ...content,
      images: {
        [PreviewImageFormat.Wide]: {
          gravity: ImageProxyGravity.CE,
          height: 197,
          resize: ImageProxyResize.Fill,
          width: 600,
        },
        [PreviewImageFormat.Big]: {
          gravity: ImageProxyGravity.CE,
          height: 215,
          resize: ImageProxyResize.Fill,
          width: 380,
        },
        [PreviewImageFormat.Medium]: {
          gravity: ImageProxyGravity.CE,
          height: 197,
          resize: ImageProxyResize.Fill,
          width: 295,
        },
        [PreviewImageFormat.Small]: {
          gravity: ImageProxyGravity.CE,
          height: 168,
          resize: ImageProxyResize.Fill,
          width: 210,
        },
        [PreviewImageFormat.Mobile]: {
          gravity: ImageProxyGravity.CE,
          height: 190,
          resize: ImageProxyResize.Fill,
          width: 188,
        },
      },
    };
  }, [content]);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Превью</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              type={DropType.Image}
              imageOptions={{ height: 200, resize: ImageProxyResize.Fill, width: 300 }}
            />
          </Form.Item>
          <Form.Item name="images" hidden />
        </Col>
      </Row>
    </FormBlock>
  );
};
