import { Col, Modal as AntdModal, ModalProps, Row } from 'antd';
import React from 'react';

import style from './styles.module.scss';

interface Props extends ModalProps {
  title?: React.ReactNode;
}

export const Modal: React.FC<Props> = (props) => {
  const { children, title, ...modalProps } = props;

  return (
    <AntdModal {...modalProps}>
      <div className={style.wrapper}>
        <Row justify="center">
          <Col span={24}>
            <p className={style.title}>{title}</p>
          </Col>
        </Row>
        {children}
      </div>
    </AntdModal>
  );
};
