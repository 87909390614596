import React, { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isAuthenticatedSelector } from '~selectors';

import { AppLayout } from '../AppLayout';

type AuthGuardProps = PropsWithChildren & {
  isPublic?: boolean;
};

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { children, isPublic } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPublic && !isAuthenticated) {
      navigate('/login');
    }
  }, [isPublic, isAuthenticated, navigate]);

  if (isPublic) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <AppLayout>{children}</AppLayout>;
};
