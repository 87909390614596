import {
  BlockContentPreviewItem,
  BlockType,
  ImageProxyGravity,
  ImageProxyResize,
  PreviewImageFormat,
} from 'pn-backend';
import { z } from 'zod';

import { Validates, ValidatesBlockContent } from '~types';

const blockContentPreviewItemSchema = z.object<Validates<BlockContentPreviewItem>>({
  gravity: z.nativeEnum(ImageProxyGravity).optional(),
  height: z.number().optional(),
  resize: z.nativeEnum(ImageProxyResize).optional(),
  width: z.number().optional(),
});

export const blockContentPreviewSchema = z.object<ValidatesBlockContent<BlockType.Preview>>({
  fileId: z.number({
    invalid_type_error:
      'Служебный блок "Превью" зависит от блока "Обложка". Проверьте корректность блока "Обложка"!',
    required_error:
      'Служебный блок "Превью" зависит от блока "Обложка". Проверьте корректность блока "Обложка"!',
  }),
  images: z.object({
    [PreviewImageFormat.Small]: blockContentPreviewItemSchema,
    [PreviewImageFormat.Medium]: blockContentPreviewItemSchema,
    [PreviewImageFormat.Big]: blockContentPreviewItemSchema,
    [PreviewImageFormat.Wide]: blockContentPreviewItemSchema,
    [PreviewImageFormat.Mobile]: blockContentPreviewItemSchema,
  }),
});
