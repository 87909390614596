import { PlusOutlined } from '@ant-design/icons';
import { Button as AntdButton, Col, Divider, Row, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { BlockType } from 'pn-backend';
import { __, always, cond, equals, inc, includes, not, T } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Columns,
  Embed,
  Gallery,
  Gif,
  H2,
  H3,
  Image,
  Incut,
  LineBlock,
  List,
  MoreOnTopic,
  Question,
  Quote,
  TestQuestion,
  TestResult,
  Text,
  Title,
  Video,
} from '~assets';
import { usePost } from '~hooks';
import { hasPendingBlocksSelector } from '~selectors';
import { useCreateBlockMutation } from '~services';
import { getBlockTitle } from '~utils';

import { Button } from '../../components/Button';
import styles from './styles.module.scss';

const getBlockIcon = cond([
  [equals(BlockType.Intro), always(<Title />)],
  [equals(BlockType.H2), always(<H2 />)],
  [equals(BlockType.H3), always(<H3 />)],
  [equals(BlockType.Text), always(<Text />)],
  [equals(BlockType.Quote), always(<Quote />)],
  [equals(BlockType.Incut), always(<Incut />)],
  [equals(BlockType.Image), always(<Image />)],
  [equals(BlockType.Gif), always(<Gif />)],
  [equals(BlockType.Gallery), always(<Gallery />)],
  [equals(BlockType.Video), always(<Video />)],
  [equals(BlockType.Widget), always(<Embed />)],
  [equals(BlockType.MoreOnTopic), always(<MoreOnTopic />)],
  [equals(BlockType.List), always(<List />)],
  [equals(BlockType.Line), always(<LineBlock />)],
  [equals(BlockType.Quiz), always(<Question />)],
  [equals(BlockType.Columns), always(<Columns />)],
  [equals(BlockType.TestQuestion), always(<TestQuestion />)],
  [equals(BlockType.TestResults), always(<TestResult />)],
]);

const getBlockAddTitle = cond([
  [equals(BlockType.Text), always('текст')],
  [equals(BlockType.Card), always('карточку')],
  [equals(BlockType.TestQuestion), always('вопрос')],
  [T, always('')],
]);

const getFillType = cond<[BlockType], 'fill' | 'stroke'>([
  [
    includes(__, [BlockType.Incut, BlockType.MoreOnTopic, BlockType.TestQuestion]),
    always('stroke'),
  ],
  [T, always('fill')],
]);

interface Props {
  availableBlocks: BlockType[];
  parentId?: number;
  sortOrder: number;
}

export const AddBlock: FC<Props> = ({ availableBlocks, parentId, sortOrder }) => {
  const [createBlock, { isLoading: isCreatingBlock }] = useCreateBlockMutation();
  const { id } = usePost();

  const isSavingBlocks = useSelector(hasPendingBlocksSelector);

  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setActive(not);
  };

  const handleClickAdd = (type: BlockType) => () => {
    if (parentId) {
      createBlock({
        parentId,
        sortOrder: inc(sortOrder),
        type,
      });
    } else {
      createBlock({ postId: id, sortOrder: inc(sortOrder), type });
    }
    setActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  if (availableBlocks.length === 0) {
    return null;
  }

  if (availableBlocks.length === 1) {
    const [type] = availableBlocks;

    return (
      <Row justify="center">
        <Col>
          <Button
            disabled={isSavingBlocks}
            type="primary"
            onClick={handleClickAdd(type)}
            loading={isCreatingBlock}
          >
            Добавить {getBlockAddTitle(type)}
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Divider orientation="center" className={styles.divider} />
      <AntdButton
        icon={<PlusOutlined />}
        shape="circle"
        type="primary"
        size="large"
        onClick={handleClick}
        loading={isCreatingBlock}
        className={classNames(active && styles.hidden)}
        disabled={isSavingBlocks}
      />
      {active && (
        <div ref={ref} className={styles.list}>
          <Space>
            {availableBlocks.map((blockType) => (
              <Tooltip title={getBlockTitle(blockType)} key={blockType}>
                <Button
                  type="text"
                  icon={getBlockIcon(blockType)}
                  onClick={handleClickAdd(blockType)}
                  iconFillType={getFillType(blockType)}
                />
              </Tooltip>
            ))}
          </Space>
        </div>
      )}
    </div>
  );
};
