import { Col, Form, Input, Row } from 'antd';
import { BlockType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

const getBlockTitle = cond([
  [equals(BlockType.SeoTitle), always('SEO Title')],
  [equals(BlockType.Description), always('Description')],
  [T, always(null)],
]);

const getBlockPlaceholder = cond([
  [equals(BlockType.SeoTitle), always('Напишите Title')],
  [equals(BlockType.Description), always('Напишите Description')],
  [T, always(undefined)],
]);

const getBlockMaxCount = cond([
  [equals(BlockType.SeoTitle), always(120)],
  [equals(BlockType.Description), always(200)],
  [T, always(undefined)],
]);

export const SeoBlock: FC<BlockProps<BlockType.SeoTitle | BlockType.Description>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { content, type } = block;
  const { text } = content;

  const [count, setCount] = useState(getText(text).length);

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(getText(changedText).length);
  }, []);

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [content],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={getBlockMaxCount(type)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>{getBlockTitle(type)}</div>
        </Col>
        <Col span={24}>
          <Form.Item name="text" className={styles.inputRow}>
            <TextArea
              autoSize
              rows={1}
              className={styles.input}
              bordered={false}
              placeholder={getBlockPlaceholder(type)}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};
