import { ContentState } from 'draft-js';
import React from 'react';

import styles from './styles.module.scss';

type TooltipProps = { contentState: ContentState; entityKey: string };

// @ts-ignore
export const Tooltip: React.FC<TooltipProps> = ({ children, contentState, entityKey }) => {
  const { body } = contentState.getEntity(entityKey).getData();

  return (
    <span className={styles.tooltip} data-body={body}>
      {children}
    </span>
  );
};
