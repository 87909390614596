import createSagaMiddleware from 'redux-saga';

import {
  authorsApi,
  blocksApi,
  configApi,
  employeesApi,
  feedsApi,
  filesApi,
  gridsApi,
  gridSchemasApi,
  postsApi,
  sectionsApi,
  settingsApi,
  tagsApi,
  usersApi,
} from '~services';

export const sagaMiddleware = createSagaMiddleware();

export const middlewares = [
  sagaMiddleware,
  ...[
    usersApi,
    authorsApi,
    employeesApi,
    sectionsApi,
    gridsApi,
    gridSchemasApi,
    postsApi,
    blocksApi,
    configApi,
    filesApi,
    tagsApi,
    settingsApi,
    feedsApi,
  ].map((api) => api.middleware),
];
