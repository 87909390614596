import { Col, Form, Row, Select } from 'antd';
import classNames from 'classnames';
import { formatISO, parse } from 'date-fns';
import { PostStatus, PostType, SectionStatus } from 'pn-backend';
import { allPass, equals, isEmpty, not, pipe, propEq } from 'ramda';
import React, { useEffect } from 'react';

import { DatePicker, Visible } from '~components';
import { dateFormat } from '~constants';
import { useGetAuthorsListQuery, useGetSectionsListQuery } from '~services';
import { getStatusTitle, getTypeTitle, isDefined } from '~utils';

import styles from './styles.module.scss';

const { Option } = Select;

type PostsFilterValues = {
  publishedAt?: string;
  sections?: number[];
  term?: string;
  type?: PostType;
};
interface Props {
  inDrawer?: boolean;
  isNews: boolean;
  onChange?(values: any): void;
  values?: PostsFilterValues;
}

export const PostsFilter: React.FC<Props> = ({ inDrawer = false, isNews, onChange, values }) => {
  const [form] = Form.useForm<PostsFilterValues>();
  const { resetFields } = form;
  const { data: authors } = useGetAuthorsListQuery({});
  const { data: sections } = useGetSectionsListQuery({});

  const sectionsOptions = (sections?.results || []).filter(
    // Убираем новости и неопубликованные разделы
    allPass([pipe(propEq('alias', 'news'), not), propEq('status', SectionStatus.published)]),
  );

  const authorsOptions = authors?.results ?? [];

  useEffect(() => {
    if (isEmpty(values)) {
      resetFields();
    }
  }, [resetFields, values]);

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={onChange}
      className={styles.wrapper}
      initialValues={values}
    >
      <Row gutter={[25, 15]} className={classNames(inDrawer && styles.drawerContent)}>
        <Visible isVisible={!isNews}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item label="Тип публикации" name="type">
              <Select placeholder="Выбрать" size="large" allowClear>
                {Object.values(PostType)
                  .filter(allPass([pipe(isEmpty, not), pipe(equals(PostType.News), not)]))
                  .map((value) => (
                    <Option key={value} value={value}>
                      {getTypeTitle(value)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Visible>

        <Col xs={24} sm={24} md={12} lg={8}>
          <Form.Item label="Статус" name="status">
            <Select placeholder="Выбрать" size="large" allowClear>
              {Object.values(PostStatus).map((value) => (
                <Option key={value} value={value}>
                  {getStatusTitle(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8}>
          <Form.Item
            label="Дата публикации"
            name="publishedAt"
            getValueFromEvent={(value: Date) =>
              value ? formatISO(value, { format: 'extended', representation: 'date' }) : undefined
            }
            getValueProps={(value: string) => ({
              value: value ? parse(value, dateFormat, new Date()) : undefined,
            })}
          >
            <DatePicker
              size="large"
              format={dateFormat}
              style={{ width: '100%' }}
              inputReadOnly
              allowClear
            />
          </Form.Item>
        </Col>
        <Visible isVisible={!isNews}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item
              label="Автор"
              name="authors"
              getValueProps={(authorId: string) => ({
                value: authorId ? parseInt(authorId, 10) : undefined,
              })}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
                }
                options={authorsOptions}
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder="Выбрать"
                size="large"
                allowClear
              />
            </Form.Item>
          </Col>
        </Visible>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Form.Item
            label="Раздел"
            name="sections"
            getValueProps={(sectionIds: string | string[] = []) => ({
              value: (Array.isArray(sectionIds) ? sectionIds : [sectionIds]).map((sectionId) =>
                parseInt(sectionId, 10),
              ),
            })}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                isDefined(option) && option.title.toLowerCase().includes(input.toLowerCase())
              }
              options={sectionsOptions}
              fieldNames={{ label: 'title', value: 'id' }}
              placeholder="Выбрать"
              size="large"
              mode="multiple"
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
