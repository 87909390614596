import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentQuizSchema = z.object<ValidatesBlockContent<BlockType.Quiz>>({
  answerOptions: z
    .array(z.string().min(1, { message: 'Блок "Опрос" - ответ должен быть заполнен!' }))
    .min(1, { message: 'Блок "Опрос" - должен быть минимум 1 ответ!' }),
  caption: z.string(),
  fileId: z.number().nullable(),
  question: z.string().min(1, { message: 'Блок "Опрос" - вопрос должен быть заполнен!' }),
});
