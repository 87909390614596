import { createSlice } from '@reduxjs/toolkit';

import { gridsAdapter } from '~adapters';
import { gridsApi } from '~services';

export const gridsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(gridsApi.endpoints.getGridsList.matchFulfilled, (state, action) => {
        gridsAdapter.upsertMany(state, action.payload.results);
      })
      .addMatcher(gridsApi.endpoints.getGrid.matchFulfilled, (state, action) => {
        gridsAdapter.upsertOne(state, action.payload);
      });
  },
  initialState: gridsAdapter.getInitialState(),
  name: 'grids',
  reducers: {},
});

export const gridsReducer = gridsSlice.reducer;
