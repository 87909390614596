import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

import { draftContentSchema } from '../draft';

export const blockContentIntroSchema = z.object<ValidatesBlockContent<BlockType.Intro>>({
  content: draftContentSchema,
});
