import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { Http } from '@status/codes';
import { notification } from 'antd';
import { Mutex } from 'async-mutex';
import axios, { Method } from 'axios';
import { isEmpty, reject } from 'ramda';

// eslint-disable-next-line import/no-cycle
import { configApiRootSelector } from '~selectors';
import { noop, stringifyQueryString } from '~utils';

const mutex = new Mutex();

const Api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: stringifyQueryString({ arrayFormat: 'bracket' }),
  },
  withCredentials: true,
});

type QueryArg = {
  contentType?: string;
  data?: any;
  ignoreErrors?: number[];
  method?: Method;
  onUploadProgress?(progressEvent: any): void;
  params?: Record<string, any> | void;
  url: string;
  withAuth?: boolean;
  withCredentials?: boolean;
};

const axiosBaseQuery = async (
  request: QueryArg | string,
  state: any,
  extraOptions: Record<string, any>,
): Promise<any> => {
  const {
    url = request as string,
    method = 'GET',
    data = undefined,
    params = null,
    onUploadProgress = noop,
    contentType = null,
    ignoreErrors = [],
    withAuth = true,
    withCredentials = true,
  } = typeof request === 'string' ? {} : request;

  try {
    const baseURL = configApiRootSelector(state.getState() as RootState) || window.location.origin;

    const headers: Record<string, string> = {
      'Content-Type': contentType ?? 'application/json',
    };

    if (withAuth) {
      const token = localStorage.getItem('token');

      headers.Authorization = `Bearer ${token}`;
    }

    const result = await Api(url, {
      baseURL,
      data,
      headers,
      method,
      onUploadProgress,
      params: params ? reject(isEmpty, params) : null,
      withCredentials,
      ...extraOptions,
    });

    return { data: result.data };
  } catch (axiosError: any) {
    const error = {
      data: axiosError.response?.data ?? axiosError?.message ?? '',
      status: axiosError.response?.status ?? Http.BadRequest,
    };

    /** ToDo Handle and translate 400 errors */
    if (error?.status >= Http.BadRequest) {
      // eslint-disable-next-line no-console
      console.error('apiError...', { error });

      if (!ignoreErrors.includes(error.status)) {
        notification.error({
          description: `/${url} (${method}) ${JSON.stringify(error.data)}`,
          duration: 60,
          message: `Ошибка выполнения запроса (${error?.status ?? ''})`,
          placement: 'bottomRight',
        });
      }
    }

    return { error };
  }
};

export const authGuardQuery: BaseQueryFn = async (
  request: QueryArg | string,
  api: any,
  extraOptions: Record<string, any>,
): Promise<any> => {
  await mutex.waitForUnlock();
  const proxyRequest = {
    ignoreErrors: [Http.Unauthorized],
    ...(typeof request === 'string' ? { url: request } : request),
  };

  const result = await axiosBaseQuery(proxyRequest, api, extraOptions);

  if (result?.error?.status === Http.Unauthorized && localStorage.getItem('token')) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await axiosBaseQuery(
          { ignoreErrors: [Http.Forbidden], url: '/auth/refresh' },
          api,
          {
            ...extraOptions,
            credentials: 'include',
          },
        );

        if (refreshResult.data) {
          localStorage.setItem('token', refreshResult.data.accessToken);
          return await axiosBaseQuery(request, api, extraOptions);
        }

        localStorage.removeItem('token');
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      return axiosBaseQuery(proxyRequest, api, extraOptions);
    }
  }

  return result;
};

export default Api;
