import { BlockType, IncutType, ListType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';
import { getTextFromRawDraftContentState } from '~utils';

import { draftContentSchema } from '../draft';

export const blockContentIncutSchema = z.object<ValidatesBlockContent<BlockType.Incut>>({
  caption: z.string(),
  content: draftContentSchema.refine(
    (content) => getTextFromRawDraftContentState(content).length !== 0,
    { message: 'Блок "Врезка" - текст обязателен для заполнения!' },
  ),
  items: z.array(z.string()),
  listType: z.nativeEnum(ListType),
  type: z.nativeEnum(IncutType),
});
