import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentGifSchema = z.object<ValidatesBlockContent<BlockType.Gif>>({
  caption: z.string(),
  fileId: z.number({
    invalid_type_error:
      'В блоке "Gif" обязательно должно быть загружено изображение. Загрузите необходимые медиаданные',
    required_error:
      'В блоке "Gif" обязательно должно быть загружено изображение. Загрузите необходимые медиаданные',
  }),
});
