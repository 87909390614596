import { useContext } from 'react';

import { UserContext } from '~providers';
import { isDefined } from '~utils';

export const useIsMe = (employeeId?: number): boolean => {
  const { user } = useContext(UserContext);

  return isDefined(employeeId) && isDefined(user) ? user.id === employeeId : false;
};
