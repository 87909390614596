import { CloseCircleOutlined } from '@ant-design/icons';
import { JsonViewer } from '@textea/json-viewer';
import { Alert, Button, Popover, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { ZodIssue } from 'zod';

interface Props {
  validationError: ZodIssue[] | Error;
}

export const ValidationError = (props: Props) => {
  const { validationError } = props;
  const [isOpened, setIsOpened] = useState(false);
  return (
    <Popover
      open={isOpened}
      title={
        <Row justify="end">
          <Button
            icon={<CloseCircleOutlined />}
            type="text"
            onClick={() => {
              setIsOpened(false);
            }}
          />
        </Row>
      }
      content={
        <Row style={{ maxHeight: '300px', overflow: 'auto' }}>
          {'stack' in validationError ? (
            <Alert
              type="error"
              message={`${validationError.name}: ${validationError.message}`}
              description={validationError.stack}
            />
          ) : (
            <JsonViewer enableClipboard={false} value={validationError} />
          )}
        </Row>
      }
      onOpenChange={setIsOpened}
    >
      <Typography.Text type="danger">Ошибка валидации</Typography.Text>
    </Popover>
  );
};
