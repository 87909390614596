import { Button, Col, Form, Input, Row, Space, Switch, Typography } from 'antd';
import { SectionPreviewSecure } from 'pn-backend';
import { always, ifElse, isNil } from 'ramda';
import React, { FC } from 'react';

import { Modal } from '~components';

import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  onClose(section?: Partial<SectionPreviewSecure>): void;

  section?: Partial<SectionPreviewSecure>;
}

const getModalTitle = ifElse(isNil, always('Добавление раздела'), always('Редактирование раздела'));
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

export const SectionModal: FC<Props> = ({ isOpen = false, onClose, section }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleFinish = (values: Partial<SectionPreviewSecure>) => {
    onClose({ ...section, ...values });
  };

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(section?.id)}
      destroyOnClose
      onCancel={handleCancel}
      width={470}
      footer={null}
    >
      <Form
        layout="vertical"
        name="section"
        validateTrigger="submit"
        onFinish={handleFinish}
        initialValues={section}
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item name="title">
                  <Input size="large" placeholder="Введите название раздела" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В главном меню</Typography.Text>
                  <Form.Item name="isShownInMenu" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В футере</Typography.Text>
                  <Form.Item name="isShownInFooter" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={handleCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(section?.id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
