import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreateTagBodySecure,
  GetManyTagsQuerySecure,
  GetManyTagsResponseSecure,
  Tag,
  UpdateTagBodySecure,
} from 'pn-backend';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

import { authGuardQuery } from './defaultApi';

export const tagsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createTag: builder.mutation<Tag, Partial<CreateTagBodySecure>>({
      invalidatesTags: [{ id: 'List', type: 'Tags' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Tags),
      }),
    }),
    deleteTag: builder.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Tags' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Tags, id),
      }),
    }),
    getTagsList: builder.query<GetManyTagsResponseSecure, Partial<GetManyTagsQuerySecure>>({
      providesTags: () => [{ id: 'List', type: 'Tags' }],
      query: (params = {}) => ({
        method: 'GET',
        params: {
          order: '-updatedAt',
          ...params,
        },
        url: createUrl(ApiPath.Tags),
      }),
    }),
    updateTag: builder.mutation<Tag, { changes: UpdateTagBodySecure; id: number }>({
      invalidatesTags: [{ id: 'List', type: 'Tags' }],
      query: (data) => ({
        data: data.changes,
        method: 'PATCH',
        url: createUrl(ApiPath.Tags, data.id),
      }),
    }),
  }),
  reducerPath: 'tagssApi',
  tagTypes: ['Tags'],
});

export const {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetTagsListQuery,
  useUpdateTagMutation,
} = tagsApi;
