import { EditorState, Modifier } from 'draft-js';

export const getEmoji = (editorState: EditorState, enteredEmoji: string): EditorState => {
  const emojiValue = enteredEmoji ?? null;

  if (emojiValue) {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(currentContent, currentSelection, emojiValue);

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  }
  return editorState;
};
