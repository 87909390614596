import { EditorState, Modifier, SelectionState } from 'draft-js';

export const makeUnstyled = (editorState: EditorState, blocksToUnstyled: string[]) => {
  const contentState = editorState.getCurrentContent();
  let contentUnstyled = contentState;
  let newEditorState = editorState;
  const blocksMap = contentState.getBlockMap();

  blocksMap.forEach((block) => {
    const blockType = block?.getType() ?? '';
    if (blocksToUnstyled.includes(blockType)) {
      const selectionState = SelectionState.createEmpty(block?.getKey() ?? '');
      const updatedSelection = selectionState.merge({
        anchorOffset: block?.getText().length,
        focusOffset: 0,
      });

      contentUnstyled = Modifier.setBlockType(contentUnstyled, updatedSelection, 'unstyled');
    }
  });

  const currentSelection = newEditorState.getSelection();

  newEditorState = EditorState.push(newEditorState, contentUnstyled, 'change-block-type');

  newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

  return newEditorState;
};
