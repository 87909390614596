import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { blocksAdapter } from '~adapters';

import { stateSelector } from './state';

export const blocksEntitySelector = blocksAdapter.getSelectors<RootState>(prop('blocks'));

export const blocksSelector = createDraftSafeSelector(stateSelector, ({ blocks }) => blocks);

export const hasPendingBlocksSelector = createDraftSafeSelector(
  blocksSelector,
  ({ pendingBlocks }) => pendingBlocks.length > 0,
);
