import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, ColProps } from 'antd';
import React, { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren &
  Omit<ColProps, 'id'> & {
    disabled?: boolean;
    id: string | number;
  };

export const SortableItem: FC<Props> = ({ children, disabled = false, id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled: { draggable: disabled, droppable: disabled },
    id,
  });

  const style = {
    cursor: disabled ? 'default' : 'pointer',
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Col ref={setNodeRef} style={style} {...attributes} {...listeners} {...props}>
      {children}
    </Col>
  );
};
