import { Col, Row, Typography } from 'antd';
import { FileSecure, GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import { always, pipe, prop, propEq, times, uniqBy, when } from 'ramda';
import { FunctionComponent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Change, Delete, Plus } from '~assets';
import { Badge, Button } from '~components';
import { BadgeColor, DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { GridContext } from '~providers';
import { updateManyGridSchemas } from '~slices';
import { deleteMarkup, isDefined } from '~utils';

import { DropZone } from '../../../DropZone';
import { searchPostsModal } from '../../../SearchPostModal';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

type PostProps = PostPreviewSecure<PostType> & {
  onChange(id: PostPreviewSecure<PostType>['id']): void;
};

interface AddPostProps {
  onAdd(): void;
}

const Post: FunctionComponent<PostProps> = (props) => {
  const { id, onChange, tags, title } = props;

  const [tag] = tags || [];

  return (
    <Col span={24} className={styles.post}>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Typography.Text className={styles.title}>{deleteMarkup(title?.text)}</Typography.Text>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 0]} justify="space-between" align="middle">
            <Col>
              {tag && (
                <Badge size="small" color={BadgeColor.GreyDark} transparent>
                  {tag?.name}
                </Badge>
              )}
            </Col>
            <Col>
              <Button
                icon={<Change />}
                onClick={pipe(always(id), onChange)}
                type="text"
                iconFillType="stroke"
                textClassName={styles.changeText}
              >
                Заменить
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const AddPost: FunctionComponent<AddPostProps> = ({ onAdd }) => {
  return (
    <Col span={24} className={styles.add}>
      <Button
        icon={<Plus className={styles.plusIcon} />}
        onClick={onAdd}
        type="text"
        className={styles.addButton}
      >
        Добавить публикацию
      </Button>
    </Col>
  );
};

export const MainCompilation: FunctionComponent<GridSchemaSecure> = (props) => {
  const {
    posts = [],
    cover: defaultCover,
    id,
    maxPostCount,
    title,
    filterBySectionId,
    filterByPostTypes,
  } = props;
  const dispatch = useDispatch();
  const [cover, setCover] = useState<typeof defaultCover | undefined>(defaultCover);
  const { grid } = useContext(GridContext);

  const isMobile = useIsMobile();

  const handleClear = () => {
    setCover(undefined);
    dispatch(
      updateManyGridSchemas([{ changes: { coverId: undefined, posts: [], title: undefined }, id }]),
    );
  };

  const handleChangeCover = (file: FileSecure) => {
    setCover(file);
    dispatch(updateManyGridSchemas([{ changes: { coverId: file.id }, id }]));
  };

  const handleChangePost = async (postId?: PostPreviewSecure<PostType>['id']) => {
    const [post] = await searchPostsModal({
      maxPostCount: 1,
      sections: isDefined(grid) ? [filterBySectionId, grid.id] : [filterBySectionId],
      types: filterByPostTypes,
    });

    const newPosts = [...posts.map(when(propEq('id', postId), always(post))), post];

    dispatch(updateManyGridSchemas([{ changes: { posts: uniqBy(prop('id'), newPosts) }, id }]));
  };

  const canClear = cover || posts?.length > 0 || title?.length > 0;

  return (
    <BlockWrapper {...props} canChangePosts={false} canChangeTitle>
      <Row gutter={[15, 15]} className={styles.wrapper}>
        <Col md={{ span: 12 }} xs={{ span: 24 }} className={styles.imageWrapper}>
          <DropZone
            value={cover}
            type={DropType.Image}
            onChange={handleChangeCover}
            imageOptions={{ height: isMobile ? 177 : 197, width: isMobile ? 240 : 300 }}
          />
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Row gutter={[0, 10]}>
            {posts.slice(0, 3).map((post) => (
              <Post key={post.id} onChange={handleChangePost} {...post} />
            ))}
            {times(
              () => (
                <AddPost onAdd={handleChangePost} />
              ),
              maxPostCount - posts.length,
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="end" className={styles.footer}>
            <Col>
              <Button
                icon={<Delete />}
                onClick={handleClear}
                textClassName={styles.clearButton}
                disabled={!canClear}
                type="text"
              >
                Очистить блок
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </BlockWrapper>
  );
};
