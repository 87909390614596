import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';
import { getTextFromRawDraftContentState } from '~utils';

import { draftContentSchema } from '../draft';

export const blockContentH3Schema = z.object<ValidatesBlockContent<BlockType.H3>>({
  content: draftContentSchema.refine(
    (content) => {
      const text = getTextFromRawDraftContentState(content);

      return text.length >= 5 && text.length <= 100;
    },
    {
      message: 'Блок "H3" - не менее 5 и не более 100 символов!',
    },
  ),
});

export const blockContentH3SchemaForInnerCardBlock = z.object<ValidatesBlockContent<BlockType.H3>>({
  content: draftContentSchema.superRefine((content, ctx) => {
    if (getTextFromRawDraftContentState(content).length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        fatal: true,
        inclusive: true,
        message: 'Блок "Заголовок H3" обязателен для заполнения. Внесите, пожалуйста, данные',
        minimum: 5,
        type: 'string',
      });
    }
    if (
      (getTextFromRawDraftContentState(content).length >= 1 &&
        getTextFromRawDraftContentState(content).length < 5) ||
      getTextFromRawDraftContentState(content).length > 100
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Заголовок H3" - не менее 5 и не более 100 символов',
      });
    }
  }),
});
