import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { stateSelector } from './state';

export const validationStateSelector = createDraftSafeSelector(
  stateSelector,
  ({ validation }) => validation,
);

export const validationErrorsSelector = createDraftSafeSelector(
  validationStateSelector,
  ({ errors }) => errors,
);
