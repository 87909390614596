import { Form, FormInstance } from 'antd';
import { PostType, Setting, UpdateSettingBodySecure } from 'pn-backend';
import { pick, propEq } from 'ramda';
import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { TypeSettings } from '~constants';
import {
  useGetListPostsQuery,
  useGetPostByIdQuery,
  useGetSectionsListQuery,
  useGetSettingsQuery,
} from '~services';
import { PostProps } from '~types';
import { isDefined, isNotNil } from '~utils';

export type SettingsCustomPage = TypeSettings.About | TypeSettings.Ads;
export type SettingForms = TypeSettings.Contacts | TypeSettings.Socials;
type Context = {
  forms: {
    [key in SettingForms]: {
      form: FormInstance<UpdateSettingBodySecure>;
      initialValues: Partial<Setting>;
      isError: boolean;
      isFetching: boolean;
    };
  };
  isReadyToSave: boolean;
  posts: {
    [key in SettingsCustomPage]: {
      isError: boolean;
      isFetching: boolean;
      post?: PostProps['post'];
    };
  };
};

// ToDo Отрефакторить: использовать PostContext для получения данных о постах
export const SettingsContext = createContext<Context>({} as Context);

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [socialsForm] = Form.useForm<UpdateSettingBodySecure>();
  const [contactsForm] = Form.useForm<UpdateSettingBodySecure>();

  const {
    data: settings,
    isError: isErrorSettings,
    isFetching: isFetchingSettings,
  } = useGetSettingsQuery({
    relations: ['certificate'],
  });

  const {
    data: sections,
    isError: isErrorSections,
    isFetching: isFetchingSections,
  } = useGetSectionsListQuery({});

  const aboutSectionId = sections?.results.find(propEq('alias', 'about'))?.id;
  const {
    data: aboutSectionPosts,
    isError: isErrorAboutSectionPosts,
    isFetching: isFetchingAboutSectionPosts,
  } = useGetListPostsQuery(
    {
      sections: isDefined(aboutSectionId) ? [aboutSectionId] : [],
      type: [PostType.Custom],
    },
    { refetchOnMountOrArgChange: true, skip: !aboutSectionId },
  );

  const aboutPostId = aboutSectionPosts?.results[0]?.id;
  const {
    data: aboutPost,
    isError: isErrorAboutPost,
    isFetching: isFetchingAboutPost,
  } = useGetPostByIdQuery(
    {
      id: aboutPostId as number,
      relations: ['blocks'],
    },
    { refetchOnMountOrArgChange: true, skip: !aboutPostId },
  );

  const advertisersSectionId = sections?.results.find(propEq('alias', 'advertisers'))?.id;
  const {
    data: advertisersSectionPosts,
    isError: isErrorAdvertisersSectionPosts,
    isFetching: isFetchingAdvertisersSectionPosts,
  } = useGetListPostsQuery(
    {
      sections: isDefined(advertisersSectionId) ? [advertisersSectionId] : [],
      type: [PostType.Custom],
    },
    { refetchOnMountOrArgChange: true, skip: !advertisersSectionId },
  );

  const advertisersPostId = advertisersSectionPosts?.results[0]?.id;
  const {
    data: advertisersPost,
    isError: isErrorAdvertisersPost,
    isFetching: isFetchingAdvertisersPost,
  } = useGetPostByIdQuery(
    {
      id: advertisersPostId as number,
      relations: ['blocks'],
    },
    { refetchOnMountOrArgChange: true, skip: !advertisersPostId },
  );

  const value: Context = useMemo(
    () => ({
      forms: {
        [TypeSettings.Contacts]: {
          form: contactsForm,
          initialValues: pick(
            [
              'certificate',
              'certificateId',
              'additional',
              'certificateTitle',
              'copyright',
              'address',
              'founder',
              'chiefEditor',
              'email',
            ],
            settings ?? {},
          ),
          isError: isErrorSettings,
          isFetching: isFetchingSettings,
        },
        [TypeSettings.Socials]: {
          form: socialsForm,
          initialValues: pick(['socialNetworkLinks', 'socialNetworkShare'], settings ?? {}),
          isError: isErrorSettings,
          isFetching: isFetchingSettings,
        },
      },
      isReadyToSave: isNotNil(aboutPostId) && isNotNil(advertisersPostId) && isNotNil(settings),
      posts: {
        [TypeSettings.About]: {
          isError: isErrorSections || isErrorAboutSectionPosts || isErrorAboutPost,
          isFetching: isFetchingSections || isFetchingAboutSectionPosts || isFetchingAboutPost,
          post: aboutPost,
        },
        [TypeSettings.Ads]: {
          isError: isErrorSections || isErrorAdvertisersSectionPosts || isErrorAdvertisersPost,
          isFetching:
            isFetchingSections || isFetchingAdvertisersSectionPosts || isFetchingAdvertisersPost,
          post: advertisersPost,
        },
      },
    }),
    [
      contactsForm,
      settings,
      isErrorSettings,
      isFetchingSettings,
      socialsForm,
      aboutPostId,
      advertisersPostId,
      isErrorSections,
      isErrorAboutSectionPosts,
      isErrorAboutPost,
      isFetchingSections,
      isFetchingAboutSectionPosts,
      isFetchingAboutPost,
      aboutPost,
      isErrorAdvertisersSectionPosts,
      isErrorAdvertisersPost,
      isFetchingAdvertisersSectionPosts,
      isFetchingAdvertisersPost,
      advertisersPost,
    ],
  );
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
