import { createSlice } from '@reduxjs/toolkit';

import { authorsAdapter } from '~adapters';
import { authorsApi } from '~services';

interface State {
  count: number;
  lastRequestId?: string;
}

export const authorsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(authorsApi.endpoints.getAuthorsList.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0) {
          authorsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(authorsApi.endpoints.getAuthorsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          authorsAdapter.addMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(authorsApi.endpoints.createAuthor.matchFulfilled, (state, action) => {
        authorsAdapter.upsertOne(state, action.payload);
        state.count += 1;
      })
      .addMatcher(authorsApi.endpoints.deleteAuthor.matchFulfilled, (state) => {
        authorsAdapter.removeAll(state);
      })
      .addMatcher(authorsApi.endpoints.getAuthorById.matchFulfilled, (state, action) => {
        authorsAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(authorsApi.endpoints.updateAuthor.matchFulfilled, (state, action) => {
        authorsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.meta.arg.originalArgs.id,
        });
      });
  },
  initialState: authorsAdapter.getInitialState<State>({
    count: 0,
  }),
  name: 'authors',
  reducers: {},
});

export const authorsReducer = authorsSlice.reducer;
