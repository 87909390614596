import { BlockChildMap, BlockType, PostType, PostTypeBlockMap } from 'pn-backend';
import { difference } from 'ramda';
import * as z from 'zod';
import { ZodObject } from 'zod';

import {
  ChildBlockContentValidationSchemaMap,
  ChildBlockValidationSchemaMap,
  MandatoryChildBlocksSchemaMap,
  PostBlockContentValidationSchemaMap,
  PostBlocksValidationSchemaMap,
  ValidatesBlock,
  ValidatesPostBlocks,
} from '~types';
import { getBlockTitle, isDefined } from '~utils';

import {
  blockContentCardSchema,
  blockContentColumnsSchema,
  blockContentCoverSchema,
  blockContentDescriptionNotRequiredSchema,
  blockContentDescriptionSchema,
  blockContentGallerySchema,
  blockContentGifSchema,
  blockContentH1Schema,
  blockContentH2Schema,
  blockContentH2SchemaForInnerCardBlock,
  blockContentImageSchema,
  blockContentIncutSchema,
  blockContentIntroSchema,
  blockContentLineSchema,
  blockContentListSchema,
  blockContentMetaSchema,
  blockContentMoreOnTopicSchema,
  blockContentNewsSourceSchema,
  blockContentPreviewSchema,
  blockContentQuizSchema,
  blockContentQuoteSchema,
  blockContentSeoTitleSchema,
  blockContentSpecLinkSchema,
  blockContentSubtitleSchema,
  blockContentTextSchema,
  blockContentTextSchemaMandatory,
  blockContentVideoSchema,
  blockContentWidgetSchema,
} from './blocks';
import { blockContentH3Schema } from './blocks/h3';
import { sectionValidationSchema } from './section';

export const cardMandatoryChildBlocks = [BlockType.Text];

export const mandatoryChildBlocksSchemaMap: MandatoryChildBlocksSchemaMap = {
  [BlockType.Card]: cardMandatoryChildBlocks,
};

export const cardBlockBlockContentSchemaMap: ChildBlockContentValidationSchemaMap<BlockType.Card> =
  {
    [BlockType.H2]: blockContentH2SchemaForInnerCardBlock,
    [BlockType.Image]: blockContentImageSchema,
    [BlockType.Gif]: blockContentGifSchema,
    [BlockType.Gallery]: blockContentGallerySchema,
    [BlockType.Video]: blockContentVideoSchema,
    [BlockType.Quote]: blockContentQuoteSchema,
    [BlockType.Text]: blockContentTextSchemaMandatory,
    [BlockType.Widget]: blockContentWidgetSchema,
    [BlockType.List]: blockContentListSchema,
  };

export const childBlocksValidationSchemas: ChildBlockValidationSchemaMap = {
  [BlockType.Card]: cardBlockBlockContentSchemaMap,
};

const newsBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.News> = {
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.NewsSource]: blockContentNewsSourceSchema,
  [BlockType.Text]: blockContentTextSchemaMandatory,
};

const specBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.Spec> = {
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.Cover]: blockContentCoverSchema,
  [BlockType.SpecLink]: blockContentSpecLinkSchema,
  [BlockType.Preview]: blockContentPreviewSchema,
};

const cardBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.Card> = {
  [BlockType.Card]: blockContentCardSchema,
  [BlockType.Cover]: blockContentCoverSchema,
  [BlockType.Description]: blockContentDescriptionSchema,
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.Intro]: blockContentIntroSchema,
  [BlockType.Meta]: blockContentMetaSchema,
  [BlockType.Preview]: blockContentPreviewSchema,
  [BlockType.SeoTitle]: blockContentSeoTitleSchema,
  [BlockType.Subtitle]: blockContentSubtitleSchema,
};

const articleBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.Article> = {
  [BlockType.Cover]: blockContentCoverSchema,
  [BlockType.Description]: blockContentDescriptionSchema,
  [BlockType.Gallery]: blockContentGallerySchema,
  [BlockType.Gif]: blockContentGifSchema,
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.H2]: blockContentH2Schema,
  [BlockType.H3]: blockContentH3Schema,
  [BlockType.Image]: blockContentImageSchema,
  [BlockType.Incut]: blockContentIncutSchema,
  [BlockType.Intro]: blockContentIntroSchema,
  [BlockType.List]: blockContentListSchema,
  [BlockType.Meta]: blockContentMetaSchema,
  [BlockType.MoreOnTopic]: blockContentMoreOnTopicSchema,
  [BlockType.Preview]: blockContentPreviewSchema,
  [BlockType.Quiz]: blockContentQuizSchema,
  [BlockType.Quote]: blockContentQuoteSchema,
  [BlockType.SeoTitle]: blockContentSeoTitleSchema,
  [BlockType.Subtitle]: blockContentSubtitleSchema,
  [BlockType.Text]: blockContentTextSchema,
  [BlockType.Video]: blockContentVideoSchema,
  [BlockType.Widget]: blockContentWidgetSchema,
};

const newsFullBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.NewsFull> = {
  [BlockType.Cover]: blockContentCoverSchema,
  [BlockType.Description]: blockContentDescriptionNotRequiredSchema,
  [BlockType.Gallery]: blockContentGallerySchema,
  [BlockType.Gif]: blockContentGifSchema,
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.H2]: blockContentH2Schema,
  [BlockType.H3]: blockContentH3Schema,
  [BlockType.Image]: blockContentImageSchema,
  [BlockType.Incut]: blockContentIncutSchema,
  [BlockType.Intro]: blockContentIntroSchema,
  [BlockType.List]: blockContentListSchema,
  [BlockType.Meta]: blockContentMetaSchema,
  [BlockType.MoreOnTopic]: blockContentMoreOnTopicSchema,
  [BlockType.NewsSource]: blockContentNewsSourceSchema,
  [BlockType.Preview]: blockContentPreviewSchema,
  [BlockType.Quiz]: blockContentQuizSchema,
  [BlockType.Quote]: blockContentQuoteSchema,
  [BlockType.SeoTitle]: blockContentSeoTitleSchema,
  [BlockType.Subtitle]: blockContentSubtitleSchema,
  [BlockType.Text]: blockContentTextSchema,
  [BlockType.Video]: blockContentVideoSchema,
  [BlockType.Widget]: blockContentWidgetSchema,
};

const longreadBlockContentSchemaMap: PostBlockContentValidationSchemaMap<PostType.Longread> = {
  [BlockType.Columns]: blockContentColumnsSchema,
  [BlockType.Cover]: blockContentCoverSchema,
  [BlockType.Description]: blockContentDescriptionSchema,
  [BlockType.Gallery]: blockContentGallerySchema,
  [BlockType.Gif]: blockContentGifSchema,
  [BlockType.H1]: blockContentH1Schema,
  [BlockType.H2]: blockContentH2Schema,
  [BlockType.H3]: blockContentH3Schema,
  [BlockType.Image]: blockContentImageSchema,
  [BlockType.Incut]: blockContentIncutSchema,
  [BlockType.Intro]: blockContentIntroSchema,
  [BlockType.Line]: blockContentLineSchema,
  [BlockType.List]: blockContentListSchema,
  [BlockType.Meta]: blockContentMetaSchema,
  [BlockType.MoreOnTopic]: blockContentMoreOnTopicSchema,
  [BlockType.Preview]: blockContentPreviewSchema,
  [BlockType.Quiz]: blockContentQuizSchema,
  [BlockType.Quote]: blockContentQuoteSchema,
  [BlockType.SeoTitle]: blockContentSeoTitleSchema,
  [BlockType.Subtitle]: blockContentSubtitleSchema,
  [BlockType.Text]: blockContentTextSchema,
  [BlockType.Video]: blockContentVideoSchema,
  [BlockType.Widget]: blockContentWidgetSchema,
};

// ToDo Разобраться, почему Validates<T> не приводится к T
const getChildBlocksValidationSchema = <T extends BlockType>(
  blockType: T,
  blockContentSchemaMap?: ChildBlockContentValidationSchemaMap<T>,
) =>
  z
    .array(
      z.discriminatedUnion(
        'type',
        Object.keys(blockContentSchemaMap ?? {}).map((blockType: BlockChildMap<T>) =>
          z.object<ValidatesBlock<BlockChildMap<T>>>({
            // @ts-expect-error Validates<T> не приводится к T
            content: blockContentSchemaMap[blockType],
            // @ts-expect-error Validates<T> не приводится к T
            type: z.literal(blockType),
          }),
        ) as NonEmptyArray<ZodObject<ValidatesBlock<BlockChildMap<T>>>>,
      ),
    )
    .superRefine((content, ctx) => {
      if (
        isDefined(mandatoryChildBlocksSchemaMap[blockType]) &&
        // @ts-expect-error mandatoryChildBlocksSchemaMap[blockType] проверяется на null выше
        mandatoryChildBlocksSchemaMap[blockType].length > 0 &&
        isDefined(content)
      ) {
        const missedBlocks = difference(
          // @ts-expect-error mandatoryChildBlocksSchemaMap[blockType] проверяется выше
          mandatoryChildBlocksSchemaMap[blockType],
          content.map((contentBlock) => contentBlock.type),
        );

        missedBlocks.forEach((missedBlock) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `В блоке "${getBlockTitle(
              blockType,
            )}" обязательно наличие блока "${getBlockTitle(missedBlock)}"!`,
          });
        });
      }
    });

const getPostBlocksValidationSchema = <T extends PostType>(
  postType: T,
  blockContentSchemaMap: PostBlockContentValidationSchemaMap<T>,
): ZodObject<ValidatesPostBlocks<T>> =>
  z.object({
    blocks: z.array(
      z.discriminatedUnion(
        'type',
        Object.keys(blockContentSchemaMap).map((blockType: PostTypeBlockMap[T]) =>
          z.object<ValidatesBlock<PostTypeBlockMap[T]>>({
            // @ts-expect-error Pick<'blocks'> взял всю структуру Block
            blocks: getChildBlocksValidationSchema(
              blockType,
              childBlocksValidationSchemas[blockType],
            ),
            // @ts-expect-error Validates<T> не приводится к T
            content: blockContentSchemaMap[blockType],
            // @ts-expect-error Validates<T> не приводится к T
            type: z.literal(blockType),
          }),
        ) as NonEmptyArray<ZodObject<ValidatesBlock<PostTypeBlockMap[T]>>>,
      ),
    ),
  }) as ZodObject<ValidatesPostBlocks<T>>;

// ToDo Реализовать схемы для всех типов постов
export const postValidationsSchemas: Partial<PostBlocksValidationSchemaMap> = {
  [PostType.News]: getPostBlocksValidationSchema(PostType.News, newsBlockContentSchemaMap),
  [PostType.NewsFull]: getPostBlocksValidationSchema(
    PostType.NewsFull,
    newsFullBlockContentSchemaMap,
  ),
  [PostType.Article]: getPostBlocksValidationSchema(PostType.Article, articleBlockContentSchemaMap),
  [PostType.Longread]: getPostBlocksValidationSchema(
    PostType.Longread,
    longreadBlockContentSchemaMap,
  ),
  [PostType.Spec]: getPostBlocksValidationSchema(PostType.Spec, specBlockContentSchemaMap),
  [PostType.Card]: getPostBlocksValidationSchema(PostType.Card, cardBlockContentSchemaMap),
};

export const postWarningsSchema = z.object({
  sections: z.array(sectionValidationSchema).min(1, { message: 'Не заполнен раздел!' }),
});
