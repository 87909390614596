import { PostType } from 'pn-backend';
import { ComponentType } from 'react';

import { RoutesPath } from '~constants';
import {
  AuthorsPage,
  AuthPage,
  EmployeeEditPage,
  EmployeesPage,
  GridSchemaPage,
  PostEditPage,
  PostsPage,
  SectionsPage,
  SettingsPage,
  TagsPage,
} from '~pages';

import { GridSchemasPage } from '../pages/GridSchemasPage';

type RouteType = {
  component: ComponentType;
  isPublic?: boolean;
  path: string;
  props?: Record<string, any>;
};

export const routes: Array<RouteType> = [
  {
    component: AuthPage,
    isPublic: true,
    path: RoutesPath.Login,
  },
  {
    component: PostsPage,
    path: RoutesPath.Posts,
  },
  {
    component: PostsPage,
    path: RoutesPath.News,
    props: {
      type: PostType.NewsFull,
    },
  },
  {
    component: PostEditPage,
    path: RoutesPath.PostEdit,
  },
  {
    component: GridSchemaPage,
    path: RoutesPath.GridSchema,
  },
  {
    component: GridSchemasPage,
    path: RoutesPath.GridSchemas,
  },
  {
    component: SectionsPage,
    path: RoutesPath.Sections,
  },
  {
    component: TagsPage,
    path: RoutesPath.Tags,
  },
  {
    component: EmployeeEditPage,
    path: RoutesPath.EmployeeEdit,
  },
  {
    component: EmployeesPage,
    path: RoutesPath.Employees,
  },
  {
    component: AuthorsPage,
    path: RoutesPath.Authors,
  },
  {
    component: SettingsPage,
    path: RoutesPath.Settings,
  },
];
