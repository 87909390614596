import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreateSectionBodySecure,
  GetManySectionsQuerySecure,
  GetManySectionsResponseSecure,
  GetSectionGridResponseSecure,
  PatchSectionsBodySecure,
  Section,
  UpdateSectionBodySecure,
} from 'pn-backend';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

import { authGuardQuery } from './defaultApi';

export const sectionsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createSection: builder.mutation<Section, Partial<CreateSectionBodySecure>>({
      invalidatesTags: [{ id: 'List', type: 'Sections' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Sections),
      }),
    }),
    getSectionGrid: builder.query<GetSectionGridResponseSecure, number>({
      query: (id) => ({
        method: 'GET',
        url: createUrl(ApiPath.Sections, id, ApiPath.Grid),
      }),
    }),
    getSectionsList: builder.query<
      GetManySectionsResponseSecure,
      Partial<GetManySectionsQuerySecure>
    >({
      providesTags: () => [{ id: 'List', type: 'Sections' }],
      query: (params = {}) => ({
        method: 'GET',
        params,
        url: createUrl(ApiPath.Sections),
      }),
    }),
    updateSection: builder.mutation<Section, { changes: UpdateSectionBodySecure; id: number }>({
      invalidatesTags: [{ id: 'List', type: 'Sections' }],
      query: (data) => ({
        data: data.changes,
        method: 'PATCH',
        url: createUrl(ApiPath.Sections, data.id),
      }),
    }),
    updateSections: builder.mutation<void, PatchSectionsBodySecure>({
      query: (data) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Sections),
      }),
    }),
  }),
  reducerPath: 'sectionsApi',
  tagTypes: ['Sections'],
});

export const {
  useCreateSectionMutation,
  useGetSectionGridQuery,
  useGetSectionsListQuery,
  useUpdateSectionMutation,
  useUpdateSectionsMutation,
} = sectionsApi;
