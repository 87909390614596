import { Button, Card, Col, Image, message, Modal, Row } from 'antd';
import { EmployeeExtSecure, EmployeeStatus, getFileURL, ImageProxyResize } from 'pn-backend';
import React, { useCallback, useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Edit, Lock, Unlock } from '~assets';
import { RoutesPath } from '~constants';
import { useHasPermission, useIsMe } from '~hooks';
import { ConfigContext } from '~providers';
import { useUpdateEmployeeMutation } from '~services';
import { getUserRoleTitle } from '~utils';

import styles from './styles.module.scss';

const { confirm } = Modal;

interface Props {
  item: EmployeeExtSecure;
  onEdit?(id: number): void;
}

const bodyStyle: React.CSSProperties = {
  height: '100%',
  padding: 0,
};

export const EmployeeCard: React.FC<Props> = (props) => {
  const { item, onEdit } = props;
  const { authorsCount = 0, avatar, id, name, postsCount = 0, role, status } = item;
  const isBlocked = status === 'blocked';
  const navigate = useNavigate();
  const { imageProxyHost } = useContext(ConfigContext);

  const hasPermission = useHasPermission();
  const isMe = useIsMe(id);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onCardClick = useCallback(() => {
    if (!isBlocked && (hasPermission || isMe)) {
      navigate(
        generatePath(RoutesPath.EmployeeEdit, {
          id,
        }),
      );
    }
  }, [navigate, id]);

  const onEditClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isBlocked && (hasPermission || isMe)) {
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      onEdit && onEdit(id);
    }
  };

  const onUnlockClick = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (isBlocked && hasPermission) {
        confirm({
          bodyStyle: { textAlign: 'center' },
          cancelText: 'Отменить',
          centered: true,
          closable: true,
          content: (
            <div>
              Пользователь <b>{name}</b> получит доступ к панели администратора.
            </div>
          ),
          icon: null,
          okText: 'Разблокировать',
          onOk: async () => {
            await updateEmployee({ id, status: EmployeeStatus.active });
            message.success('Пользователь разблокирован');
          },
          title: (
            <div>
              <b>Разблокировать пользователя?</b>
            </div>
          ),
        });
      }
    },
    [updateEmployee, id, isBlocked, name],
  );

  const onLockClick = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isBlocked) {
        confirm({
          bodyStyle: { textAlign: 'center' },
          cancelText: 'Отменить',
          centered: true,
          closable: true,
          content: (
            <div>
              Пользователь <b>{name}</b> станет неактивным.
            </div>
          ),
          icon: null,
          okText: 'Заблокировать',
          onOk: async () => {
            await updateEmployee({ id, status: EmployeeStatus.blocked });
            message.success('Пользователь заблокирован');
          },
          title: (
            <div>
              <b>Заблокировать пользователя?</b>
            </div>
          ),
        });
      }
    },
    [updateEmployee, id, isBlocked, name],
  );

  return (
    <Col xs={24} md={12}>
      <Card
        hoverable={!isBlocked && (hasPermission || isMe)}
        className={styles.card}
        bodyStyle={bodyStyle}
        onClick={onCardClick}
      >
        {isBlocked && <div className={styles.disable} />}
        <Row gutter={[15, 5]}>
          <Col>
            <Image
              src={
                avatar
                  ? getFileURL(avatar, {
                      domain: imageProxyHost,
                      height: 60,
                      resize: ImageProxyResize.Fill,
                      width: 60,
                    })
                  : undefined
              }
              preview={false}
              className={styles.imageWrapper}
            />
          </Col>
          <Col md={18} xs={14}>
            <Row>
              <div className={styles.nameAndRole}>
                <p className={styles.name}>{name}</p>
                <p className={styles.delimiterDot}>&#8226;</p>
                <p className={styles.role}>{getUserRoleTitle(role)}</p>
              </div>
            </Row>
            <Row gutter={[5, 5]}>
              <Col>
                <p className={styles.info}>Публикаций:</p>
              </Col>
              <Col>
                <p>{postsCount}</p>
              </Col>
            </Row>
            <Row gutter={[5, 5]}>
              <Col>
                <p className={styles.info}>Авторов:</p>
              </Col>
              <Col>
                <p>{authorsCount}</p>
              </Col>
            </Row>
          </Col>
          {(hasPermission || isMe) && (
            <Col md={2} xs={3}>
              <Row>
                <Col>
                  <Button disabled={isBlocked} icon={<Edit onClick={onEditClick} />} type="text" />
                </Col>
              </Row>
              {hasPermission && (
                <Row>
                  <Col>
                    {isBlocked ? (
                      <Button
                        className={styles.unlock}
                        icon={<Unlock onClick={onUnlockClick} />}
                        type="text"
                      />
                    ) : (
                      <Button icon={<Lock onClick={onLockClick} />} type="text" />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};
