import { Image } from 'antd';
import classNames from 'classnames';
import { CSSProperties, FC, SyntheticEvent, useState } from 'react';

import { Logo } from '~assets';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  gradient?: boolean;
  height?: number | string;
  objectFit?: CSSProperties['objectFit'];
  src?: string;
  width?: number | string;
}

export const Cover: FC<Props> = ({ className, gradient, height = 150, objectFit, src, width }) => {
  const [errorImage, setErrorImage] = useState<SyntheticEvent<HTMLImageElement, Event>>();

  if (src && !errorImage) {
    return (
      <div className={styles.wrapper}>
        <Image
          preview={false}
          src={src}
          alt=""
          height={height}
          width={width}
          className={classNames(styles.image, objectFit && styles[objectFit], className)}
          onError={setErrorImage}
        />
        {gradient && <div className={styles.gradient} />}
      </div>
    );
  }

  return (
    <div className={styles.defaultLogo} style={{ height, width }}>
      <Logo />
      {gradient && <div className={styles.gradient} />}
    </div>
  );
};
