export enum RichTextBlock {
  'bold' = 'BOLD',
  'emoji' = 'EMOJI',
  'highlight' = 'HIGHLIGHT',
  'italic' = 'ITALIC',
  'link' = 'LINK',
  'strikethrough' = 'STRIKETHROUGH',
  'tooltip' = 'TOOLTIP',
  'underline' = 'UNDERLINE',
}
