import { skipToken } from '@reduxjs/toolkit/query';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import cn from 'classnames';
import {
  CreateEmployeeBodySecure,
  EmployeeExtSecure,
  ImageProxyResize,
  UpdateEmployeeBodySecure,
} from 'pn-backend';
import React, { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '~components';
import { DropType } from '~constants';
import { useHasPermission, useIsMe } from '~hooks';
import { ConfigContext } from '~providers';
import { employeeByIdSelector } from '~selectors';
import {
  useCreateEmployeeMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from '~services';
import { noop } from '~utils';

import { DropZone } from '../DropZone';
import style from './styles.module.scss';

interface Props {
  id?: number;
  isOpen?: boolean;

  onClose?(employee?: EmployeeExtSecure): void;
}

export const EmployeeModal: FC<Props> = ({ id, isOpen = false, onClose = noop }) => {
  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const { imageProxyHost } = useContext(ConfigContext);

  const hasPermission = useHasPermission();
  const isMe = useIsMe(id);

  const modalTitle = id ? 'Редактирование пользователя' : 'Добавление нового пользователя';
  const submitButtonText = id ? 'Применить' : 'Добавить';
  const action = id
    ? (data: Partial<UpdateEmployeeBodySecure>) => updateEmployee({ ...data, id })
    : (data: CreateEmployeeBodySecure) => createEmployee(data);

  useGetEmployeeByIdQuery(id ? { id } : skipToken);
  const employee = useSelector(employeeByIdSelector(id));

  const onCancel = () => {
    onClose(undefined);
  };

  const onFinish = async (values: any) => {
    const actionParams = {
      avatarId: values?.avatar?.id,
      login: values.login,
      name: values.name,
      password: values.password?.toString(),
      role: values.role,
    };

    // @ts-ignore
    const { data: newEmployee } = await action(actionParams);

    onClose(newEmployee);
  };

  const initialValues = useMemo(() => {
    if (employee) {
      return {
        ...employee,
      };
    }

    return {
      role: 'editor',
    };
  }, [employee]);

  return (
    <Modal
      title={modalTitle}
      open={isOpen}
      destroyOnClose
      onCancel={onCancel}
      width={613}
      footer={null}
    >
      <Form
        layout="vertical"
        name="employee"
        validateTrigger="submit"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={[0, 15]}>
          <Col md={12} xs={24}>
            <Form.Item name="avatar">
              <DropZone
                type={DropType.Image}
                imageOptions={{
                  domain: imageProxyHost,
                  height: 250,
                  resize: ImageProxyResize.Fill,
                  width: 250,
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Row gutter={[15, 15]}>
              <Col span={24}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Form.Item name="name" label={<label className={style.label}>Имя</label>}>
                  <Input size="large" placeholder="Имя" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hidden={!hasPermission}
                  name="role"
                  /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                  label={<label className={style.label}>Роль</label>}
                >
                  <Select
                    size="large"
                    options={[
                      { label: 'Главный редактор', value: 'chief' },
                      { label: 'Редактор', value: 'editor' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              name="login"
              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
              label={<label style={{ marginBottom: '10px' }}>Логин</label>}
            >
              <Input size="large" placeholder="Логин" disabled={!isMe && !!id} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row
              gutter={[
                { md: 15, xs: 15 },
                { md: 0, xs: 15 },
              ]}
            >
              <Col md={12} xs={24}>
                <Form.Item
                  name="password"
                  /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                  label={<label className={style.label}>Пароль</label>}
                >
                  <Input.Password
                    // @ts-ignore
                    autocomplete="new-password"
                    size="large"
                    placeholder="Пароль"
                    disabled={!isMe && !!id}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="confirmPassword"
                  /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                  label={<label className={style.label}>Подтверждение пароля</label>}
                >
                  <Input.Password
                    // @ts-ignore
                    autocomplete="new-password"
                    size="large"
                    placeholder="Подтвердить пароль"
                    disabled={!isMe && !!id}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col md={{ order: 1, span: 12 }} xs={{ order: 2, span: 24 }}>
                <Row justify={{ md: 'end', xs: 'center' }}>
                  <Button
                    type="text"
                    size="large"
                    className={cn(style.button, style.decline)}
                    onClick={onCancel}
                  >
                    Отменить
                  </Button>
                </Row>
              </Col>
              <Col md={{ order: 2, span: 12 }} xs={{ order: 1, span: 24 }}>
                <Row justify={{ md: 'start', xs: 'center' }}>
                  <Button type="primary" htmlType="submit" size="large" className={style.button}>
                    {submitButtonText}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
