import { BlockType, CoverFormat } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentCoverSchema = z.object<ValidatesBlockContent<BlockType.Cover>>({
  caption: z.string(),
  fileId: z.number({
    invalid_type_error: 'Блок "Обложка" - обязательно должно быть загружено изображение!',
    required_error: 'Блок "Обложка" - обязательно должно быть загружено изображение!',
  }),
  format: z.nativeEnum(CoverFormat),
  hideInPost: z.boolean({
    invalid_type_error:
      'Ошибка в поле "Не отображать на странице материала". Попробуйте заново выставить значение и дождаться сохранения блока',
    required_error:
      'Ошибка в поле "Не отображать на странице материала". Попробуйте заново выставить значение и дождаться сохранения блока',
  }),
});
