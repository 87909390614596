import { BlockType, ListType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';
import { getTextFromRawDraftContentState } from '~utils';

import { draftContentSchema } from '../draft';

export const blockContentListSchema = z.object<ValidatesBlockContent<BlockType.List>>({
  options: z
    .array(
      z.object({
        content: draftContentSchema,
      }),
    )
    .min(1, { message: 'Блок "Список" - должен содержать хотя бы один пункт списка!' })
    .refine(([option]) => getTextFromRawDraftContentState(option?.content).length > 0, {
      message: 'Блок "Список" - должен содержать хотя бы один заполненный пункт списка!',
    }),
  type: z.nativeEnum(ListType),
});
