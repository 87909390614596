import { Col, Result, Row, Typography } from 'antd';
import { GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import React, { FunctionComponent } from 'react';

import { Cover } from '~components';
import { deleteMarkup } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

const Post: FunctionComponent<PostPreviewSecure<PostType>> = ({ preview, title }) => (
  <Col span={24} className={styles.spec}>
    <Cover src={preview.imageURLs.big} width="100%" height={197} gradient objectFit="cover" />
    <Typography.Text className={styles.title}>{deleteMarkup(title?.text)}</Typography.Text>
  </Col>
);

export const OneSpecProject: React.FC<GridSchemaSecure> = (props) => {
  const { posts, title } = props;

  return (
    <BlockWrapper {...props} title={title || 'Спецпроект'}>
      {posts?.length === 0 ? (
        <Result status="error" title="Отсутствует спецпроект!" />
      ) : (
        <Row gutter={[0, 20]} className={styles.content}>
          {posts?.map((post) => (
            <Post key={post.id} {...post} />
          ))}
        </Row>
      )}
    </BlockWrapper>
  );
};
