import { EditorState } from 'draft-js';
import React, { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';

import {
  Bold,
  Emoji,
  Highlight,
  Italic,
  Link as LinkIcon,
  Strikethrough,
  Tooltip,
  Underline,
} from '~assets';

import { PopoverComp } from './components/Popover';
import { RichTextBlock } from './types';

type ToolbarControl = {
  icon: ReactElement;
  inline?: boolean;
  key: RichTextBlock;
  popover?: (
    children: ReactNode,
    setEditorState: Dispatch<SetStateAction<EditorState>>,
    editorState: EditorState,
    key: any,
  ) => JSX.Element;
};

const toolbarControls: ToolbarControl[] = [
  {
    icon: <Bold />,
    inline: true,
    key: RichTextBlock.bold,
  },
  {
    icon: <Italic />,
    inline: true,
    key: RichTextBlock.italic,
  },
  {
    icon: <Underline />,
    inline: true,
    key: RichTextBlock.underline,
  },
  {
    icon: <Strikethrough />,
    inline: true,
    key: RichTextBlock.strikethrough,
  },
  {
    icon: <LinkIcon />,
    key: RichTextBlock.link,
    popover: (children, setEditorState, editorState, key) => (
      <PopoverComp
        contentType="link"
        setEditorState={setEditorState}
        editorState={editorState}
        key={key}
      >
        {children}
      </PopoverComp>
    ),
  },
  {
    icon: <Tooltip />,
    key: RichTextBlock.tooltip,
    popover: (children, setEditorState, editorState, key) => (
      <PopoverComp
        contentType="tooltip"
        setEditorState={setEditorState}
        editorState={editorState}
        key={key}
      >
        {children}
      </PopoverComp>
    ),
  },
  {
    icon: <Highlight />,
    inline: true,
    key: RichTextBlock.highlight,
  },
  {
    icon: <Emoji />,
    inline: true,
    key: RichTextBlock.emoji,
    popover: (children, setEditorState, editorState, key) => (
      <PopoverComp
        contentType="emoji"
        setEditorState={setEditorState}
        editorState={editorState}
        key={key}
      >
        {children}
      </PopoverComp>
    ),
  },
];

export default toolbarControls;
