import { EditorState } from 'draft-js';
// @ts-ignore
import { filterInlineStyles } from 'draftjs-filters';

export const removeInlineStyles = (editorState: EditorState, retainInlineStyles: string[] = []) => {
  let newEditorState = editorState;

  const newContent = filterInlineStyles(retainInlineStyles, newEditorState.getCurrentContent());

  const currentSelection = newEditorState.getSelection();

  newEditorState = EditorState.push(newEditorState, newContent, 'change-inline-style');

  newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

  return newEditorState;
};
