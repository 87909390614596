import { Col, Form, Row } from 'antd';
import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { BlockProps } from '~types';

import { CardBlocks } from '../../CardBlocks';
import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

export const CardBlock: FC<BlockProps<BlockType.Card>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { blocks, content, id } = block;

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      initialValues={content}
      bordered
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Карточка</div>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <CardBlocks blocks={blocks} parentId={id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
