import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentDescriptionSchema = z.object<ValidatesBlockContent<BlockType.Description>>(
  {
    text: z.string().superRefine((text, ctx) => {
      if (text.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          fatal: true,
          inclusive: true,
          message: 'Блок "Description" обязателен для заполнения. Внесите, пожалуйста, данные',
          minimum: 5,
          type: 'string',
        });
      }
      if ((text.length >= 1 && text.length < 5) || text.length > 200) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Блок "Description" - не менее 5 и не более 200 символов',
        });
      }
    }),
  },
);

export const blockContentDescriptionNotRequiredSchema = z.object<
  ValidatesBlockContent<BlockType.Description>
>({
  text: z.string().superRefine((text, ctx) => {
    if ((text.length >= 1 && text.length < 5) || text.length > 200) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Description" - не менее 5 и не более 200 символов',
      });
    }
  }),
});
