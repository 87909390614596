import { Col, Row, Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import React, { ReactNode } from 'react';

import { Visible } from '../Visible';
import styles from './styles.module.scss';

interface Props extends TitleProps {
  rightContent?: ReactNode;
  title: string;
  titlePostfix?: ReactNode;
  titleVisible?: boolean;
}

export const TitlePage: React.FC<Props> = (props) => {
  const { rightContent, title, titlePostfix, titleVisible = true, ...antdProps } = props;

  return (
    <Row align="middle" justify="space-between">
      <Visible isVisible={titleVisible}>
        <Col className={styles.leftContent}>
          <div className={styles.titleWrapper}>
            <Typography.Title
              level={2}
              style={{
                marginBottom: 0,
                marginRight: '15px',
              }}
              {...antdProps}
            >
              {title}
            </Typography.Title>
            {titlePostfix}
          </div>
        </Col>
      </Visible>
      {rightContent && <Col className={styles.rightContent}>{rightContent}</Col>}
    </Row>
  );
};
