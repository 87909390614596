import { ContentState } from 'draft-js';
import React from 'react';

type LinkProps = { contentState: ContentState; entityKey: string };

// @ts-ignore
export const Link: React.FC<LinkProps> = ({ children, contentState, entityKey }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} title={url}>
      {children}
    </a>
  );
};
