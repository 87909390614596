import loadScript from 'load-script';
import { always, cond, defaultTo, identity, indexOf, lte, pipe, T } from 'ramda';

export enum WidgetType {
  facebook = 'facebook',
  inst = 'inst',
  script = 'script',
  twitter = 'twitter',
  vkPost = 'vk_post',
}

export const getTypeWidget = cond([
  [pipe(defaultTo(''), indexOf(WidgetType.vkPost), lte(0)), always(WidgetType.vkPost)],
  [pipe(defaultTo(''), indexOf(WidgetType.script), lte(0)), always(WidgetType.script)],
  [pipe(defaultTo(''), indexOf(WidgetType.inst), lte(0)), always(WidgetType.inst)],
  [pipe(defaultTo(''), indexOf(WidgetType.twitter), lte(0)), always(WidgetType.twitter)],
  [pipe(defaultTo(''), indexOf(WidgetType.facebook), lte(0)), always(WidgetType.facebook)],
  [T, always(null)],
]);

export const vkWidget = (html: string) => {
  const trimmedHtml = html.replace(/([\r\n])/gm, '');

  const scriptRegexp = /(<script.*?<\/script>)/gm;

  const vkWidgetScripts = trimmedHtml.match(scriptRegexp);

  if (vkWidgetScripts) {
    vkWidgetScripts.forEach((script) => {
      const scriptSrc = /src\s*=\s*"(.+?)"/gm.exec(script);
      const scriptType = /type\s*=\s*"(.+?)"/gm.exec(script);
      const scriptHasFunction = script.match(/function/gm);
      if (scriptSrc) {
        loadScript(scriptSrc[1], { attrs: { type: scriptType ? scriptType[1] : '' } }, identity);
      }
      if (scriptHasFunction) {
        const scriptFunction = /(?:<script type="text\/javascript">)(.*?)(?:<\/script>)/gm.exec(
          script,
        );
        if (scriptFunction) {
          const scriptToExecute = document.createElement('script');
          scriptToExecute.type = scriptType ? scriptType[1] : '';
          // eslint-disable-next-line prefer-destructuring
          scriptToExecute.text = scriptFunction[1];
          setTimeout(() => document.head.appendChild(scriptToExecute), 3000);
        }
      }
    });
  }
};
