import { BlockType, WidgetFormat } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentWidgetSchema = z.object<ValidatesBlockContent<BlockType.Widget>>({
  format: z.nativeEnum(WidgetFormat),
  html: z
    .string()
    .min(1, { message: 'В блоке "Embed" обязательно должен быть внесен код, внесите его' }),
  text: z.string(),
});
