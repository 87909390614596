import { Col, Result, Row, Space, Typography } from 'antd';
import { GridSchemaSecure, PostPreviewSecure, PostType } from 'pn-backend';
import React, { FunctionComponent } from 'react';

import { Cover } from '~components';
import { useIsMobile } from '~hooks';
import { deleteMarkup, isPost } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.module.scss';

const Post: FunctionComponent<PostPreviewSecure<PostType.Test>> = ({ preview, title }) => {
  const isMobile = useIsMobile();

  return (
    <Col span={24} className={styles.test}>
      <Space align="start" size="large" wrap={isMobile}>
        <Cover
          src={preview?.imageURLs?.medium}
          height={205}
          width={isMobile ? '100%' : 258}
          objectFit="cover"
        />
        <div className={styles.titleWrapper}>
          <Typography.Text className={styles.title}>{deleteMarkup(title?.text)}</Typography.Text>
        </div>
      </Space>
    </Col>
  );
};

export const SomeTests: React.FC<GridSchemaSecure> = (props) => {
  const { posts, title } = props;

  return (
    <BlockWrapper {...props} title={title || 'Тест'}>
      {posts?.length === 0 ? (
        <Result status="error" title="Отсутствует тест!" />
      ) : (
        <Row gutter={[0, 20]} className={styles.content}>
          {posts?.filter(isPost(PostType.Test)).map((post) => (
            <Post key={post.id} {...post} />
          ))}
        </Row>
      )}
    </BlockWrapper>
  );
};
