import { BlockType } from 'pn-backend';
import { z } from 'zod';

import { ValidatesBlockContent } from '~types';

export const blockContentSubtitleSchema = z.object<ValidatesBlockContent<BlockType.Subtitle>>({
  text: z.string().superRefine((text, ctx) => {
    if ((text.length >= 1 && text.length < 5) || text.length > 180) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Блок "Подзаголовок" - не менее 5 и не более 180 символов',
      });
    }
  }),
});
