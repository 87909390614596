import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import { BlockType, VideoBlockType, VideoFormat } from 'pn-backend';
import { prop } from 'ramda';
import React, { FC } from 'react';

import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockProps } from '~types';
import { canBeFullscreen, getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const VideoBlock: FC<BlockProps<BlockType.Video>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { type: postType } = usePost();
  const { content, files } = block;

  const isMobile = useIsMobile();

  const formatValues: FormBlockProps<BlockType.Video>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId].filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content ?? { cycle: false, type: VideoBlockType.Iframe }}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Видео</div>
          <Form.Item name="type" hidden>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              type={DropType.Video}
              imageOptions={isMobile ? { height: 177, width: 315 } : undefined}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Row gutter={[25, 25]}>
            <Col span={16}>
              <Form.Item name="format">
                <Radio.Group>
                  <Radio value={VideoFormat.Center}>По центру</Radio>
                  {canBeFullscreen(postType) && (
                    <Radio value={VideoFormat.Fullscreen}>Fullscreen</Radio>
                  )}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cycle" valuePropName="checked">
                <Checkbox>Зациклить</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="caption" className={styles.inputRow}>
                <Input bordered={false} className={styles.input} placeholder="Подпись" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
