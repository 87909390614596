import { Col, Row } from 'antd';
import { PostType } from 'pn-backend';
import { inc } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfiniteScroll } from '~components';
import { BASE_LIMIT } from '~constants';
import { postsEntitySelector } from '~selectors';
import { useGetListPostsQuery } from '~services';
import { isDefined } from '~utils';

import { PostCard } from '../PostCard';

interface Props {
  filter?: Record<string, any>;
  order?: string;
}

const defaultTypes = [
  PostType.Article,
  PostType.Card,
  PostType.Longread,
  PostType.Spec,
  PostType.Test,
];

export const PostsList: React.FC<Props> = ({ filter = {}, order }) => {
  const [page, setPage] = useState(0);
  const { data, isFetching } = useGetListPostsQuery(
    {
      limit: BASE_LIMIT,
      offset: page * BASE_LIMIT,
      order,
      ...filter,
      type: filter?.type || defaultTypes,
    },
    { pollingInterval: 60000, refetchOnMountOrArgChange: true, refetchOnReconnect: true },
  );
  const posts = useSelector(postsEntitySelector.selectAll);

  const fetchData = async () => {
    setPage(inc);
  };

  useEffect(() => {
    setPage(0);
  }, [order, filter]);

  return (
    <InfiniteScroll
      fetchData={fetchData}
      hasMore={isDefined(data) && data?.count > posts?.length}
      isFetching={isFetching}
    >
      <Row gutter={[25, 25]}>
        {posts?.map((post) => (
          <Col lg={12} xl={8} key={post.id}>
            <PostCard post={post} showType={filter?.type !== PostType.News} />
          </Col>
        ))}
      </Row>
    </InfiniteScroll>
  );
};
