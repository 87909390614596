import { DownloadOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Typography } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { AddImage, UploadVideo } from '~assets';
import { DropType } from '~constants';

import styles from './styles.module.scss';

interface Props {
  isError: boolean;
  isProcessing: boolean;
  isSmall: boolean;
  isUploading: boolean;
  type: DropType;
  uploadProgress: number;
}

const getPreviewText = cond<[DropType], string>([
  [equals(DropType.Image), always('Загрузить изображение')],
  [equals(DropType.Photo), always('Загрузить фото')],
  [equals(DropType.Gif), always('Загрузить гифку')],
  [equals(DropType.Video), always('Загрузить видео')],
  [equals(DropType.Documents), always('Загрузить документ')],
]);

const getErrorText = cond<[DropType], string>([
  [equals(DropType.Image), always('Ошибка загрузки изображения')],
  [equals(DropType.Photo), always('Ошибка загрузки фото')],
  [equals(DropType.Gif), always('Ошибка загрузки гифки')],
  [equals(DropType.Video), always('Ошибка загрузки видео')],
  [equals(DropType.Documents), always('Ошибка загрузки документа')],
]);

const getPreviewIcon = cond<[DropType], JSX.Element>([
  [equals(DropType.Video), always(<UploadVideo />)],
  [equals(DropType.Documents), always(<DownloadOutlined style={{ fontSize: '48px' }} />)],
  [T, always(<AddImage />)],
]);

export const Preview: React.FC<Props> = ({
  isError,
  isProcessing,
  isSmall,
  isUploading,
  type,
  uploadProgress,
}) => {
  const PreviewIcon = useCallback(() => {
    if (isProcessing || isUploading) {
      return (
        <Progress
          percent={isUploading ? uploadProgress : 100}
          type="circle"
          width={isSmall ? 40 : 80}
        />
      );
    }
    return getPreviewIcon(type);
  }, [isProcessing, isUploading, isSmall, type, uploadProgress]);

  const previewText = useMemo(() => {
    if (isError) {
      return getErrorText(type);
    }
    if (isProcessing) {
      return 'Обработка';
    }
    if (isUploading) {
      return 'Загрузка';
    }
    return getPreviewText(type);
  }, [isError, isProcessing, isUploading, type]);

  return (
    <Row className={classNames(styles.previewWrapper, isSmall && styles.previewWrapperSmall)}>
      <Col span={24}>
        <PreviewIcon />
      </Col>
      {!isSmall && (
        <Col span={24}>
          <Typography.Text className={styles.previewLabel}>{previewText}</Typography.Text>
        </Col>
      )}
    </Row>
  );
};
