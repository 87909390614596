import { Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import { BlockType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React, { FC, useCallback, useState } from 'react';

import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

const getBlockTitle = cond([
  [equals(BlockType.H1), always('Заголовок H1')],
  [equals(BlockType.Subtitle), always('Подзаголовок')],
  [T, always(null)],
]);

const getBlockPlaceholder = cond([
  [equals(BlockType.H1), always('Напишите заголовок')],
  [equals(BlockType.Subtitle), always('Напишите подзаголовок')],
  [T, always(undefined)],
]);

const getBlockMaxCount = cond([
  [equals(BlockType.H1), always(100)],
  [equals(BlockType.Subtitle), always(180)],
  [T, always(undefined)],
]);

const getBlockFontStyle = cond([
  [equals(BlockType.H1), always(styles.h1Block)],
  [equals(BlockType.Subtitle), always(styles.subtitleBlock)],
  [T, always(undefined)],
]);

export const TitleBlock: FC<BlockProps<BlockType.H1 | BlockType.Subtitle>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content, type } = block;

  const { text } = content;

  const [count, setCount] = useState(getText(text).length);

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(getText(changedText).length);
  }, []);

  return (
    <FormBlock
      form={form}
      count={count}
      blockIndex={blockIndex}
      block={block}
      canDelete={canDelete}
      maxCount={getBlockMaxCount(type)}
      onValuesChange={onValuesChange}
      initialValues={content}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>{getBlockTitle(type)}</div>
        </Col>
        <Col span={24}>
          <Form.Item name="text">
            <TextArea
              autoSize
              rows={1}
              className={cn(styles.input, getBlockFontStyle(type))}
              bordered={false}
              placeholder={getBlockPlaceholder(type)}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};
