import { FileStatus } from 'pn-backend';
import { always, cond, equals, lensIndex, path, pipe, prop, propEq, view } from 'ramda';
import { Accept } from 'react-dropzone';

import { DropType } from '~constants';

export const getAccept = cond<[DropType], Accept>([
  [equals(DropType.Image), always({ 'image/jpeg': [], 'image/png': [] })],
  [equals(DropType.Photo), always({ 'image/jpeg': [], 'image/png': [] })],
  [equals(DropType.Gif), always({ 'image/gif': [] })],
  [equals(DropType.Video), always({ 'video/*': [] })],
  [equals(DropType.Audio), always({ 'audio/*': [] })],
]);

export const isImage = equals(DropType.Image);
export const isVideo = equals(DropType.Video);

export const isError = propEq('status', FileStatus.Error);

export const isComplete = propEq('status', FileStatus.Complete);

export const isInProgress = propEq('status', FileStatus.InProgress);

export const getErrorMessage = pipe(prop('errors'), view(lensIndex(0)), prop('message'));

export const getFileName = path<string>(['file', 'name']);
