import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DefaultDraftBlockRenderMap,
} from 'draft-js';
import { Map } from 'immutable';

import { Link } from './components/Link';
import { Tooltip } from './components/Tooltip';
import { RichTextBlock } from './types';

const customStyleMap = {
  HIGHLIGHT: {
    backgroundColor: '#faed27',
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

// @ts-ignore
const RenderMap = new Map({
  STRIKETHROUGH: {
    element: 'line-through',
  },
}).merge(DefaultDraftBlockRenderMap);

function findLinkEntities(
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  block.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === RichTextBlock.link;
  }, callback);
}

function findTooltipEntities(
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  block.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === RichTextBlock.tooltip
    );
  }, callback);
}

const decorator = new CompositeDecorator([
  {
    component: Link,
    strategy: findLinkEntities,
  },
  {
    component: Tooltip,
    strategy: findTooltipEntities,
  },
]);

export { customStyleMap, decorator, RenderMap };
