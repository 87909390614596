import { Col } from 'antd';
import { not } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge, TitlePage } from '~components';
import { BadgeColor } from '~constants';
import { EmployeeModal, EmployeesList, MobilePageHeader, PageHeader } from '~containers';
import { useHasPermission, useIsMobile } from '~hooks';
import { countEmployeesSelector } from '~selectors';
import { noop } from '~utils';

import styles from './styles.module.scss';

export const EmployeesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const count = useSelector(countEmployeesSelector);

  const hasPermission = useHasPermission();

  const isMobile = useIsMobile();

  const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
  const [editableEmployee, setEditableEmployee] = useState<number>();

  const toggleModal = () => {
    setIsCreationModalOpen(not);
    setEditableEmployee(undefined);
  };

  const openEditModal = (id: number) => {
    toggleModal();
    setEditableEmployee(id);
  };

  return (
    <div className={styles.pageContentWrapper}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Пользователи"
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="small">
              {count ?? 0}
            </Badge>
          }
          placeholderText="Поиск"
          onCreate={hasPermission ? setIsCreationModalOpen : noop}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск по имени и фамилии"
          createButtonText="Добавить пользователя"
          onCreate={hasPermission ? setIsCreationModalOpen : noop}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title="Пользователи"
          titleVisible={!isMobile}
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="large">
              {count ?? 0}
            </Badge>
          }
        />
      </Col>
      <EmployeesList onEdit={openEditModal} searchTerm={searchTerm} />
      <EmployeeModal onClose={toggleModal} isOpen={isCreationModalOpen} id={editableEmployee} />
    </div>
  );
};
