import { Card, Col, Row, Typography } from 'antd';
import { TagPreviewSecure } from 'pn-backend';
import React from 'react';

import { Delete, Edit } from '~assets';
import { formatDate } from '~utils';

import { Button } from '../Button';
import styles from './styles.module.scss';

interface Props {
  item: TagPreviewSecure;

  onDelete?(tag: TagPreviewSecure): void;
  onEdit(tag: TagPreviewSecure): void;
}

export const TagCard: React.FC<Props> = ({ item, onDelete, onEdit }) => {
  const { createdAt, name, postCount } = item;

  const onClickEdit = () => {
    onEdit(item);
  };

  const onClickDelete = () => {
    if (onDelete) {
      onDelete(item);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Row className={styles.wrapper} align="middle">
        <Col span={24}>
          <Typography.Text className={styles.title}>{name}</Typography.Text>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Дата создания:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>
                    {formatDate(createdAt, 'dd MMMM yyyy')}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Публикации:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>{postCount || 0}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button icon={<Edit />} type="text" onClick={onClickEdit}>
                Редактировать
              </Button>
            </Col>
            <Col>
              <Button icon={<Delete />} type="text" onClick={onClickDelete} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
