import { Col, Row } from 'antd';
import classNames from 'classnames';
import { EmployeeExtSecure } from 'pn-backend';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge, Button, InfiniteScroll, TitlePage } from '~components';
import { BadgeColor } from '~constants';
import { useIsMobile } from '~hooks';
import { authorsEntitySelector, countAuthorsSelector, userSelector } from '~selectors';
import { useGetAuthorsListQuery } from '~services';
import { isDefined } from '~utils';

import { AuthorCard } from '../AuthorCard';
import style from './styles.module.scss';

interface Props {
  currentEmployee?: number;
  onEdit?(id?: number): void;
  searchTerm?: string;
}

// ToDo Отрефакторить в ModelList
export const AuthorsList: React.FC<Props> = (props) => {
  const { onEdit, currentEmployee = undefined, searchTerm } = props;
  const isMobile = useIsMobile();
  const [offset, setOffset] = useState(0);
  const [employeeId, setEmployeeId] = useState(currentEmployee);
  const count = useSelector(countAuthorsSelector);
  const { data, isFetching, refetch } = useGetAuthorsListQuery({
    employeeId,
    name: searchTerm,
    offset,
    relations: ['avatar', 'employee'],
  });
  const authors = useSelector(authorsEntitySelector.selectAll) ?? [];
  const user: EmployeeExtSecure = useSelector(userSelector);

  const fetchData = async () => {
    setOffset(authors?.length);
  };

  const toggleShowMy = () => {
    if (employeeId) {
      setEmployeeId(undefined);
    } else {
      setEmployeeId(user?.id);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <TitlePage
              title="Авторы"
              level={currentEmployee ? 4 : 2}
              titleVisible={!isMobile || (!!currentEmployee && isMobile)}
              titlePostfix={
                <Badge color={BadgeColor.BlueLight} size={currentEmployee ? 'small' : 'large'}>
                  {count ?? 0}
                </Badge>
              }
            />
          </Col>
          {!currentEmployee && (
            <Col>
              <Button
                type="text"
                textClassName={classNames(style.showMy, employeeId && style.active)}
                onClick={toggleShowMy}
              >
                Показать моих авторов
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <InfiniteScroll
          fetchData={fetchData}
          hasMore={isDefined(data) && data.count > authors?.length}
          isFetching={isFetching}
        >
          <Row
            gutter={[
              { md: 20, xs: 10 },
              { md: 20, xs: 10 },
            ]}
          >
            {authors?.map((author) => (
              <AuthorCard key={author?.id} item={author} onEdit={onEdit} />
            ))}
          </Row>
        </InfiniteScroll>
      </Col>
    </Row>
  );
};
