import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import { ImageProxyResize } from 'pn-backend';
import { not } from 'ramda';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Badge, InfiniteScroll, TitlePage } from '~components';
import { BadgeColor, DropType } from '~constants';
import { AuthorModal, AuthorsList, DropZone, MobilePageHeader, PostCard } from '~containers';
import { useHasPermission, useIsMe, useIsMobile } from '~hooks';
import {
  useGetEmployeeByIdQuery,
  useGetListPostsQuery,
  useUpdateEmployeeMutation,
} from '~services';
import { isDefined } from '~utils';

import styles from './styles.module.scss';

export const EmployeeEditPage = () => {
  const { id = '' } = useParams();
  const [offset, setOffset] = useState(0);

  const isMobile = useIsMobile();

  const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
  const [editableAuthor, setEditableAuthor] = useState<number>();

  const [updateEmployee] = useUpdateEmployeeMutation();

  const { data: employee } = useGetEmployeeByIdQuery(
    { id: parseInt(id, 10), relations: ['avatar', 'authors'] },
    { refetchOnMountOrArgChange: true, skip: !id },
  );

  const employeeAuthors = employee?.authors?.map((author) => author.id);

  const { data, isFetching } = useGetListPostsQuery(
    { authors: employeeAuthors, offset },
    { skip: !employee || employeeAuthors?.length === 0 },
  );

  const posts = data?.results || [];

  const hasPermission = useHasPermission();
  const isMe = useIsMe(parseInt(id, 10));

  const initialValues = useMemo(() => {
    if (employee) {
      return {
        ...employee,
      };
    }
    return {};
  }, [employee]);

  if (!employee) {
    return null;
  }

  const fetchData = async () => {
    setOffset(posts?.length);
  };

  const toggleModal = () => {
    setIsCreationModalOpen(not);
    setEditableAuthor(undefined);
  };

  const openEditModal = (authorId: number) => {
    toggleModal();
    setEditableAuthor(authorId);
  };

  const onFinish = async (values: any) => {
    const params = {
      avatarId: values?.avatar?.id,
      login: values.login,
      name: values.name,
      password: values.password?.toString(),
      role: values.role,
    };

    await updateEmployee({ ...params, id: parseInt(id, 10) });
  };

  return (
    <>
      <Row gutter={[30, 30]}>
        <Col span={24} className={styles.mobilePageHeader}>
          <MobilePageHeader title={`Профиль ${employee?.name}`} withSearch={false} />
        </Col>
        <Col span={24}>
          <Form
            layout="vertical"
            name="employee"
            validateTrigger="submit"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <Row justify="space-between">
                  <TitlePage titleVisible={!isMobile} title={`Профиль ${employee?.name}`} />
                  <Col>
                    <Row gutter={[25, 25]}>
                      <Col>
                        <Button
                          className={styles.button}
                          block
                          type="primary"
                          htmlType="submit"
                          size="large"
                        >
                          Сохранить
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[30, 30]}>
                  <Col md={5} xs={24}>
                    <Form.Item name="avatar">
                      <DropZone
                        disabled={!hasPermission && !isMe}
                        type={DropType.Photo}
                        imageOptions={{ height: 180, resize: ImageProxyResize.Fill, width: 180 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={7} xs={24}>
                    <Row gutter={[30, 36]}>
                      <Col span={24}>
                        <Form.Item
                          name="name"
                          /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                          label={<label className={styles.label}>Имя</label>}
                        >
                          <Input
                            disabled={!hasPermission && !isMe}
                            size="large"
                            placeholder="Имя"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  {!isMobile && (
                    <Col>
                      <div className={styles.separator} />
                    </Col>
                  )}
                  <Col md={11} xs={24}>
                    <Row gutter={[30, 36]}>
                      <Col span={24} className={cn(!isMobile && styles.noPaddingRight)}>
                        <Form.Item
                          name="login"
                          /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                          label={<label className={styles.label}>Логин</label>}
                        >
                          <Input disabled={!isMe} size="large" placeholder="Логин" />
                        </Form.Item>
                      </Col>
                      <Col span={24} className={cn(!isMobile && styles.noPaddingRight)}>
                        <Row gutter={[15, 15]}>
                          <Col md={12} xs={24}>
                            <Form.Item
                              name="password"
                              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                              label={<label className={styles.label}>Пароль</label>}
                            >
                              <Input.Password
                                // @ts-ignore
                                autocomplete="new-password"
                                size="large"
                                placeholder="Пароль"
                                disabled={!isMe}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              name="confirmPassword"
                              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                              label={<label className={styles.label}>Подтверждение пароля</label>}
                            >
                              <Input.Password
                                // @ts-ignore
                                autocomplete="new-password"
                                size="large"
                                placeholder="Подтвердить пароль"
                                disabled={!isMe}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Row align="middle" gutter={[10, 10]}>
            <Col span={24}>
              <AuthorsList onEdit={openEditModal} currentEmployee={parseInt(id, 10)} />
            </Col>
            <Col span={24}>
              <Row gutter={[15, 15]} align="middle">
                <Col>
                  <PlusOutlined className={styles.addAuthor} onClick={toggleModal} />
                </Col>
                <Col>
                  <div className={styles.addAuthorText}>Добавить автора</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={styles.noPaddingLeft}>
              <TitlePage
                level={4}
                title="Публикации"
                titlePostfix={
                  <Badge color={BadgeColor.BlueLight} size="small">
                    {posts?.length ?? 0}
                  </Badge>
                }
              />
            </Col>
            <InfiniteScroll
              fetchData={fetchData}
              hasMore={isDefined(data) && data?.count > posts?.length}
              isFetching={isFetching}
            >
              <Row gutter={[25, 25]}>
                {posts?.map((post) => (
                  <Col lg={12} xl={8}>
                    <PostCard post={post} />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          </Row>
        </Col>
      </Row>
      <AuthorModal
        onClose={toggleModal}
        isOpen={isCreationModalOpen}
        id={editableAuthor}
        employeeId={employee.id}
      />
    </>
  );
};
