import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import {
  always,
  defaultTo,
  equals,
  findIndex,
  ifElse,
  join,
  lensIndex,
  map,
  over,
  pipe,
  propEq,
  reverse,
  toPairs,
} from 'ramda';

import { SortDirections } from '~constants';

import { isDefined } from './common';

export const getSortValuesFunc =
  <T extends { id: number; sortOrder: number }>(values: T[]) =>
  (event: DragEndEvent): Array<{ changes: { sortOrder: number }; id: number }> => {
    const { active, over } = event;

    if (isDefined(over) && active.id !== over.id) {
      const oldIndex = findIndex(propEq('sortOrder', active.id), values);
      const newIndex = findIndex(propEq('sortOrder', over.id), values);

      return arrayMove<T>(values, oldIndex, newIndex).reduce((acc, value, index) => {
        if (value.sortOrder === index) {
          return acc;
        }

        return [...acc, { changes: { sortOrder: index }, id: value.id }];
      }, []);
    }

    return [];
  };

export const sortToString: (sort: Record<string, SortDirections>) => string = pipe(
  defaultTo({}),
  toPairs,
  // @ts-ignore
  map(
    pipe(
      reverse,
      // @ts-ignore
      over(lensIndex(0), ifElse(equals(SortDirections.Desc), always('-'), always('+'))),
      join(''),
    ),
  ),
  join(','),
);
