import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

// eslint-disable-next-line import/no-cycle
import { Config } from '~services';

import { stateSelector } from './state';

// Не использовать в компонентах, для этого есть ConfigContext
export const configSelector = createDraftSafeSelector(stateSelector, prop<Config>('config'));

export const configApiRootSelector = createDraftSafeSelector(
  configSelector,
  prop<Config['apiUrl']>('apiUrl'),
);
