import { always, cond, equals, T } from 'ramda';

// TODO реализовать проверку роли
export const getUserRoleTitle = cond([
  [equals('chief'), always('Главный редактор')],
  [equals('editor'), always('Редактор')],
  // [equals(UserRole.Corrector), always('Корректор')],
  // [equals(UserRole.Editor), always('Редактор')],
  // [equals(UserRole.Intern), always('Интерн')],
  // [equals(UserRole.SuperAdmin), always('Администратор')],
  [T, always('-')],
]);

export const getUserRoleTitleForValidation = cond([
  [equals('chief'), always('Главред')],
  // [equals(UserRole.Corrector), always('Корректор')],
  [T, always('-')],
]);
